import React from "react";
import { ModuleTen } from "./ModuleTen";
import { ModuleNinePart1 } from "./ModuleNinePart1";
import { ModuleNinePart2 } from "./ModuleNinePart2";
import { ModuleEightPart1 } from "./ModuleEightPart1";
import { ModuleEightPart2 } from "./ModuleEightPart2";
import { ModuleEightPart3 } from "./ModuleEightPart3";
import { ModuleEightPart4 } from "./ModuleEightPart4";
import { ModuleEightPart5 } from "./ModuleEightPart5";
import { ModuleSeven } from "./ModuleSeven";
import { ModuleSixPart1 } from "./ModuleSixPart1";
import { ModuleSixPart2 } from "./ModuleSixPart2";
import { ModuleFivePart1 } from "./ModuleFivePart1";
import { ModuleFivePart2 } from "./ModuleFivePart2";
import { ModuleFivePart3 } from "./ModuleFivePart3";
import { ModuleFivePart4 } from "./ModuleFivePart4";
import { ModuleFivePart5 } from "./ModuleFivePart5";
import { ModuleFour } from "./ModuleFour";
import { ModuleFourPart2 } from "./ModuleFourPart2";
import { ModuleThree } from "./ModuleThree";
import { ModuleTwo } from "./ModuleTwo";
import { ModuleOne } from "./ModuleOne";
import { ModuleZero } from "./ModuleZero";
import { ModuleNinePart3 } from "./ModuleNinePart3";
import { ModuleNinePart4 } from "./ModuleNinePart4";

export const StaticModuleWrapper = ({
  module_sequence_number,
  subModuleId,
}) => {
  // console.log('Static module')
  // console.log(module_sequence_number)

  const getModuleWithSequenceNumber = () => {
    if (subModuleId === "cf208c09-d17f-45bc-ae85-659f6adf62b8") {
      return <ModuleZero />;
    } else if (subModuleId === "dcdecb31-8973-4d71-8894-9ec04d6753f1") {
      return <ModuleOne />;
    } else if (subModuleId === "ceae9023-76fb-4c7f-9fb8-79f7ea5d6bdd") {
      return <ModuleTwo />;
    } else if (subModuleId === "caaa52f7-bc1e-4a31-ae38-5e2d9692d7d9") {
      return <ModuleThree />;
    } else if (subModuleId === "0077976b-bff7-4421-88b0-3de50608d104") {
      return <ModuleFour />;
    } else if (subModuleId === "4f739893-c29b-4d1d-9a46-20e0286ac07a") {
      return <ModuleFourPart2 />;
    } else if (subModuleId === "f3e5e602-5700-4679-8004-bb3651c86a33") {
      return <ModuleFivePart1 />;
    } else if (subModuleId === "b1044b05-4e21-4adc-a772-43e5425d143d") {
      return <ModuleFivePart2 />;
    } else if (subModuleId === "84500754-6fce-4e07-bcd0-ddef60431777") {
      return <ModuleFivePart3 />;
    } else if (subModuleId === "180d6488-d6b8-491b-acab-3ee33217266e") {
      return <ModuleFivePart4 />;
    } else if (subModuleId === "3ae563dc-3168-4606-9575-59d92320ee8f") {
      return <ModuleFivePart5 />;      
    } else if (subModuleId === "c1348626-ffac-4e0e-98bc-7f3cd614e8dc") {
      return <ModuleSixPart1 />;
    } else if (subModuleId === "5633305d-abd2-4a4c-b2dc-4a6a6418a136") {
      return <ModuleSixPart2 />;  
    } else if (subModuleId === "34353493-4678-4d05-a48c-26b08424889c") {
      return <ModuleSeven />;
    } else if (subModuleId === "e4a22ee7-2549-4d8a-8124-0af01ecb7384") {
      return <ModuleEightPart1 />;
    } else if (subModuleId === "49571e10-f4f7-4e10-9a94-468707ce8313") {
      return <ModuleEightPart2 />;
    } else if (subModuleId === "f3f33817-c3c9-4de3-969b-7d884ca06d11") {
      return <ModuleEightPart3 />;
    } else if (subModuleId === "c83832e6-63ce-45d9-9999-a3372b8b5b7c") {
      return <ModuleEightPart4 />;
    } else if (subModuleId === "d476ce7b-45b7-4228-b73b-4a7230f42e45") {
      return <ModuleEightPart5 />;
    } else if (subModuleId === "cbccb917-65a4-4ceb-b405-141c8fed7883") {
      return <ModuleNinePart1 />;
    } else if (subModuleId === "5fa04cb0-41d5-45fa-a61d-4efb175efaae") {
      return <ModuleNinePart2 />;
    } else if (subModuleId === "02918027-dfa6-4108-acb5-ec83c080e150") {
      return <ModuleNinePart3 />;
    } else if (subModuleId === "eee6c76c-509e-461b-952b-73ac403024bf") {
      return <ModuleNinePart4 />;    
    } else if (subModuleId === "dabbb489-f8b6-4e51-9150-130bb3848be1") {
      return <ModuleTen />;
    }
  };

  return (
    <div>
      {getModuleWithSequenceNumber()}
      {/* Static module */}
    </div>
  );
};
