import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import { Container, Card } from "react-bootstrap";
import under_con from "../../../../Images/module5/under_con.png";
import speeding_img from "../../../../Images/module5/speeding_img.png";
import drive from "../../../../Images/module5/drive.png";
import officer from "../../../../Images/module5/officer.png";
import Stop from "../../../../Images/module5/m54.png";
import Yield from "../../../../Images/module5/m55.png";
import Regulation from "../../../../Images/module5/m56.png";
import Warning from "../../../../Images/module5/m57.png";
import Railroad from "../../../../Images/module5/m58.png";
import WorkArea from "../../../../Images/module5/m59.png";
import Users from "../../../../Images/module5/users.mp4";
import LargeVehicle from "../../../../Images/module5/largevehicle.mp4";
import CellPhonesAndSafetyBeltsComponent from "./CellPhonesAndSafetyBeltsComponent";
import Slide1 from "../../../../Images/module5/slide1.jpg";
import Slide2 from "../../../../Images/module5/slide2.jpg";
import Slide3 from "../../../../Images/module5/slide3.jpg";
import Slide4 from "../../../../Images/module5/slide4.jpg";
import Slide5 from "../../../../Images/module5/slide5.jpg";
import Slide6 from "../../../../Images/module5/slide6.jpg";
import Slide7 from "../../../../Images/module5/slide7.jpg";

import AOS from "aos";
import "aos/dist/aos.css";

import "./ModuleFive.css";

import { H5PEditorUI, H5PPlayerUI } from "@lumieducation/h5p-react";
import { ContentService } from "../services/ContentService";

const TRAFFIC_SIGNS = [
  {
    header: "STOP SIGN ",
    headerSpan: "(red with white letter)",
    img: Stop,
    details:
      "Come to a full stop, yield the right of way to vehicles and pedestrians in or approaching the intersection. Go when it is safe. You must come to a full stop before the stop line, if there is one.",
  },
  {
    header: "Yield Signs ",
    headerSpan: "(red and white with red letters)",
    img: Yield,
    details:
      "Slow down as you approach the intersection. Prepare to stop and yield the right of way to vehicles and pedestrians in or approaching the intersection. You must come to a full stop at a YIELD sign if traffic conditions require it. Approach with caution and be prepared to stop; otherwise, proceed with care.",
  },
  {
    header: "Regulation signs ",
    headerSpan: "(white with black and/or red letters or symbols)",
    img: Regulation,
    details:
      "These signs give you information about rules for traffic direction, lane use, turning, speed, parking, and other special requirements",
  },
  {
    header: "Warning signs ",
    headerSpan: "(yellow with black letters or sumbols)",
    img: Warning,
    details:
      "you are approaching an especially hazardous location or a place where there is a special rule. Be especially cautions when you see a warning sign.",
  },
  {
    header: "Railroad crossing ",
    headerSpan: "(Yellow with black letters 'RR' and 'X' symbol)",
    img: Railroad,
    details:
      "There is a railroad crossing ahead. you should use caution, and you may have to stop. Most buses and some trucks must stop rail road crossings, even when a train is not approaching and warning lights are not activated.",
  },
  {
    header: "Work area signs ",
    headerSpan: "(orange with black letters or symbols)",
    img: WorkArea,
    details:
      "People are working on or near the roadway, and traffic may be controlled by flag person. A work area speed limit of 35 MPH may be posted.",
  },
];

export const ModuleFivePart5 = () => {
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    AOS.init();
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  const [activeId, setActiveId] = useState("");

  const contentService = new ContentService("/h5p");
  const h5pPlayer = React.useRef(H5PPlayerUI);

  const [loading, setLoading] = useState(true);
  const [videoOneStatus, setVideoOneStatus] = useState(false);

  useEffect(() => {
    sessionStorage.setItem("HAS_VIDEO", true);
  }, []);

  useEffect(() => {
    if (videoOneStatus) {
      sessionStorage.setItem("VIDEO_PLAYED", "COMPLETED");
    }
  }, [videoOneStatus]);
  const onPlayerInitialized = () => {
    setLoading(false);
  };

  const toggleActive = (id) => {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  };

  var video = document.getElementById("video");
  useEffect(() => {
    if (video) {
      var supposedCurrentTime = 0;
      video.addEventListener("timeupdate", function () {
        if (!video.seeking) {
          supposedCurrentTime = video.currentTime;
        }
      });
      // prevent user from seeking
      video.addEventListener("seeking", function () {
        // guard agains infinite recursion:
        // user seeks, seeking is fired, currentTime is modified, seeking is fired, current time is modified, ....
        var delta = video.currentTime - supposedCurrentTime;
        if (Math.abs(delta) > 0.01) {
          console.log("Seeking is disabled");
          video.currentTime = supposedCurrentTime;
        }
      });
      // delete the following event handler if rewind is not required
      video.addEventListener("ended", function () {
        // reset state in order to allow for rewind
        supposedCurrentTime = 0;
      });
    }
  }, [video]);
  return (
    <div>
      <Container>
        <div className="module5_course same_width_module mt-md-4 mb-md-4">
          <div className="container">
            <div className="mdu5_page1">
              <h2>Module 5 - Part 5</h2>
            </div>
          </div>

        
          <div className="mdu5_page10">
            <div className="container">
              <div className="row">
                

                <div className="col-md-1"></div>

            
              </div>
              <div className="m5_p10_sec2">
                <h2 data-aos="slide-right" data-aos-duration="3000">
                  LARGE VEHICLES & HOW TO SAFELY SHARE THE ROAD WITH THEM
                </h2>

                <p className="video_highway_para">
                  This video will talk about different Large Vehicles and will
                  demonstrate how to safely share the road with them. Click the
                  Play button to start the video.
                </p>

                <video
                  id="video"
                  width="100%"
                  height="400"
                  autoplay={true}
                  onEnded={() => {
                    setVideoOneStatus(true);
                  }}
                  controls
                  controlsList="nodownload noplaybackrate"
                >
                  <source src={LargeVehicle} type="video/mp4" />
                </video>
              </div>

              <div className="module-5-part-2">
               

                <div className="CellPhonesAndSafetyBeltsComponent_h5p" id="h5p_content_navigate">
                  <CellPhonesAndSafetyBeltsComponent totalOuterSlides={7} />
                </div>
              </div>
            </div>
          </div>

         
        </div>
      </Container>
    </div>
  );
};
