import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Logo from "../../../../Images/module4/logo.png";
import Search from "../../../../Images/module4/search.png";
import Traffic from "../../../../Images/module4/traffic.png";
import Signal from "../../../../Images/module4/signal.png";
import Execute from "../../../../Images/module4/execute.png";
import Strategyvideo from "../../../../Images/module4/strategy.mp4";
import SEE from "../../../../Images/module4/b11.png";
import Hydro from "../../../../Images/module4/hydro.jpg";
import SkidCar from "../../../../Images/module4/b15.png";
import RearWheel from "../../../../Images/module4/b16.jpg";
import RearSkid from "../../../../Images/module4/b17.jpg";
import FrontSkid from "../../../../Images/module4/b18.jpg";
import Skid from "../../../../Images/module4/skid.mp4";
import Skids from "../../../../Images/module4/skid.jpg";
import SkidsSliderComponent from "./SkidsSliderComponent";
import SkidsSliderComponentCopy from "./SkidsSliderComponentCopy";
import { H5PEditorUI, H5PPlayerUI } from "@lumieducation/h5p-react";
import { ContentService } from "../services/ContentService";

import AOS from "aos";
import "aos/dist/aos.css";

import "./ModuleFour.css";

export const ModuleFourPart2 = () => {
  const [scroll, setScroll] = useState(false);

  const contentService = new ContentService("/h5p");
  const h5pPlayer = React.useRef(H5PPlayerUI);

  const [loading, setLoading] = useState(true);
  const [videoOneStatus, setVideoOneStatus] = useState(false);

  useEffect(() => {
    sessionStorage.setItem("HAS_VIDEO", true);
  }, []);

  useEffect(() => {
    if (videoOneStatus) {
      sessionStorage.setItem("VIDEO_PLAYED", "COMPLETED");
    }
  }, [videoOneStatus]);

  const onPlayerInitialized = () => {
    setLoading(false);
  };

  useEffect(() => {
    AOS.init();
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  var video = document.getElementById("video");
  useEffect(() => {
    if (video) {
      var supposedCurrentTime = 0;
      video.addEventListener("timeupdate", function () {
        if (!video.seeking) {
          supposedCurrentTime = video.currentTime;
        }
      });
      // prevent user from seeking
      video.addEventListener("seeking", function () {
        // guard agains infinite recursion:
        // user seeks, seeking is fired, currentTime is modified, seeking is fired, current time is modified, ....
        var delta = video.currentTime - supposedCurrentTime;
        if (Math.abs(delta) > 0.01) {
          console.log("Seeking is disabled");
          video.currentTime = supposedCurrentTime;
        }
      });
      // delete the following event handler if rewind is not required
      video.addEventListener("ended", function () {
        // reset state in order to allow for rewind
        supposedCurrentTime = 0;
      });
    }

  }, [video]);

  return (
    <div className="module4_course module_font same_width_module mb-md-4">
      <Container>
        <div className="module_plan mt-5">
          <Container>
            <div className="ribbon_main">
              <div
                className="ribbon_bg ribbon"
                data-aos="zoom-in"
                data-aos-duration="2000"
              >
                <h2>HYDROPLANING</h2>
              </div>
            </div>

            <Row className="mt-5 pt-md-5">
              <Col md={8} xs={12}>
                <div className="hydro_content_left">
                  <span className="d-block">
                    Roads are at their slickest during the first five to ten
                    minutes of a rainfall.
                  </span>

                  <p className="mt-3 mb-3">
                    This occurs because the water mixes with surface dirt and
                    oil to form a slippery film, greatly reducing your tires'
                    ability to grip the road. The tires of a vehicle will begin
                    to skim along the wet surface of the road, much like a
                    water-skier zipping across the surface of a lake.
                  </p>

                  <p>
                    Hydroplaning is when tires completely lose contact with the
                    road and are riding on a thin film of water. Hydroplaning
                    limits your ability to control your car.
                  </p>
                </div>
              </Col>
              <Col md={4} xs={12}>
                <div className="hydro_img">
                  <img src={Hydro} alt="Image" />
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg={6} md={9} xs={12}>
                <h2 className="same_heading">
                  HOW DO YOU PREVENT HYDROPLANING?
                </h2>
              </Col>
            </Row>

            <Row
              className="mt-3 hydro_margin"
              data-aos="fade-right"
              data-aos-duration="2000"
            >
              <Col lg={3} md={6} xs={12}>
                <div className="prevent_content text-center">
                  <p>Invest in good tires with deep tread.</p>
                </div>
              </Col>

              <Col lg={3} md={6} xs={12}>
                <div className="prevent_content text-center">
                  <p>Always have properly inflated tires.</p>
                </div>
              </Col>

              <Col lg={3} md={6} xs={12}>
                <div className="prevent_content text-center">
                  <p>
                    Reduce speed to about one-third when driving on wet
                    roadways.
                  </p>
                </div>
              </Col>

              <Col lg={3} md={6} xs={12}>
                <div className="prevent_content text-center">
                  <p>Avoid driving through standing water.</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        

        <div className="avoid_skid mt-5 mb-5">
       
          <div className="avoid_skid_h5p" id="h5p_content_navigate">
            <SkidsSliderComponentCopy totalOuterSlides={6} />
          </div>

          <Container className="mt-5 pb-4">
            <p className="skids_para">
              The below video will explain how to avoid and handle skids. Click
              the Play button to start the video.
            </p>

            <video
              id="video"
              width="100%"
              height="400"
              autoplay={true}
              onEnded={() => {
                setVideoOneStatus(true);
              }}
              controls
              controlsList="nodownload noplaybackrate"
            >
              <source src={Skid} type="video/mp4" />
            </video>
          </Container>
        </div>
      </Container>
    </div>
  );
};
