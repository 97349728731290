import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LOGO_Horizontal from "../../../../Images/LOGO_Horizontal.png";
import Overview from "../../../../Images/module6/overview.png";
import Objective from "../../../../Images/module6/objective.png";
import Driving from "../../../../Images/module6/driving.png";
import Hammer from "../../../../Images/module6/hammer.png";
import Action from "../../../../Images/module6/action.png";
import Expression1 from "../../../../Images/module6/expression/1.png";
import Expression2 from "../../../../Images/module6/expression/2.png";
import Expression3 from "../../../../Images/module6/expression/3.png";
import Expression4 from "../../../../Images/module6/expression/4.png";
import Expression5 from "../../../../Images/module6/expression/5.png";
import Expression6 from "../../../../Images/module6/expression/6.png";
import Expression7 from "../../../../Images/module6/expression/7.png";
import Expression8 from "../../../../Images/module6/expression/8.png";
import Expression9 from "../../../../Images/module6/expression/9.png";
import Expression10 from "../../../../Images/module6/expression/10.png";
import Expression11 from "../../../../Images/module6/expression/11.png";
import Expression12 from "../../../../Images/module6/expression/12.png";
import Drugs from "../../../../Images/module6/drugs.png";
import Graduated from "../../../../Images/module6/graduated.png";
import Factor from "../../../../Images/module6/factor.png";
import License from "../../../../Images/module6/license.png";
import SchoolDriving from "../../../../Images/module6/driving_school.png";
import Attitude from "../../../../Images/module6/attitude.png";
import JuniorDriver from "../../../../Images/module6/junior_driver.png";
import DriverLicense from "../../../../Images/module6/m616.jpg";
import TrafficViolation from "../../../../Images/module6/m617.png";
import TrafficViolationFooter from "../../../../Images/module6/m618.jpg";
import GraduatedLaw from "../../../../Images/module6/graduatedlaw-without-logo.mp4";
import AOS from "aos";
import "aos/dist/aos.css";

import "./ModuleSix.css";

import { H5PEditorUI, H5PPlayerUI } from "@lumieducation/h5p-react";
import { ContentService } from "../services/ContentService";

export const ModuleSixPart2 = () => {
  const [scroll, setScroll] = useState(false);

  const contentService = new ContentService("/h5p");
  const h5pPlayer = React.useRef(H5PPlayerUI);

  const [loading, setLoading] = useState(true);
  const [active, setActive] = useState(false);

  const [selectedAnswer, setSelectedAnswer] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);
  const correctAnswer = "A";

  const question = "How do feelings and attitude differ?";

  const answerChoices = [
    {
      label: "A",
      value: "A",
      content:
        "Feelings are constantly changing, while attitudes tend to be constant.",
    },
    {
      label: "B",
      value: "B",
      content: "Feelings and attitudes are the same thing.",
    },
    {
      label: "C",
      value: "C",
      content:
        "Attitudes cannot influence the way you act, while feelings can.",
    },
    {
      label: "D",
      value: "D",
      content: "An 'I don't care' attitude can help you become a safe driver.",
    },
  ];

  const handleAnswerSelect = (answer) => {
    setActive(true);
    setSelectedAnswer(answer);
  };

  const onPlayerInitialized = () => {
    setLoading(false);
  };

  useEffect(() => {
    AOS.init();
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  useEffect(() => {
    sessionStorage.setItem("HAS_VIDEO", true);
  }, []);
  useEffect(() => {
    sessionStorage.removeItem('HAS_H5P_ACTIVITY');
    sessionStorage.removeItem('HAS_H5P_ACTIVITY_SUBMIT');
  }, []);

  var video = document.getElementById("video");
  useEffect(() => {
    if (video) {
      var supposedCurrentTime = 0;
      video.addEventListener("timeupdate", function () {
        if (!video.seeking) {
          supposedCurrentTime = video.currentTime;
        }
      });
      // prevent user from seeking
      video.addEventListener("seeking", function () {
        // guard agains infinite recursion:
        // user seeks, seeking is fired, currentTime is modified, seeking is fired, current time is modified, ....
        var delta = video.currentTime - supposedCurrentTime;
        if (Math.abs(delta) > 0.01) {
          console.log("Seeking is disabled");
          video.currentTime = supposedCurrentTime;
        }
      });
      // delete the following event handler if rewind is not required
      video.addEventListener("ended", function () {
        // reset state in order to allow for rewind
        supposedCurrentTime = 0;
      });
    }
  }, [video]);

  return (
    <div className="module6_course module_font same_width_module mb-md-4">
      <Container>

        <div className="graduate_driver">
          <div
            className="graduate_head_section"
            data-aos="zoom-in"
            data-aos-duration="2000"
          >
            <h2>
              THE GRADUATED DRIVER LICENSING LAW & SPECIAL LIMITATIONS PLACED
              UPON JUNIOR DRIVERS
            </h2>

            <span>
              Note: The information in this section pertains to 16- and
              17-year-old drivers.
            </span>
          </div>

          <Container>
            <h4>WHAT IS A JUNIOR DRIVER?</h4>

            <Row>
              <Col md={8} xs={12}>
                <ul className="p-0">
                  <li>
                    Drivers under the age of 18 that don't have a driver
                    education MV-285 Student Certificate of Completion are
                    considered to be junior drivers.
                    <p className="mt-3">
                      <span className="mb-2"> Young driver statistics: </span>
                      Car crashes are the leading cause of death for young
                      drivers (drivers ages 16-24).
                    </p>
                  </li>

                  <li>
                    Drivers ages 16-20 are overrepresented in crashes; 4% of the
                    licensed drivers in New York are ages 16-20, but 8% of the
                    drivers involved in crashes in 2015 were in this age group.
                  </li>
                </ul>
              </Col>

              <div className="drive_img">
                <img src={SchoolDriving} alt="Image" />
              </div>

              <Col md={12} xs={12}>
                <ul className="p-0">
                  <li>
                    The younger the driver, the more likely he or she is to
                    crash. Crash rates of 16- and 17-year-old drivers are 35%
                    higher than that of 18 to 20-year-old drivers, and 62%
                    higher than 21 to 24-year olds.
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="risk_factor mt-5">
          <Container>
            <h5 className="mb-3">KEY RISK FACTORS FOR TEENS:</h5>
            <Row>
              <Col md={3} xs={12} className="mb-md-0 mb-2">
                <div className="risk_factor_content">
                  <p>Immaturity</p>
                </div>
              </Col>

              <Col md={3} xs={12} className="mb-md-0 mb-2">
                <div className="risk_factor_content">
                  <p>Inexperience and lack of driving skills</p>
                </div>
              </Col>

              <Col md={3} xs={12} className="mb-md-0 mb-2">
                <div className="risk_factor_content">
                  <p>
                    Judgment and decision-making ability is not fully developed
                  </p>
                </div>
              </Col>

              <Col md={3} xs={12} className="mb-md-0 mb-2">
                <div className="risk_factor_content">
                  <p>
                    Risky driving behaviours (speeding, distracted driving,
                    impaired driving)
                  </p>
                </div>
              </Col>
            </Row>
            <h5 className="mt-3 mb-3">GRADUATED DRIVER LICENSING LAW (GDL)</h5>
            <ul className="p-0">
              <li>
                {/* The Graduated Driver Licensing law (GDL) was developed to address
              the safety and risk issues of young drivers. */}
                The Graduated Driver Licensing Law (GDL) & Special Limitations
                Placed Upon Junior Drivers
              </li>

              <li>
                GDL gradually introduces young drivers to unrestricted driving
                privileges through three stages:
              </li>
            </ul>
            <div
              className="risk_factor_img text-center mt-5 mb-5"
              data-aos="zoom-in"
              data-aos-duration="2000"
            >
              <img src={Factor} />
            </div>
            <Row>
              <Col md={5} xs={12}>
                <div
                  className="graduated_img"
                  data-aos="zoom-in"
                  data-aos-duration="2000"
                >
                  <img src={Graduated} alt="Image" />
                </div>
              </Col>

              <Col md={7} xs={12}>
                <h5 className="mt-md-0 mt-3">
                  THE GRADUATED LICENSE LAW AND RESTRICTIONS FOR DRIVERS UNDER
                  18
                </h5>

                <p className="risk_para">
                  The Graduated License Law imposes limitations on individuals
                  under 18 years old holding a junior learner permit or junior
                  driver license (Class DJ, MJ, or DJMJ). These restrictions
                  vary depending on if you have a Junior License or Junior
                  Permit, and when and where you will be driving in the state of
                  New York.
                </p>

                <ul className="p-0">
                  <li>
                    Whether you have a junior learner permit or a junior driver
                    license
                  </li>

                  <li>Where and when you will be driving in New York State</li>
                </ul>
              </Col>
            </Row>
            <div className="check_region mt-3 mb-5">
              <Row>
                <Col md={5} xs={12}>
                  <h6 className="check_region_content">
                    Check the restrictions in your region!
                  </h6>
                </Col>

                <Col md={2} xs={12}>
                  <div className="check_region_img text-md-right pt-3">
                    <img src={License} alt="Image" />
                  </div>
                </Col>

                <Col md={5} xs={12}>
                  <p className="check_region_content_para mt-2">
                    DMV - graduated license law & restrictions for drivers under
                    18
                  </p>
                </Col>
              </Row>
            </div>
            <p className="region_para">
              The following video will explain The Graduated License Law,
              Restrictions, and Penalties for Drivers Under 18. Click the Play
              button to start the video.
            </p>
            <video
              id="video"
              width="100%"
              height="400"
              onEnded={() => {
                sessionStorage.setItem("VIDEO_PLAYED", "COMPLETED");
              }}
              autoplay={true}
              controls
              controlsList="nodownload noplaybackrate"
            >
              <source src={GraduatedLaw} type="video/mp4" />
            </video>
            <div className="mt-5">
              <h5 className="mt-md-0 mt-3">
                GENERAL RESTRICTIONS FOR JUNIOR DRIVERS
              </h5>
              <ul className="p-0">
                <li>
                  If you're applying for a junior permit or junior license
                  (Class DJ or MJ), you will need to get parental consent.
                  Junior drivers must hold a valid permit for at least six
                  months before they are eligible to take the road test. Keep in
                  mind that any time the permit is suspended or revoked does not
                  count toward the six-month requirement.
                </li>

                <li>
                  When supervision is required by law, only the supervising
                  driver is allowed to sit in the front seat. Additionally,
                  junior drivers are restricted from having more than one
                  passenger under the age of 21 in the vehicle, unless those
                  passengers are immediate family members or the supervising
                  driver is a licensed parent, guardian, or certified driving
                  instructor.
                </li>
              </ul>

              <div>
                <ul className="p-0">
                  <Row>
                    <Col md={6}>
                      <li>
                        All junior drivers and their passengers must wear seat
                        belts, with one seat belt per person. Children under
                        four years old must be secured in a federally-approved
                        child safety seat.
                      </li>
                      <li>
                        Before taking the road test, a junior permit holder must
                        submit a Certification of Supervised Driving form
                        (MV-262) to the examiner. This form verifies that the
                        junior driver has completed a minimum of 50 hours of
                        supervised driving, including at least 15 hours of
                        nighttime driving.
                      </li>
                    </Col>
                    <Col md={6}>
                      <img
                        src={JuniorDriver}
                        className=""
                        width="100%"
                        alt="Image"
                      />
                    </Col>
                  </Row>
                </ul>
                <img
                  src={DriverLicense}
                  className=""
                  width="100%"
                  alt="Image"
                />
              </div>
              <div className="mt-5 before-star">
                <h5 className="mt-md-0 mt-5">
                  Junior permit holders may not drive:
                </h5>
                <ul className="p-0">
                  <li>
                    At any time on any street within a park in New York City, or
                    on bridges or tunnels managed by the Triborough Bridge and
                    Tunnel Authority.
                  </li>
                  <li>
                    On the Cross County, Hutchinson River, Saw Mill River, or
                    Taconic State Parkways in Westchester County.
                  </li>
                  <li>In any DMV Road Test Area under any conditions.</li>
                  <li>
                    Without a qualified supervising driver or outside the
                    geographical restrictions that apply to the junior permit
                    holder's area.
                  </li>
                </ul>
              </div>

              <div className="mt-5 traffic_penalities">
                <h5 className="mt-md-0 mt-5">
                  Penalties for traffic violation as a junior driver
                </h5>
                <ul className="p-0">
                  <li>
                    If the junior driver is convicted of a single "use of a
                    mobile telephone/use of portable electronic device" offense
                    during the probationary period, their license will be
                    suspended for 120 days.
                  </li>
                  <li>
                    If a junior driver has their license, permit, or driving
                    privileges reinstated after a suspension or revocation and
                    commits another offense, their junior permit or license will
                    be revoked for 60 days.
                  </li>
                  <div className="violation_img">
                    <img
                      src={TrafficViolation}
                      className=""
                      width="60%"
                      alt="Image"
                    />
                  </div>
                  <Row className="mb-5">
                    <Col md={6} xs={12} className="mb-md-0 mb-2">
                      <div className="violation_block">
                        <p>
                          If a junior driver is convicted of any two moving
                          violations during their probationary period, their
                          junior permit or license will be revoked.
                        </p>
                      </div>
                    </Col>

                    <Col md={6} xs={12} className="mb-md-0 mb-2">
                      <div className="violation_block">
                        <p>
                          If a junior driver is found guilty of committing a
                          single, more serious violation (as listed below)
                          during the probationary period.
                        </p>
                      </div>
                    </Col>
                  </Row>
                  <h5>
                    A “serious traffic violation” is generally a violation that
                    carries three or more driver violation points. Some examples
                    are:
                  </h5>
                  <ul className="p-0">
                    <li>Speeding</li>

                    <li>Reckless driving</li>
                    <li>Following too closely</li>
                    <li>Participating in a speed contest</li>
                    <li>Use of mobile telephone (such as a cellular phone)</li>
                    <li>
                      Use of portable electronic device (such as a smartphone,
                      GPS or MP3 player)
                    </li>
                  </ul>
                </ul>
                <p>
                  If, however, the junior driver is found guilty of committing a
                  single "use of a mobile telephone/use of portable electronic
                  device" violation during the probation period, their license
                  will be revoked for 1 year.
                </p>
              </div>
            </div>
            <div className="">
              <img
                src={TrafficViolationFooter}
                className=""
                width="100%"
                alt="Image"
              />
            </div>
          </Container>
        </div>
      </Container>
    </div>
  );
};
