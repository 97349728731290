import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Logo from "../../../../Images/logo.png";
import Alcohol from "../../../../Images/module8/alcohol.png";
import Drugs from "../../../../Images/module8/drugs.png";
import DriveAlcohol from "../../../../Images/module8/driveacohol.png";
import Physical from "../../../../Images/module8/physical.png";
import Suspension from "../../../../Images/module8/suspension.png";
import Law from "../../../../Images/module8/law.png";
import Pound from "../../../../Images/module8/pound.png";
import PersonCar from "../../../../Images/module8/person_car.png";
import Risk from "../../../../Images/module8/risk_drug.png";
import ProvisionLaw from "../../../../Images/module8/provision.png";
import LawEffect from "../../../../Images/module8/law_effect.png";
import Leandra from "../../../../Images/module8/leandra.mp4";

// import faces image
import face1Image from "../../../../Images/module8/character-face-images/face-1.jpg";
import face2Image from "../../../../Images/module8/character-face-images/face-2.jpg";
import face3Image from "../../../../Images/module8/character-face-images/face-3.jpg";
import face4Image from "../../../../Images/module8/character-face-images/face-4.jpg";
import face5Image from "../../../../Images/module8/character-face-images/face-5.jpg";
import face6Image from "../../../../Images/module8/character-face-images/face-6.jpg";
import face7Image from "../../../../Images/module8/character-face-images/face-7.jpg";
import face8Image from "../../../../Images/module8/character-face-images/face-8.jpg";
import face9Image from "../../../../Images/module8/character-face-images/face-9.jpg";
import face10Image from "../../../../Images/module8/character-face-images/face-10.jpg";
import face11Image from "../../../../Images/module8/character-face-images/face-11.jpg";
import face12Image from "../../../../Images/module8/character-face-images/face-12.jpg";
import face13Image from "../../../../Images/module8/character-face-images/face-13.jpg";
import face14Image from "../../../../Images/module8/character-face-images/face-14.jpg";
import face15Image from "../../../../Images/module8/character-face-images/face-15.jpg";
import face16Image from "../../../../Images/module8/character-face-images/face-16.jpg";
import face17Image from "../../../../Images/module8/character-face-images/face-17.jpg";
import face18Image from "../../../../Images/module8/character-face-images/face-18.jpg";
import face19Image from "../../../../Images/module8/character-face-images/face-19.jpg";

// import example scenario images
import exampleScenarioImages1 from "../../../../Images/module8/example-scenario-images/example-scenario-1.png";
import exampleScenarioImages2 from "../../../../Images/module8/example-scenario-images/example-scenario-2.png";
import exampleScenarioImages3 from "../../../../Images/module8/example-scenario-images/example-scenario-3.png";
import exampleScenarioImages4 from "../../../../Images/module8/example-scenario-images/example-scenario-4.png";
import exampleScenarioImages5 from "../../../../Images/module8/example-scenario-images/example-scenario-5.png";
import exampleScenarioImages6 from "../../../../Images/module8/example-scenario-images/example-scenario-6.png";
import exampleScenarioImages7 from "../../../../Images/module8/example-scenario-images/example-scenario-7.png";
import exampleScenarioImages8 from "../../../../Images/module8/example-scenario-images/example-scenario-8.png";

import swal from "sweetalert";
import peopleWhoAreHighRiskImage from "../../../../Images/module8/people-who-are-high-risk.jpg";
import peopleWhoAreLowRiskImage from "../../../../Images/module8/people-who-are-low-risk.jpg";
import situationsThatAreHighRiskImage from "../../../../Images/module8/situations-that-are-high-risk.jpg";
import situationsThatAreLowRiskImage from "../../../../Images/module8/situations-that-are-low-risk.jpg";

import AOS from "aos";
import "aos/dist/aos.css";

import "./ModuleEight.css";

import { H5PEditorUI, H5PPlayerUI } from "@lumieducation/h5p-react";
import { ContentService } from "../services/ContentService";
import { NONE } from "../../../../Utils/PermissionConstant";

export const ModuleEightPart5 = () => {
  const [scroll, setScroll] = useState(false);

  const contentService = new ContentService("/h5p");
  const h5pPlayer = React.useRef(H5PPlayerUI);

  const [loading, setLoading] = useState(true);

  const onPlayerInitialized = () => {
    setLoading(false);
  };

  const [image1Clicked, setImage1Clicked] = useState(false);
  const [image2Clicked, setImage2Clicked] = useState(false);
  const [image3Clicked, setImage3Clicked] = useState(false);
  const [image4Clicked, setImage4Clicked] = useState(false);
  const [allFaceImagesClicked, setAllFaceImagesClicked] = useState(false);
  const [decisionMatrixImagesClicked, setDecisionMatrixImagesClicked] =
    useState(false);

    useEffect(() => {
      sessionStorage.setItem("HAS_H5P_ACTIVITY", true);
      sessionStorage.setItem("HAS_H5P_ACTIVITY_SUBMIT", false);
    }, []);

  const handleImage1Click = (content) => {
    console.warn('11111111111111111');
    setImage1Clicked(true);
    handleImageClick("Details", content);
  };

  const handleImage2Click = (content) => {
    console.warn('22222222222222222');
    setImage2Clicked(true);
    handleImageClick("Details", content);
  };

  const handleImage3Click = (content) => {
    setImage3Clicked(true);
    handleImageClick("Details", content);
  };

  const handleImage4Click = (content) => {
    setImage4Clicked(true);
    handleImageClick("Details", content);
  };

  useEffect(() => {
    if (image1Clicked && image2Clicked && image3Clicked && image4Clicked) {
      sessionStorage.setItem("HAS_H5P_ACTIVITY_SUBMIT", true);
    }
  }, [image1Clicked, image2Clicked, image3Clicked, image4Clicked]);

  // const clickedStatus = {
  //   1: false,
  //   2: false,
  //   3: false,
  //   4: false,
  //   5: false,
  //   6: false,
  //   7: false,
  //   8: false,
  //   9: false,
  //   10: false,
  //   11: false,
  //   12: false,
  //   13: false,
  //   14: false,
  //   15: false,
  //   16: false,
  // };
  
  const handleImageClick = (title, content) => {
    swal({
      title: title,
      content: {
        element: "div",
        attributes: {
          innerHTML: content,
        },
      },
    });
  };

  useEffect(() => {
    AOS.init();
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  return (
    <div className="module8_course module_font same_width_module mb-md-4">
    <Container>

      <div className="provision_law pb-5">
        <Container>
          <h2
            className="same_alcohol_head"
            data-aos="zoom-in-right"
            data-aos-duration="2000"
          >
            Crimes Behind the Wheel: Understanding NY’s Laws on Vehicular
            Assault and Manslaughter
          </h2>
          <h5 className="mt-5 mb-3">
            What Happens When Drunk Driving Leads to Injury?
          </h5>
          <p>
            <b>Vehicular Assault in the Second Degree (Penal Law §120.03)</b>
          </p>
          <p>
            Driving under the influence is not just a poor choice; it can lead
            to devastating consequences. When a driver is intoxicated or
            impaired by drugs and causes serious injury to another person,
            they may be charged with{" "}
            <b>vehicular assault in the second degree</b>. This charge applies
            to various forms of impairment:
          </p>
          <ul className="p-0">
            <li>
              Driving with a <b>Blood Alcohol Content (BAC)</b> above the
              legal limit, which is 0.08% in New York (§1192(2)).
            </li>
            <li>
              Driving while under the influence of alcohol to a degree that
              impairs the ability to drive safely (§1192(3)).
            </li>
            <li>Driving while impaired by drugs (§1192(4)).</li>
            <li>
              Driving while impaired by both drugs and alcohol (§1192(4-a)).
            </li>
          </ul>
          <p>
            The injury must be directly caused by the driver’s impaired
            condition. This offense is classified as a <b>Class E Felony</b>,
            which is the lowest level of felony but still carries significant
            penalties. If convicted, the driver faces up to{" "}
            <b>4 years in prison</b>.
          </p>
          <p>
            It’s important to understand that New York law treats any
            impairment seriously, whether it involves alcohol, drugs, or a
            combination of both. Even if a driver believes they are only
            "slightly" impaired, the law makes no distinction once serious
            injury occurs.
          </p>

          <p>
            <strong>Example Scenario:</strong>
          </p>
          <Row>
            <Col md={6}>
              <img
                src={exampleScenarioImages1}
                alt="Example 1"
                className="example-scenario-1"
              />
            </Col>
            <Col md={6}>
              <p>
                Imagine a driver who has had several drinks at a party. They
                feel "buzzed" but decide they are capable of driving home. On
                the way, they fail to stop at a red light and collide with
                another vehicle, seriously injuring the other driver. Because
                the driver’s BAC was found to be over the legal limit, they
                are charged with <b>vehicular assault in the second degree</b>
                .
              </p>
            </Col>
          </Row>

          <h5 className="mt-5 mb-3">
            Is Your Blood Alcohol Level Above 0.18? You Could Face a Higher
            Charge
          </h5>
          <p>
            <b>Vehicular Assault in the First Degree (Penal Law §120.04)</b>
          </p>
          <p>
            For those who believe that driving with a higher <b>BAC</b> is not
            a big deal, think again. When a driver’s BAC is
            <b> 0.18 or more</b>, the charge can escalate from second-degree
            to <b>vehicular assault in the first degree</b>. This elevated
            charge occurs under specific aggravating circumstances, which
            include:
          </p>
          <ul className="p-0">
            <li>
              Driving with a <b>BAC of 0.18 or higher</b>.
            </li>
            <li>
              Driving with a <b>suspended or revoked license</b>.
            </li>
            <li>
              Violating <b>Leandra’s Law</b>, which involves driving
              intoxicated with a child under 15 in the vehicle.
            </li>
          </ul>
          <p>
            The penalties are much harsher because the driver’s condition is
            seen as more reckless, given the level of impairment.{" "}
            <b>Vehicular assault in the first degree</b> is a Class D Felony,
            which carries a maximum sentence of <b>up to 7 years in prison</b>
            .
          </p>

          <h5 className="mt-5 mb-3">Leandra’s Law and Its Impact:</h5>
          <p>
            Enacted in New York in 2009, <b>Leandra’s Law</b> is a strict
            provision that automatically elevates charges when a child under
            15 is in the car during a DWI incident. This law was named after
            11-year-old Leandra Rosado, who tragically died in a car accident
            while a passenger in an intoxicated driver’s vehicle. The law
            reflects society’s increasing concern over endangering children’s
            lives due to drunk driving.
          </p>
          <p>
            <strong>Example Scenario:</strong>
          </p>
          <Row>
            <Col md={6}>
              <img
                src={exampleScenarioImages2}
                alt="Example 2"
                className="example-scenario-2"
              />
            </Col>
            <Col md={6}>
              <p>
                Consider a driver who has been out with friends and ends up
                with a <b>BAC of 0.19</b> after drinking heavily. They decide
                to drive home with their two children in the backseat. On the
                way, they lose control and hit a pedestrian, causing serious
                injury. Because of their high BAC and the presence of
                children, they are charged with{" "}
                <b>vehicular assault in the first degree</b> under Leandra’s
                Law, facing up to <b>7 years in prison</b>.
              </p>
            </Col>
          </Row>

          <h5 className="mt-5 mb-3">
            Reckless Driving Can Mean Much More than a Ticket
          </h5>
          <p>
            <b>Aggravated Vehicular Assault (Penal Law §120.04-a)</b>
          </p>
          <p>
            Recklessness on the road—such as speeding, tailgating, or weaving
            through traffic—is already dangerous. But when reckless driving is
            combined with intoxication or impairment, it can lead to
            <b> aggravated vehicular assault</b>, a more severe charge. The
            law defines reckless driving as acting with a gross disregard for
            the safety of others.
          </p>
          <p>
            <b>
              Several factors can elevate the offense to aggravated vehicular
              assault:
            </b>
          </p>
          <ul className="p-0">
            <li>
              <b>Reckless driving</b> that endangers others while impaired.
            </li>
            <li>
              Having a <b>BAC of 0.18 or higher</b>.
            </li>
            <li>
              Driving with a <b>suspended license</b>.
            </li>
            <li>Causing serious physical injury to more than one person.</li>
            <li>
              <b>Prior convictions for DWI</b>.
            </li>
          </ul>
          <p>
            This offense is a <b>Class C Felony</b>, meaning it carries the
            possibility of <b>up to 15 years in prison</b>. The law takes into
            account not only the driver’s impairment but also the recklessness
            of their behavior.
          </p>
          <p>
            <strong>Example Scenario:</strong>
          </p>

          <Row>
            <Col md={6}>
              <img
                src={exampleScenarioImages3}
                alt="Example 3"
                className="example-scenario-3"
              />
            </Col>
            <Col md={6}>
              <p>
                A driver with a <b>BAC of 0.20</b> is speeding on the highway,
                swerving between lanes. They crash into another car, causing
                multiple injuries to the occupants. Due to their reckless
                driving and high BAC, the charge is elevated to{" "}
                <b>aggravated vehicular assault</b>, with the potential for{" "}
                <b>15 years in prison</b>.
              </p>
            </Col>
          </Row>

          <h5 className="mt-5 mb-3">When Carelessness Leads to Death</h5>
          <p>
            <b>Criminally Negligent Homicide (Penal Law §125.10)</b>
          </p>
          <p>
            Sometimes, a driver’s carelessness can lead to tragic results, and
            when this happens, <b>criminally negligent homicide</b> charges
            may apply. This offense involves causing the death of another
            person through negligence, rather than intent. The law defines
            criminal negligence as failing to perceive a substantial and
            unjustifiable risk that results in death. This means the driver
            did not act with intent to kill, but their failure to act
            responsibly led to a fatal outcome.
          </p>
          <p>
            Criminally negligent homicide is a <b>Class E Felony</b>, with a
            potential sentence of <b>up to 4 years in prison</b>. The key
            difference between criminally negligent homicide and other charges
            like manslaughter is the absence of intent. However, the
            consequences are no less severe for the families of victims.
          </p>
          <p>
            <strong>Example Scenario:</strong>
          </p>

          <Row>
            <Col md={6}>
              <img
                src={exampleScenarioImages4}
                alt="Example 4"
                className="example-scenario-4"
              />
            </Col>
            <Col md={6}>
              <p>
                A driver, distracted by their phone, veers off the road and
                strikes a pedestrian. The pedestrian dies from their injuries.
                Although the driver did not intend to cause harm, their
                failure to pay attention constitutes{" "}
                <b>criminal negligence</b>, leading to{" "}
                <b>criminally negligent homicide charges</b>.
              </p>
            </Col>
          </Row>

          <h5 className="mt-5 mb-3">
            The Price of a Fatal Mistake on the Road
          </h5>
          <p>
            <b>
              Vehicular Manslaughter in the Second Degree (Penal Law §125.12)
            </b>{" "}
          </p>
          <p>
            When a fatal accident is caused by a driver under the influence of
            alcohol or drugs, the charge often escalates to{" "}
            <b>vehicular manslaughter in the second degree</b>. This charge is
            used when the driver’s actions, whether due to impairment or
            negligence, directly result in someone’s death. For example, this
            could include:
          </p>
          <ul className="p-0">
            <li>
              Driving with a <b>BAC over 0.08%</b>.
            </li>
            <li>
              Driving under the influence of <b>drugs</b>.
            </li>
            <li>
              Operating a vehicle with dangerous cargo or materials while
              impaired.
            </li>
          </ul>
          <p>
            This offense is classified as a <b>Class D Felony</b> and carries
            a maximum sentence of <b>up to 7 years in prison</b>. Unlike
            criminally negligent homicide,{" "}
            <b>vehicular manslaughter in the second degree </b>
            specifically involves impairment by drugs or alcohol.
          </p>
          <p>
            <strong>Example Scenario:</strong>
          </p>
          <Row>
            <Col md={6}>
              <img
                src={exampleScenarioImages5}
                alt="Example 5"
                className="example-scenario-5"
              />
            </Col>
            <Col md={6}>
              <p>
                A truck driver carrying flammable materials has been drinking
                and is found to have a BAC of 0.12. They lose control of their
                vehicle, causing an explosion that results in a bystander’s
                death. The driver is charged with vehicular manslaughter in
                the second degree due to their intoxication and the hazardous
                nature of the accident.
              </p>
            </Col>
          </Row>

          <h5 className="mt-5 mb-3">
            When the Stakes Are Even Higher: What Makes Vehicular Manslaughter
            First Degree?
          </h5>
          <p>
            <b>
              Vehicular Manslaughter in the First Degree (Penal Law §125.13)
            </b>
          </p>
          <p>
            Certain aggravating circumstances can elevate a{" "}
            <b>second-degree vehicular manslaughter charge</b> to{" "}
            <b>first-degree vehicular manslaughter</b>. This is more severe,
            as the driver’s behavior shows heightened recklessness or repeated
            offenses. These factors include:
          </p>
          <ul className="p-0">
            <li>
              Driving with a <b>suspended or revoked license</b>.
            </li>
            <li>
              Having a <b>prior DWI conviction</b> within the last 10 years.
            </li>
            <li>
              <b>Causing the death of multiple people.</b>
            </li>
            <li>
              Driving with a <b>BAC over 0.18%</b>.
            </li>
          </ul>
          <p>
            As a{" "}
            <b>Class C Felony, vehicular manslaughter in the first degree </b>
            can lead to <b>up to 15 years in prison</b>.
          </p>
          <p>
            <strong>Example Scenario:</strong>
          </p>

          <Row>
            <Col md={6}>
              <img
                src={exampleScenarioImages6}
                alt="Example 6"
                className="example-scenario-6"
              />
            </Col>
            <Col md={6}>
              <p>
                A driver with a prior DWI conviction is caught driving with a
                <b> BAC of 0.20</b>, leading to a fatal crash that claims the
                lives of two pedestrians. The driver faces{" "}
                <b>vehicular manslaughter in the first degree</b>, carrying a
                much harsher sentence than the second-degree charge.
              </p>
            </Col>
          </Row>

          <h5 className="mt-5 mb-3">
            The Most Serious Charge: Aggravated Vehicular Homicide
          </h5>
          <p>
            <b>Aggravated Vehicular Homicide (Penal Law §125.14)</b>
          </p>
          <p>
            The most severe of all vehicular crimes,{" "}
            <b>aggravated vehicular homicide</b> involves recklessness,
            intoxication, and fatal consequences. The charge is escalated when
            multiple aggravating factors are present, such as:
          </p>
          <ul className="p-0">
            <li>
              <b>Reckless driving</b> combined with a <b>BAC over 0.18%</b>.
            </li>
            <li>
              Driving with a <b>suspended license</b> due to prior violations.
            </li>
            <li>
              <b>Causing the death of more than one person.</b>
            </li>
          </ul>
          <p>
            This offense is a <b>Class B Felony</b>, carrying the harshest
            penalties, including <b>up to 25 years in prison</b>. Aggravated
            vehicular homicide is reserved for the most egregious cases of
            reckless and impaired driving that lead to fatal outcomes.
          </p>
          <p>
            <strong>Example Scenario:</strong>
          </p>

          <Row>
            <Col md={6}>
              <img
                src={exampleScenarioImages7}
                alt="Example 7"
                className="example-scenario-7"
              />
            </Col>
            <Col md={6}>
              <p>
                A driver with a <b>BAC of 0.22</b> and a suspended license
                causes a multi-car pileup, resulting in several deaths. Given
                the recklessness and multiple fatalities, they are charged
                with
                <b> aggravated vehicular homicide</b>, facing{" "}
                <b>25 years in prison</b>.
              </p>
            </Col>
          </Row>

          <h5 className="mt-5 mb-3">
            Ignition Interlocks: A Lifeline or a Burden?
          </h5>
          <p>
            <b>
              Driving While Intoxicated Offenses (Penal Law §§60.36 & 60.21)
            </b>{" "}
          </p>
          <p>
            For drivers convicted of <b>DWI offenses</b>, courts often require
            the installation of an <b>ignition interlock device</b> as part of
            the sentence. This device prevents the vehicle from starting
            unless the driver provides a breath sample that registers a{" "}
            <b>BAC below the legal limit</b>. It serves as a preventive
            measure to stop repeat offenses. Courts may impose this condition
            alongside other penalties, such as fines or imprisonment.
          </p>

          <p>
            <strong>Example Scenario:</strong>
          </p>
          <Row>
            <Col md={6} className="example-scenario-8-div">
              <img
                src={exampleScenarioImages8}
                alt="Example 8"
                className="example-scenario-8"
              />
            </Col>
            <Col md={6}>
              <p>
                A driver convicted of a DWI must install an{" "}
                <b>ignition interlock device</b> in their car for one year.
                They must blow into the device before starting the vehicle,
                ensuring they are sober. Failing to comply with this
                requirement can result in additional penalties or extensions
                of the interlock period.
              </p>
            </Col>
          </Row>

          <h5 className="mt-5 mb-3">
            How Executive Law Amends Parole Conditions for Serious Offenders
          </h5>
          <p>
            <b>Related Executive Law Amendment (2009)</b>
          </p>
          <p>
            For offenders convicted of serious vehicular crimes such as
            <b> vehicular assault, vehicular manslaughter</b>, or{" "}
            <b>felony DWI</b>, the law imposes strict parole conditions. This
            includes the mandatory installation of an{" "}
            <b>ignition interlock device</b> for the entire duration of their
            parole or conditional release. This ensures that repeat offenders
            are closely monitored to prevent further violations. Additionally,
            drivers whose licenses are revoked are prohibited from driving any
            vehicle during their parole period.
          </p>
        </Container>
      </div>

      <div className="class_law">
        <div className="traffic_law">
          <Container>
            <h2
              className="high_risks_head"
              data-aos="zoom-in-right"
              data-aos-duration="2000"
            >
              THE HIGH RISKS OF USING OTHER DRUGS
            </h2>

            <Row>
              <Col md={8} xs={12}>
                <div className="definition_drug">
                  <h5>DEFINITION OF A DRUG:</h5>

                  <p>
                    Any taken substance taken that will produce a
                    physiological effect when ingested or otherwise introduced
                    into the body.
                  </p>
                  <p>There are many drugs that alter perception. </p>
                </div>
              </Col>

              <Col md={4} xs={12}>
                <div
                  className="risk_drug pt-md-5 pt-lg-0"
                  data-aos="zoom-in-left"
                  data-aos-duration="2000"
                >
                  <img src={Risk} alt="Image" />
                </div>
              </Col>
            </Row>
            <div className="tpyes_drug">
              <Row>
                <Col md={12} xs={12}>
                  <h6>Types of drugs:</h6>
                </Col>

                <Col md={3} xs={6}>
                  <div
                    className="drug_even drug_content_div"
                    data-aos="zoom-in-right"
                    data-aos-duration="2000"
                  >
                    <p>HERBAL</p>
                  </div>
                </Col>

                <Col md={3} xs={6}>
                  <div
                    className="drug_odd drug_content_div"
                    data-aos="zoom-in-right"
                    data-aos-duration="2000"
                  >
                    <p>OVER THE COUNTER</p>
                  </div>
                </Col>

                <Col md={3} xs={6}>
                  <div
                    className="drug_even drug_content_div"
                    data-aos="zoom-in-right"
                    data-aos-duration="2000"
                  >
                    <p>PRESCRIPTION</p>
                  </div>
                </Col>

                <Col md={3} xs={6}>
                  <div
                    className="drug_odd drug_content_div"
                    data-aos="zoom-in-right"
                    data-aos-duration="2000"
                  >
                    <p>ILLICIT</p>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>

        <div className="drug_class mt-5">
          <Container>
            <h4>The classes of drugs:</h4>

            <ul className="p-0">
              <li>
                Stimulants (nicotine, caffeine, cocaine/crack, and
                amphetamines)
              </li>

              <li>Depressants</li>

              <li>
                Hallucinogens (ex. LSD, psilocybin, ecstasy, and peyote)
              </li>

              <li>Narcotics</li>

              <li>Tranquilizers</li>

              <li>
                Marijuana is a distinct category and has properties and
                effects similar to depressants and stimulants
              </li>

              <li>
                Some over-the-counter drugs may be as dangerous as
                prescription and illegal drugs. For example, antihistamine
                contained in many cold remedies, cough medicines, allergy
                medications and decongestants may cause drowsiness.
              </li>
            </ul>

            <h5 className="mt-3 mb-4">
              Read the precautions on the labels of all substances prior to
              using them, especially if you intend to drive.
            </h5>

            <p>
              “Potentiation” or “synergism” are terms used to describe the
              combined effect of two drugs that interact differently than the
              sum of their individual effects. The combined effect is often
              unpredictable.
            </p>
            <p>
              Tolerance is the body’s ability to withstand the effects of
              certain drugs. As a result, the user must take greater amounts
              to sustain a predictable "high".
            </p>
          </Container>
        </div>

        <div className="drug_class mt-5">
          <Container>
            <h4>HOW CERTAIN DRUGS AFFECT DRIVING:</h4>

            <ul className="p-0">
              <li>
                Alcohol is the most widely used and abused drug in the world
                and is a depressant. When consumed, it can impair judgment,
                slow reflexes and hamper eye-hand coordination. If a person is
                stopped by the police while driving under the influence of
                alcohol, they may face penalties such as fines, license
                revocation, higher insurance premiums and a possible jail
                sentence.
              </li>

              <li>
                Oxycontin is a commonly abused narcotic and is a pain killer.
                When consumed, it can cause drowsiness and impair judgment. If
                a person is stopped by the police while driving under the
                influence of Oxycontin, they may face penalties such as fines,
                license revocation, higher insurance premiums and a possible
                jail sentence.
              </li>

              <li>
                Marijuana is a commonly used illegal drug and has properties
                and effects similar to depressants and stimulants. When
                consumed, it can impair judgment, slow reflexes and hamper
                eye-hand coordination. If a person is stopped by the police
                while driving under the influence of marijuana, they may face
                penalties such as fines, license revocation, higher insurance
                premiums and a possible jail sentence.
              </li>

              <li>
                Cocaine/crack is a commonly used illegal drug and a stimulant.
                When consumed, it can cause irritability, lack of
                concentration, and an overestimation of abilities. If a person
                is stopped by the police while driving under the influence of
                cocaine/crack, they may face penalties such as fines, license
                revocation, higher insurance premiums and a possible jail
                sentence.
              </li>

              <li>
                Psilocybin, also known as magic mushrooms, is a naturally
                occurring psychedelic substance that can cause hallucinations,
                altered perception of time, and changes in mood and thought.
                When consumed, it can impair a person's ability to drive,
                making it difficult to focus and react to the road. A person
                under the influence of psilocybin may also experience a
                distorted sense of reality, which can be dangerous when
                operating a vehicle.
              </li>

              <li>
                Ecstasy, also known as MDMA, is a synthetic drug that acts as
                both a stimulant and a hallucinogen. It can cause increased
                energy, euphoria, and emotional warmth, but it can also impair
                a person's ability to drive. Ecstasy can cause confusion,
                disorientation, and an altered perception of time, which can
                make it difficult to react to unexpected situations on the
                road.
              </li>

              <li>
                LSD, also known as acid, is a powerful psychedelic drug that
                can cause hallucinations, altered perception of time, and
                changes in mood and thought. When consumed, it can impair a
                person's ability to drive, making it difficult to focus and
                react to the road. A person under the influence of LSD may
                also experience a distorted sense of reality, which can be
                dangerous when operating a vehicle. Additionally, since the
                effects of LSD can last for several hours, it can be dangerous
                to drive even after the person believes the effects have worn
                off.
              </li>

              <li>
                It is important to note that certain over-the-counter drugs
                may also cause drowsiness and impair driving abilities, and it
                is essential to read the precautions on the labels of all
                substances prior to using them, especially if you intend to
                drive. Also, It is also important to note that the combined
                effect of two drugs that interact differently than the sum of
                their individual effects is often unpredictable, which is
                known as "Potentiation" or "Synergism".
              </li>
            </ul>

            <p>
              The decision to drink and subsequently to drive is based on two
              variables: THE INDIVIDUAL and THE SITUATION. Each of these
              variables has two categories: low risk and high risk. Simply
              put, a LOW RISK PERSON, in a LOW RISK SITUATION can make the
              decision to drink moderately; a HIGH-RISK PERSON, in a HIGH-RISK
              SITUATION should make the choice to not drink.
            </p>
            <p>
              The decision to drive should be made ONLY when one’s ability and
              judgment is not impaired by alcohol or other drugs. Note that
              there has been no “safe” level of consumption established for
              substances such as marijuana or cocaine.
            </p>

            <div className="table_title">
              <h5>Decision Matrix</h5>
            </div>
            <table className="table add-h5p-class-1">
              <thead>
                <tr>
                  <th className="title">Individuals</th>
                  <th className="title">Situations</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td  style={{ border: '2px solid green' }}>
                    <p >People who are low risk:</p>
                    <img
                      src={peopleWhoAreLowRiskImage}
                      style={{ border: image1Clicked ? '2px solid green' : 'none' }}
                      height="250px"
                      alt="Image"
                      onClick={() =>
                        handleImage1Click(
                          `
                <ul className="swal-content-list">
                      <li style="text-align: left;">are in good physical health</li>
                      <li style="text-align: left;">have no emotional disorders</li>
                      <li style="text-align: left;">
                        have no history of alcohol-related problems or of
                        alcoholism in their family
                      </li>
                      <li style="text-align: left;">are not under stress</li>
                    </ul>
                `
                        )
                      }
                    />
                  </td>
                  <td>
                    <p>Situations that are low risk:</p>
                    <img
                      src={situationsThatAreLowRiskImage}
                      height="250px"
                      alt="Image"
                      onClick={() =>
                        handleImage2Click(
                          `
                <ul className="swal-content-list">
                      <li style="text-align: left;">
                        a “safe environment,” where little judgment or
                        physical coordination is or will be required
                      </li>
                      <li style="text-align: left;">
                        where the quantity used is small enough not to cause
                        intoxication or impairment. (There is no established
                        safe level of consumption for substances such as
                        marijuana or cocaine.)
                      </li>
                    </ul>
                `
                        )
                      }
                      style={{ border: image2Clicked ? '2px solid green' : 'none' }}
                    />

                  </td>
                </tr>
                <tr>
                  <td>
                    <p>People who are high risk:</p>
                    <img
                      src={peopleWhoAreHighRiskImage}
                      height="250px"
                      alt="Image"
                      onClick={() =>
                        handleImage3Click(
                          `
                <ul className="swal-content-list">
                      <li style="text-align: left;">
                        have certain physical disorders, of which they may not
                        even be aware
                      </li>
                      <li style="text-align: left;">are experiencing emotional stress or disorder</li>
                      <li style="text-align: left;">
                        are from a family with a history of alcohol-related
                        problems or drug addiction
                      </li>
                      <li style="text-align: left;">have a sensitivity to alcohol</li>
                      <li style="text-align: left;">
                        Particular at-risk populations are:
                      </li>
                      <li style="text-align: left;">Children</li>
                      <li style="text-align: left;">Adolescents</li>
                      <li style="text-align: left;">The elderly</li>
                      <li style="text-align: left;">Women who are pregnant</li>
                    </ul>
                `
                        )
                      }
                      style={{ border: image3Clicked ? '2px solid green' : 'none' }}
                    />
                  </td>
                  <td>
                    <p>Situations that are high risk:</p>
                    <img
                      src={situationsThatAreHighRiskImage}
                      height="250px"
                      alt="Image"
                      onClick={() =>
                        handleImage4Click(
                          `
                <ul className="swal-content-list">
                      <li style="text-align: left;">
                        where judgment and physical coordination are critical
                      </li>
                      <li style="text-align: left;">
                        where use averages more than two standard drinks a day
                      </li>
                      <li style="text-align: left;">
                        when the quantity consumed is sufficient to cause
                        impairment (BAC reaches .02 percent or greater)
                      </li>
                      <li style="text-align: left;">a time of unusual stress or emotional upheaval</li>
                      <li style="text-align: left;">
                        when taking medication, such as sedatives,
                        psychoactive drugs, tranquilizers or antihistamine
                      </li>
                    </ul>
                `
                        )
                      }
                      style={{ border: image4Clicked ? '2px solid green' : 'none' }}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <h3 className="h5p-content-note">
              Note: Please click on all above images and read content before
              going further.
            </h3>
          </Container>
        </div>
      </div>
    </Container>
  </div>
  );
};
