import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import swal from "sweetalert";

import {
  getUserCourseByIdAction,
  socketDisconnectAction,
  logoutUserAction
} from "../Redux/Actions/";

/**
 *
 * @param {string} courseId course id
 * @returns status of course is completed or not
 */
const useCourseCompleteStatus = (courseId, socket) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [userCourse, setUserCourse] = useState();

  const isCourseCompleted = useMemo(() => {
    if (userCourse) {
      const notCompletedCourse = userCourse?.users?.filter(
        (user) => user.user_course.course_status === "COMPLETED"
      );

      return notCompletedCourse[0];
    }
  }, [userCourse]);

  // const excludeRoutes = ["/signin"];
  const shouldNotExecute =
    location.pathname.includes("/signin") ||
    location.pathname.includes("/signup");

  useEffect(() => {
    if (!shouldNotExecute) {
      getUserCourseById(courseId);
    }
  }, [dispatch, courseId, shouldNotExecute]);

  if (!courseId) return;

  async function getUserCourseById() {
    try {
      const response = await dispatch(getUserCourseByIdAction(courseId));
      const getCourseListSuccess = response?.payload;

      if (getCourseListSuccess) {
        if (getCourseListSuccess.data) {
          if (getCourseListSuccess.data.code === 200) {
            setUserCourse(getCourseListSuccess.data.data);
          } else {
            // error message
            swal({
              title: "Error!",
              text: getCourseListSuccess.data.message,
              icon: "error",
              timer: 6000
            });
          }
        }
      }
    } catch (err) {
      if (err?.response?.data?.code === 401) {
        swal({
          title: "Error!",
          text: err?.response?.data?.err,
          icon: "error",
          timer: 5000
        }).then(() => {
          // dispatch(socketDisconnectAction(socket));
          dispatch(logoutUserAction());
        });
      }
    }
  }

  return {
    isCourseCompleted
  };
};

export default useCourseCompleteStatus;
