import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux'
import './index.css';
import App from './App';
import store from './Redux/Store/Store'
import reportWebVitals from './reportWebVitals';
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap';
import {PersistGate} from "redux-persist/integration/react";
import {persistStore} from "redux-persist";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const persistor = persistStore(store);

ReactDOM.render( 
  <React.StrictMode>
  <Provider store={store}>
  <PersistGate  persistor={persistor}>
    <App />
    </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
