export const setItemLocalStorage = (key, value) => {
  localStorage.setItem(key, value);
  return key;
};
export const getItemLocalStorage = (key) => {
  let token = key ? localStorage.getItem(key) : "";
  return token;
};
export const removeItemLocalStorage = (key) => {
  localStorage.removeItem(key);
};

export const setItemInSessionStorage = (key, value) => {
  sessionStorage.setItem(key, value);
};

export const minuteToHourConvert = (minute) => {
  var num = minute;
  var hours = num / 60;
  var rhours = Math.floor(hours);
  var minutes = (hours - rhours) * 60;
  var rminutes = Math.round(minutes);
  if (rhours < 10) {
    rhours = "0" + rhours;
  }
  if (rminutes < 10) {
    rminutes = "0" + rminutes;
  }
  return rhours + ":" + rminutes + ":" + "00";
};

export const minuteToSecondConvert = (minute) => {
  var num = minute;
  var rnum = Math.floor(num);
  var decimalvalue = num - rnum
  var seconds = rnum * 60 + decimalvalue * 60;
  return seconds;
};

export const secondToMinuteConvert = (second = 0) => {
  var num = second;
  var rnum = Math.floor(num);
  var minutes = rnum / 60;
  return minutes;
};

export const secondToMinuteOrHourConvert = (second) => {
  second = Number(second);
  var h = Math.floor(second / 3600);
  var m = Math.floor((second % 3600) / 60);
  var s = Math.floor((second % 3600) % 60);
  var hDisplay = h > 0 ? h + (h == 1 ? "" : "") : "00";
  var mDisplay = m > 0 ? m + (m == 1 ? "" : "") : "00";
  var sDisplay = s > 0 ? s + (s == 1 ? "" : "") : "00";

  if (mDisplay < 10 && mDisplay > 0) {
    mDisplay = "0" + mDisplay;
  }
  if (hDisplay < 10 && hDisplay > 0) {
    hDisplay = "0" + hDisplay;
  }
  if (sDisplay < 10 && sDisplay > 0) {
    sDisplay = "0" + sDisplay;
  }

  return hDisplay + ":" + mDisplay + ":" + sDisplay;
};

export const MODULE_GRAPE_JS = "grapejs";
export const MODULE_QUIZ = "quiz";
export const MODULE_TYPING_DNA = "typing_dna";
export const MODULE_SECURITY_QUESTION = "security_questions";

export const DNA_COUNT = "dnaCount";
export const VERIFY_COUNT = 'verifyCount'
export const OTP_VERIFY_COUNT = 'otpVerifyCount'

export const TYPING_DNA_PAYLOAD = {
  typing_pattern:
    "160,168,358,0.3128,2.1969,0.2679,1.3214,112,246,30,148,9,3,3,2,10,1,4,4,6,0,0,2,3,9,12,2,0,7,2,15,3,2,4,0,0,0,33,1,1,3,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0.748,1.0867,1.5325,0.7866,0.598,2.6341,2.1829,0.6531,0.8089,1,1,0.8272,1.3726,0.8176,0.6057,1.5264,1,0.6314,1.0752,1.1617,0.5772,2.0915,2.0274,1,1,1,1.2396,2.3821,1.0285,3.1287,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1.1925,1.1518,1.1458,1.3348,1.2536,0.9911,0.8951,0.8103,0.808,1,1,0.7902,1.75,0.9931,0.8945,1.0312,1,1.2411,0.7768,0.8858,1.0595,1.2054,0.9345,1,1,1,1.1995,0.9018,0.7232,0.6815,1,1,1,1,1,1,1,1,1,1,1,1,1,1,0.8191,1.9404,1.1409,2.815,0.7439,0.6179,0.4756,0.3831,0.561,1,1,0.7602,0.6369,0.7979,1.0972,0.7846,1,0.8891,0.6016,0.8571,1.2683,0.6768,0.6924,1,1,1,1.7302,0.7236,0.5935,1.0366,1,1,1,1,1,1,1,1,1,1,1,1,1,1,0.4969,0.3598,0.8464,0.1115,0.3315,1,1.6201,0.0084,0.7186,1,1,0.0642,1.0092,0.7637,0.1448,1.402,1,0.4128,0.1047,1.2312,0.2691,1.4696,2.3502,1,1,1,1.2614,1,1,2.2163,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1.3809,1.2229,1.2888,0.2833,1.0538,1,1.0356,0.2586,0.3691,1,1,0.1833,2.2808,0.5101,0.3792,0.4167,1,1.8192,0.5667,0.4374,0.6833,1.6,0.1343,1,1,1,0.8628,1,1,0.3154,1,1,1,1,1,1,1,1,1,1,1,1,1,1,0.7656,3.6014,1.4461,0.4291,0.3005,1,0.0573,0.1301,0.1139,1,1,0.0068,0.0544,0.4531,0.9849,0.0541,1,0.4266,0.0203,0.7093,1.0372,0.3277,0.2684,1,1,1,1.3789,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,0,3.1,0,-1,160,0,32,77,17,1,74,-1,41,134,30,12,185,105,12,84,38,1,0,0,1,2,1,916068587,1,1,0,0,0,1,1680,1050,2,1015,99,0,748820898",
  quality: 2,
};

export const getModuleIdOfQuizSequence = (sequence_number, moduleList) => {
  const currentSequence = sequence_number;

  const mouduleForCurrentQuiz = moduleList.find(
    (l) => l.sequence_number === Math.trunc(currentSequence)
  );

  return mouduleForCurrentQuiz;
};
