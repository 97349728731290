import React, { useEffect, useState } from "react";
import "./ModuleSeven.css";
import AOS from "aos";
import "aos/dist/aos.css";
import right_of_way from "../../../../Images/Module9/right_of_way.jpg";
import logo from "../../../../Images/Module9/logo.png";
import share_the_road from "../../../../Images/Module9/share_the_road.png";
import age_of_stu from "../../../../Images/Module9/age_of_stu.jpg";
import passing from "../../../../Images/Module9/passing.jpg";
import average from "../../../../Images/Module9/average.jpg";
import consequences from "../../../../Images/Module9/consequences.png";
import fine from "../../../../Images/Module9/fine.png";
import home from "../../../../Images/Module9/Home.png";
import bike1 from "../../../../Images/Module9/bike1.jpg";
import bike2 from "../../../../Images/Module9/bike2.jpg";
import bike3 from "../../../../Images/Module9/bike3.jpg";
import bike4 from "../../../../Images/Module9/bike4.jpg";
import bike5 from "../../../../Images/Module9/bike5.jpg";
import bike6 from "../../../../Images/Module9/bike6.jpg";
import bike7 from "../../../../Images/Module9/bike7.jpg";
import bike8 from "../../../../Images/Module9/bike8.jpg";
import bike9 from "../../../../Images/Module9/bike9.jpg";
import bike10 from "../../../../Images/Module9/bike10.jpg";
import bike11 from "../../../../Images/Module9/bike11.jpg";
import bike12 from "../../../../Images/Module9/bike12.jpg";
import bike_racing from "../../../../Images/Module9/bike_racing.png";
import bus from "../../../../Images/Module9/bus.png";
import sch_bus from "../../../../Images/Module9/sch_bus.png";
import bus1 from "../../../../Images/Module9/bus1.png";
import Bus_safe from "../../../../Images/Module9/m92.png";
import Train from "../../../../Images/Module9/m929.png";
import Rightway from "../../../../Images/Module9/rightway.mp4";
import Newbiker from "../../../../Images/Module9/new_biker.mp4";
import Highway from "../../../../Images/Module9/highway.jpg";

import { H5PEditorUI, H5PPlayerUI } from "@lumieducation/h5p-react";
import { ContentService } from "../services/ContentService";

import imagePedestriansAndBicyclistsDifferent from "../../../../Images/Module9/pedestrians-and-bicyclists-are-different-than-other-road-users.png";
import imageVulnerableRoadUsers from "../../../../Images/Module9/pedestrians-and-bicyclists-are-the-most-vulnerable-roadway-users.png";
import imageVisibility from "../../../../Images/Module9/visibility.png";
import imagePedestrianFrequentPlaces from "../../../../Images/Module9/places-that-pedestrians-frequent.png";
import imageUncommonPedestrianPlaces from "../../../../Images/Module9/uncommon-places-that-pedestrians-may-be.png";
import imageSharingRoadWithBicyclists from "../../../../Images/Module9/sharing-the-road-with-bicyclists.png";


import "./ModuleNine.css";
import { Container, Row, Col, Card, Accordion } from "react-bootstrap";

export const ModuleNinePart2 = () => {
  const [scroll, setScroll] = useState(false);

  const contentService = new ContentService("/h5p");
  const h5pPlayer = React.useRef(H5PPlayerUI);

  const [loading, setLoading] = useState(true);

  const onPlayerInitialized = () => {
    setLoading(false);
  };

  useEffect(() => {
    AOS.init();
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  useEffect(() => {
    sessionStorage.removeItem('HAS_H5P_ACTIVITY');
    sessionStorage.removeItem('HAS_H5P_ACTIVITY_SUBMIT');
  }, []);

  useEffect(() => {
    sessionStorage.setItem("HAS_VIDEO", true);
  }, []);

  var video = document.getElementById("video");
  useEffect(() => {
    if (video) {
      var supposedCurrentTime = 0;
      video.addEventListener("timeupdate", function () {
        if (!video.seeking) {
          supposedCurrentTime = video.currentTime;
        }
      });
      // prevent user from seeking
      video.addEventListener("seeking", function () {
        // guard agains infinite recursion:
        // user seeks, seeking is fired, currentTime is modified, seeking is fired, current time is modified, ....
        var delta = video.currentTime - supposedCurrentTime;
        if (Math.abs(delta) > 0.01) {
          console.log("Seeking is disabled");
          video.currentTime = supposedCurrentTime;
        }
      });
      // delete the following event handler if rewind is not required
      video.addEventListener("ended", function () {
        // reset state in order to allow for rewind
        supposedCurrentTime = 0;
      });
    }
  }, [video]);

  const [activeId, setActiveId] = useState("");

  const toggleActive = (id) => {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  };

  return (
    <div>
      <div className="module9_course same_width_module mt-md-4 mb-md-4">
        <Container>
          <div className="module9_page1">
          <div className="higway_bg">
              <Container>
                <Row>
                  <Col lg={5} md={7} xs={12}>
                    <h2 data-aos="slide-right" data-aos-duration="3000">
                      RECENT LAWS AND HIGHWAY SAFETY CONCERNS
                    </h2>
                    <h3 data-aos="fade-up" data-aos-duration="3000">
                      Module 9 - Part 2
                    </h3>
                  </Col>

                </Row>
              </Container>
            </div>

            <div className="moto_safe_aw some_pad">
              <Container>
                <h2 data-aos="slide-right" data-aos-duration="2000">
                  MOTORCYCLE SAFETY AWARENESS
                </h2>
                <p>
                  Drivers today share the road with a lot of other motorists –
                  not all of which are driving cars or trucks. Motorcycles are
                  common on New York roads. If there is a crash involving a
                  motorist and a motorcyclist, the motorcyclist has a much
                  higher chance of being seriously hurt or killed than the
                  driver of a car. Motorcycles do not oﬀer the rider the
                  protections that you have with other types of vehicles. For
                  example, motorcycles don’t protect the rider with a frame
                  around the rider.
                </p>
                <p>
                  Motorcycles, (which includes scooters and mopeds), come in a
                  variety of shapes and sizes. New York State Law deﬁnes a
                  motorcycle as “Every motor vehicle having a seat or saddle for
                  the use of the rider and designed to travel on not more than
                  three wheels in contact with the ground, but excluding a
                  tractor.” Most motorcycles you’ll see have either two or three
                  wheels. Examples include (but are not limited to):
                </p>

                <Row>
                  <Col lg={3} md={4} xs={6}>
                    <img
                      src={bike1}
                      alt=""
                      data-aos="zoom-in"
                      data-aos-duration="1000"
                    />
                  </Col>

                  <Col lg={3} md={4} xs={6}>
                    <img
                      src={bike2}
                      alt=""
                      data-aos="zoom-in"
                      data-aos-duration="2000"
                    />
                  </Col>

                  <Col lg={3} md={4} xs={12}>
                    <img
                      src={bike3}
                      alt=""
                      data-aos="zoom-in"
                      data-aos-duration="3000"
                    />
                  </Col>

                  <Col lg={3} md={4} xs={6}>
                    <img
                      src={bike4}
                      alt=""
                      data-aos="zoom-in"
                      data-aos-duration="1000"
                    />
                  </Col>

                  <Col lg={3} md={4} xs={6}>
                    <img
                      src={bike5}
                      alt=""
                      data-aos="zoom-in"
                      data-aos-duration="2000"
                    />
                  </Col>

                  <Col lg={3} md={4} xs={12}>
                    <img
                      src={bike6}
                      alt=""
                      data-aos="zoom-in"
                      data-aos-duration="3000"
                    />
                  </Col>

                  <Col lg={3} md={4} xs={6}>
                    <img
                      src={bike7}
                      alt=""
                      data-aos="zoom-in"
                      data-aos-duration="1000"
                    />
                  </Col>

                  <Col lg={3} md={4} xs={6}>
                    <img
                      src={bike8}
                      alt=""
                      data-aos="zoom-in"
                      data-aos-duration="2000"
                    />
                  </Col>

                  <Col lg={3} md={4} xs={12}>
                    <img
                      src={bike9}
                      alt=""
                      data-aos="zoom-in"
                      data-aos-duration="3000"
                    />
                  </Col>

                  <Col lg={3} md={4} xs={6}>
                    <img
                      src={bike10}
                      alt=""
                      data-aos="zoom-in"
                      data-aos-duration="1000"
                    />
                  </Col>

                  <Col lg={3} md={4} xs={6}>
                    <img
                      src={bike11}
                      alt=""
                      data-aos="zoom-in"
                      data-aos-duration="2000"
                    />
                  </Col>

                  <Col lg={3} md={4} xs={12}>
                    <img
                      src={bike12}
                      alt=""
                      data-aos="zoom-in"
                      data-aos-duration="3000"
                    />
                  </Col>
                </Row>
                <p>
                  The following video will discuss Motorcycle Safety Awareness
                  in more detail. It will explain the factors that make
                  motorcycles harder to see, how to adjust how you drive around
                  motorcycles, and how to increase your awareness around
                  motorcycles. Click the Play button to start the video.
                </p>
                <video
                  id="video"
                  width="100%"
                  height="400"
                  autoplay={true}
                  onEnded={() => {
                    sessionStorage.setItem("VIDEO_PLAYED", "COMPLETED");
                  }}
                  controls
                  controlsList="nodownload noplaybackrate"
                >
                  <source src={Newbiker} type="video/mp4" />
                </video>
                <h2>Understanding Motorcycle Visibility and Road Safety Challenges:</h2>
                <h6 className="mt-5">
                  <ul className="mb-5">
                    <li data-aos="slide-left" data-aos-duration="2000">
                      <b>Motorcycle size and visibility</b>
                      <ul>
                        <li>
                          Motorcycles are smaller than cars, making them harder
                          to see on the road.
                        </li>
                        <li>
                          It’s tough to judge how far away a motorcycle is and
                          how fast it’s going when it’s coming toward you or
                          moving away.
                        </li>
                      </ul>
                    </li>

                    <li data-aos="slide-left" data-aos-duration="2000">
                      <b>Seasonal use and awareness</b>
                      <ul>
                        <li>
                          Motorcycles are mostly used in Spring, Summer, and
                          Fall, so drivers may forget to look out for them
                          during colder months.
                        </li>
                        <li>
                          This makes it harder for drivers to notice motorcycles
                          when they’re not expecting them.
                        </li>
                      </ul>
                    </li>

                    <li data-aos="slide-left" data-aos-duration="2000">
                      <b>Differences in lighting</b>
                      <ul>
                        <li>
                          Motorcycle headlights must be on at all times, but
                          they can still be harder to spot than car lights.
                        </li>
                        <li>
                          Unlike cars, motorcycles don’t have a center
                          high-mounted brake light, and their turn signals can
                          be less noticeable.
                        </li>
                      </ul>
                    </li>

                    <li data-aos="slide-left" data-aos-duration="2000">
                      <b>Visibility challenges</b>
                      <ul>
                        <li>
                          Not all motorcyclists wear bright or reflective
                          clothing, which can make them harder to see.
                        </li>
                        <li>
                          Motorcycles can slow down without using the brake
                          lights, which might surprise other drivers.
                        </li>
                      </ul>
                    </li>

                    <li data-aos="slide-left" data-aos-duration="2000">
                      <b>Perceived speed</b>
                      <ul>
                        <li>
                          Because motorcycles are small, they can appear to be
                          moving faster than they really are.
                        </li>
                        <li>
                          They can also accelerate and brake quickly, which adds
                          to the challenge of judging their speed.
                        </li>
                      </ul>
                    </li>

                    <li data-aos="slide-left" data-aos-duration="2000">
                      <b>Predictability and Behavior</b>
                      <ul>
                        <li>
                          Motorcyclists may be less predictable than cars, so
                          drivers need to be extra careful around them.
                        </li>
                        <li>
                          They may share lanes with cars or ride in staggered
                          formations, making them harder to see.
                        </li>
                      </ul>
                    </li>

                    <li data-aos="slide-left" data-aos-duration="2000">
                      <b>Driver adjustment</b>
                      <ul>
                        <li>
                          Give motorcycles more space on the road, especially in
                          bad weather or when visibility is low.
                        </li>
                        <li>
                          Always check your mirrors and blind spots for
                          motorcycles, and stay focused on the road.
                        </li>
                      </ul>
                    </li>

                    <li data-aos="slide-left" data-aos-duration="2000">
                      <b>Recognizing challenges</b>
                      <ul>
                        <li>
                          It’s important to understand the unique
                          characteristics of motorcycles to keep everyone safe
                          on the road.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </h6>
              </Container>
            </div>

            <div className="be_aware mt-5">
              <Container>
                <h2
                  data-aos="slide-right"
                  data-aos-duration="2000"
                  style={{ fontSize: "32px" }}
                >
                  Pedestrian and bicycle safety
                </h2>
                <h2 data-aos="slide-right" data-aos-duration="2000">
                  Be Aware :
                </h2>
                <h3>
                  Pedestrians and Bicyclists are Different Than Other Road
                  Users:
                </h3>

                <Row>
                  <Col md={6}>
                    <img
                      src={imagePedestriansAndBicyclistsDifferent}
                      alt="Example 8"
                      className="example-scenario-8"
                    />
                  </Col>
                  <Col md={6}>
                    <p>
                      Pedestrians and bicyclists have the right to use roadways
                      just as motorists do. However, unlike motorists, they do
                      not have to get a learner permit or pass tests to qualify
                      and may not be trained in the rules that apply to all
                      roadway users. They may be any age or level of ability.
                      They may be deaf or blind, have mobility issues, or they
                      may be distracted. There are any number of issues that
                      might cause them to react differently than you expect.
                    </p>
                  </Col>
                </Row>

                <p>
                  They can be unpredictable in speed and movement, and they may
                  enter and use the roads in ways you may not expect from
                  between parked cars or behind buses. Especially in the case of
                  very young pedestrians and bicyclists, they will be much
                  smaller than the vehicles on the road and easily hidden in
                  your vehicle’s blind spots.
                </p>
                <p>
                  For these reasons, motorists must take special care to be
                  aware and to drive as safely as possible to protect all road
                  users.
                </p>

                <h3>
                  Pedestrians and Bicyclists are the Most Vulnerable Roadway
                  Users:
                </h3>
                <p>
                  Pedestrians and bicyclists do not have the protection that
                  comes with being inside a vehicle. They do not have a metal
                  frame or seat belts to protect them during a collision.
                  Because of this, they make up a disproportionate number of the
                  fatalities and major injuries that result from traffic
                  collisions.
                </p>
                <Row>
                  <Col md={6}>
                    <p>
                      According to information from the U.S. Department of
                      Transportation, pedestrian and bicyclist fatalities make
                      up about 20% of all traffic fatalities with approximately
                      7,000 pedestrian deaths and 900 bicyclist deaths
                      nationally. Another 76,000 pedestrians and 47,000
                      bicyclists are injured in roadway crashes annually. In New
                      York State, injuries and deaths vary by community, but in
                      NYC, for example, nearly 50% of all traffic fatalities
                      involve pedestrians and bicyclists.
                    </p>
                  </Col>
                  <Col md={6}>
                    <img
                      src={imageVulnerableRoadUsers}
                      alt="Example 8"
                      className="example-scenario-8"
                    />
                  </Col>
                </Row>
                <p>
                  In addition, certain age groups, particularly children and the
                  elderly are overrepresented as victims in these crashes and
                  injuries. For example, 40% of pedestrian fatalities in NYC are
                  over the age of 60.
                </p>

                <h2
                  className="mt-5"
                  data-aos="slide-right"
                  data-aos-duration="2000"
                >
                  Be Alert :
                </h2>
                <h3>
                  What can you do to be safe around pedestrians or bicyclists?:
                </h3>
                <p>
                  Motor vehicle operators are responsible for safe and lawful
                  operation of the vehicles they drive.
                </p>
                <p>
                  It is important for you, as a driver, to do everything
                  possible to respect the safety of pedestrians and bicyclists.
                  You as the driver are responsible to exercise due care by
                  being aware, alert, and driving defensively.
                </p>

                <h3>Visibility:</h3>
                <Row>
                  <Col md={6}>
                    <img
                      src={imageVisibility}
                      alt="Example 8"
                      className="example-scenario-8"
                    />
                  </Col>
                  <Col md={6}>
                    <p>
                      Drivers are conditioned primarily to pay attention to
                      other cars. Seeing pedestrians and bicyclists on roadways
                      is less common. Your brain may not “see” pedestrians or
                      bicyclists to the same degree that it sees other vehicles
                      on the road because you do not expect to see them. The
                      solution is to actively look for bicyclists and
                      pedestrians – as well as other vehicles – when you drive.
                    </p>
                  </Col>
                </Row>
                <p>
                  Pedestrians and bicyclists may be less visible than cars for
                  many reasons:
                </p>
                <Row>
                  <Col>
                    <ul className="mb-5">
                      <li data-aos="slide-left" data-aos-duration="2000">
                        Pedestrians and bicyclists are much smaller than cars.
                        They can easily disappear into blind spots around your
                        vehicle or behind other vehicles or obstacles.
                      </li>
                      <li data-aos="slide-left" data-aos-duration="2000">
                        They may not have lights or be wearing brightly colored
                        clothing. They may be especially hard to see at night or
                        in inclement weather.
                      </li>
                      <li data-aos="slide-left" data-aos-duration="2000">
                        They may not follow the rules of the road. They may be
                        in locations you would not normally look and move in
                        ways that you may not expect.
                      </li>
                      <li data-aos="slide-left" data-aos-duration="2000">
                        They move much more slowly than vehicles.
                      </li>
                    </ul>
                  </Col>
                </Row>

                <h3>Places that Pedestrians Frequent:</h3>
                <p>
                  The following are some of the most common places that you will
                  see pedestrians interacting with roadways:
                </p>
                <Row>
                  <Col md={6}>
                    <ul className="mb-5">
                      <li data-aos="slide-left" data-aos-duration="2000">
                        Schools - Pedestrians and bicyclists are very common
                        around schools. Expect children around schools – even
                        when school is not in session.
                      </li>
                      <li data-aos="slide-left" data-aos-duration="2000">
                        Playgrounds and parks - But often children may run out
                        into the street to catch or retrieve a ball or toy. They
                        might run out between parked vehicles. A careful driver
                        will be especially alert around schools and playgrounds.
                      </li>
                      <li data-aos="slide-left" data-aos-duration="2000">
                        Residential areas
                      </li>
                      <li data-aos="slide-left" data-aos-duration="2000">
                        Retail areas such as outside (strip) malls, restaurants
                      </li>
                      <li data-aos="slide-left" data-aos-duration="2000">
                        Mass transit stops
                      </li>
                      <li data-aos="slide-left" data-aos-duration="2000">
                        On common roadways
                      </li>
                    </ul>
                  </Col>
                  <Col>
                    <img
                      src={imagePedestrianFrequentPlaces}
                      alt="Example 8"
                      className="example-scenario-8"
                    />
                  </Col>
                </Row>

                <h3>Intersections:</h3>
                <p>
                  Intersections are especially dangerous. Crosswalks are
                  designed to give pedestrians a safe place to cross roads and
                  give drivers an idea of where to expect them. However, not all
                  intersections are designed with crosswalks and not all
                  crosswalks have signs or pavement markings. The law (statewide
                  and NYC) requires that drivers must come to a complete stop
                  and allow pedestrians to cross at both marked and unmarked
                  crosswalks.
                </p>

                <h3>Uncommon Places that Pedestrians May Be:</h3>
                <Row>
                  <Col md={6}>
                    <img
                      src={imageUncommonPedestrianPlaces}
                      alt="Example 8"
                      className="example-scenario-8"
                    />
                  </Col>
                  <Col md={6}>
                    <p>
                      Bicyclists and pedestrians can be anywhere. You must
                      actively use all your senses and your knowledge to detect
                      these vulnerable road users.
                    </p>
                    <p>
                      Be aware when leaving or backing out of driveways,
                      alleyways, private roads, regardless of whether they are
                      residential or commercial properties, pedestrians have the
                      right of way crossing those driveways when using the
                      sidewalk that intersects them.
                    </p>
                  </Col>
                </Row>
                <p>
                  Pedestrians are supposed to walk on sidewalks, but not all
                  roads have sidewalks. On roads where there is no shoulder or a
                  narrow shoulder or no sidewalks, pedestrians have no choice
                  but to walk along the road and they have the legal right to do
                  so. Pedestrians walking along the roadside should walk facing
                  traffic, but not all pedestrians know this. So as a driver,
                  you must be alert and expect the unexpected.
                </p>
                <p>
                  Generally, bicyclists and pedestrians are prohibited from
                  limited access highways. But that does not mean you don’t have
                  to be alert there. For example, you might encounter someone
                  whose vehicle is disabled, and they are walking alongside the
                  highway to get help.
                </p>

                <h3>Cyclists have the right to operate along the roadside:</h3>
                <p>
                  Bicycle paths and bicycle lanes are designed to provide
                  bicyclists with a safe place to ride. Motorists are not
                  permitted to drive in or block the bicycle lanes. There are no
                  exceptions – you cannot drive in these lanes, and there is No
                  stopping, No standing, No parking – not even for a minute.
                </p>
                <p>
                  Cyclists also have the right to operate along the roadside,
                  even when no bicycle lane markings are present. They should be
                  riding in the direction of traffic and observing all traffic
                  rules but expect that some cyclists may not be aware of this.
                  Be alert, give them the space they are entitled.
                </p>

                <h2
                  className="mt-5"
                  data-aos="slide-right"
                  data-aos-duration="2000"
                >
                  Drive Defensively :
                </h2>
                <p>
                  A good driver will anticipate situations that could be
                  dangerous – not just to themselves, but to other roadway
                  users. Remember, bicyclists and pedestrians are highway users,
                  too! It is important to acknowledge that driving can be
                  difficult, but by following the laws meant for drivers, you
                  can reduce your risk of being in a crash and hurting yourself
                  and others.
                </p>
                <p>
                  While pedestrians and cyclists may not always be doing the
                  right thing, in a collision with a motor vehicle they are
                  always going to be the ones who lose, so it is important for
                  you, the driver, to do everything you can to prevent a crash,
                  and its consequences, regardless of who may be in the wrong.
                </p>
                <h3>Sharing the Road with Bicyclists:</h3>
                <Row>
                  <Col md={6}>
                  <p>
                  Passing bicyclists can be very dangerous. Bicycles sometimes
                  must swerve to avoid obstacles (a pothole, debris, a car
                  opening a door, etc.). If you are too close, you might collide
                  with the bicyclist. Bicyclists are also susceptible to wind.
                  Crosswinds may cause a cyclist to swerve into the lane of
                  traffic unexpectedly. Also, when a vehicle drives past a
                  cyclist, the shape of the passing vehicle may create a wake
                  that draws the cyclist from their intended path. This could
                  cause the cyclist to suddenly swerve into the traffic lane.
                </p>
                  </Col>
                  <Col md={6}>
                  <img
                    src={imageSharingRoadWithBicyclists}
                    alt="Example 8"
                    className="example-scenario-8"
                   />
                  </Col>
                </Row>
                <p>
                  When you approach a pedestrian or cyclist, don’t assume they
                  are aware of you. If you are approaching from behind, they may
                  not hear you. Whenever possible, slow down and give them some
                  extra room.
                </p>

                <h3>Parking Safely:</h3>
                <p>
                  Driving defensively applies not just when you are driving, but
                  also when you are parked. When parked or getting ready to pull
                  out, be aware as bicyclists may come within 3-5 feet of your
                  door. One technique that can help with this is to Reach,
                  Swivel, and Look:
                </p>
                <Row>
                  <Col>
                    <ul className="mb-5">
                      <li data-aos="slide-left" data-aos-duration="2000">
                        <span>REACH: </span>
                        Use your right hand (not the left hand) to reach for the
                        handle to open the driver’s door.
                      </li>
                      <li data-aos="slide-left" data-aos-duration="2000">
                        <span>SWIVEL: </span>
                        By reaching for the driver’s door handle with your right
                        hand, this will cause your body to turn to the left, and
                        help you look for a bicycle that may be overtaking you.
                      </li>
                      <li data-aos="slide-left" data-aos-duration="2000">
                        <span>LOOK: </span>
                        Look alongside and to the rear of your car to search for
                        hazards.
                      </li>
                    </ul>
                  </Col>
                </Row>
                <p>
                  This method will help you avoid opening your driver’s door
                  into the path of a bicyclist or other traffic.
                </p>
              </Container>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};
