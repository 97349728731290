import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { MyProfile } from "./MyProfile";
import { AccountSetting } from "./AccountSetting";
import { PaymentHistory } from "./PaymentHistory";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Button from "react-bootstrap/Button";
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";
import {
  getReceiptAction,
  socketDisconnectAction,
  logoutUserAction,
} from "../../../Redux/Actions";

import "./Profile.css";

const Profile = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const dispatch = useDispatch();
  let params = useParams();
  const history = useHistory();
  const socket = useSelector((state) => {
    return state.userSocketioReducer?.socketConnectSuccess?.socketInstance;
  });
  const user = useSelector((state) => {
    return state.userAuthReducer?.userPersonalData?.data?.data;
  });
  const userId = sessionStorage.getItem("userId");

  const receiptDownload = async () => {
    try {
      let response = await dispatch(
        getReceiptAction(params.course_id, userId || user?.id)
      );
      response = response?.payload;
      if (response) {
        if (response?.data) {
          if (response?.data?.code == 200) {
            window.open(response?.data?.data?.url);
          } else {
            // error message
            swal({
              title: "Error!",
              text: response.data.message,
              icon: "error",
              timer: 6000,
            });
          }
        }
      }
    } catch (err) {
      if (err?.response?.data?.code === 401) {
        swal({
          title: "Error!",
          text: err.response.data.err,
          icon: "error",
          timer: 5000,
        });
        dispatch(socketDisconnectAction(socket));
        dispatch(logoutUserAction());
        history.push(`/user/signin/${params.course_id}`);
      }
    }
  };
  return (
    <div>
      <div className="breadcrumbBg">
        <h1 className="mb-0 mt-2">My Profile</h1>
      </div>
      <Button
        variant="outline-primary"
        onClick={receiptDownload}
        style={{ float: "right", margin: "4rem 3rem 2rem 0" }}
      >
        Download Receipt
      </Button>

      <div className="mainSection pb-4" style={{ marginTop: "5rem" }}>
        <div className="allCourses profileSection">
          <Container>
            <Row style={{ width: "100%" }}>
              <Col xl={12} lg={12} md={12} sm={12}>
                <Tabs
                  defaultactivekfey="profile"
                  transition={false}
                  id="uncontrolled-tab-example1"
                >
                  <Tab eventKey="My_Profile" title="My Profile">
                    <MyProfile />
                  </Tab>

                  <Tab eventKey="Account_Setting" title="Account Setting">
                    <AccountSetting />
                  </Tab>

                  {/* <Tab eventKey="Payment_History" title="Payment Receipt">
                    <PaymentHistory />
                  </Tab> */}
                </Tabs>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default Profile;
