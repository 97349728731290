import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Logo from "../../../../Images/logo.png";
import Purpose from "../../../../Images/module10/purpose.png";
import PurposeBg from "../../../../Images/module10/purposebg.png";
import Environment from "../../../../Images/module10/part1.png";
import Vehicle from "../../../../Images/module10/part1.png";
import Driver from "../../../../Images/module10/part1.png";
import Rules from "../../../../Images/module10/rules.png";
import Law from "../../../../Images/module10/law.png";
import Importance from "../../../../Images/module10/importance.png";
import ImportanceCar from "../../../../Images/module10/importance_car.png";
import Vision from "../../../../Images/module10/vision.jpg";
import VisionDegree from "../../../../Images/module10/vision_degree.png";
import Danger from "../../../../Images/module10/danger.png";
import DrivingSchool from "../../../../Images/module10/driving_school.png";
import dmv_reporting from "../../../../Images/module10/dmv_reporting.png";

import AOS from "aos";
import "aos/dist/aos.css";

import "./ModuleTen.css";

export const ModuleTen = () => {
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    AOS.init();
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  useEffect(() => {
    sessionStorage.removeItem('HAS_H5P_ACTIVITY');
    sessionStorage.removeItem('HAS_H5P_ACTIVITY_SUBMIT');
  }, []);

  return (
    <div className="module10_course module_font same_width_module mb-md-4">
      <Container>
        <div className="course_recap_main">
          <div className="course_purpose mt-3 mb-5">
            <div className="course_content_div">
              <Container>
                <h1
                  className="text-center"
                  data-aos="zoom-in-right"
                  data-aos-duration="2000"
                >
                  COURSE RE-CAP
                </h1>

                <p className="text-center module_number mb-md-5">Module 10</p>

                <Row>
                  <Col lg={7} md={12} xs={12}>
                    <div className="license_purpose">
                      <h2 className="purpose_head mt-lg-5">
                        Purpose of pre-licensing course:
                      </h2>

                      <p className="mt-3">
                        To help a new driver become aware of a driver’s
                        responsibilities, understand the rules of the road,
                        incorporate safety concepts and techniques, recognize
                        potential risks, and learn about current laws and
                        regulations.
                      </p>
                    </div>
                  </Col>

                  <Col lg={5} md={12} xs={12}>
                    <div
                      className="purpose_license_img"
                      data-aos="zoom-in-left"
                      data-aos-duration="2000"
                    >
                      <img src={Purpose} alt="Image" />
                    </div>
                  </Col>
                </Row>

                <div className="mission_div mt-md-5">
                  <h2 className="purpose_head">Mission:</h2>
                  <p>To promote driving that is:</p>

                  <Row>
                    <Col md={3} xs={12}>
                      <div
                        className="mission_box"
                        data-aos="zoom-in-right"
                        data-aos-duration="2000"
                      >
                        <p> Safe</p>
                      </div>
                    </Col>

                    <Col md={3} xs={12}>
                      <div
                        className="mission_box"
                        data-aos="zoom-in-right"
                        data-aos-duration="2000"
                      >
                        <p>Courteous</p>
                      </div>
                    </Col>

                    <Col md={3} xs={12}>
                      <div
                        className="mission_box"
                        data-aos="zoom-in-right"
                        data-aos-duration="2000"
                      >
                        <p>Defensive</p>
                      </div>
                    </Col>

                    <Col md={3} xs={12}>
                      <div
                        className="mission_box"
                        data-aos="zoom-in-right"
                        data-aos-duration="2000"
                      >
                        <p>
                          Alcohol/drug <br /> free{" "}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>

                <h2 className="purpose_head mt-4">Goals:</h2>

                <ul className="p-0">
                  <li>
                    Reduce injuries, crashes, traffic violations, and property
                    damage.
                  </li>

                  <li>Become responsible, courteous drivers .</li>

                  <li>
                    Learn about the dangers of fatigue, distraction, alcohol and
                    other drugs on driving ability.
                  </li>

                  <li>
                    Develop a positive attitude towards safe and defensive
                    driving.
                  </li>

                  <li>
                    Encourage and promote safety belt and child safety seat use.
                  </li>

                  <li>
                    Learn how to fit safely into the existing highway
                    transportation system, with other highway users.
                  </li>

                  <li>Familiarize students with the “rules of the road.”</li>

                  <li>
                    Teach new drivers to show courtesy to others when they are
                    driving.
                  </li>
                </ul>
              </Container>
            </div>

            <div
              className="purpose_bg_img"
              data-aos="slide-right"
              data-aos-duration="2000"
            ></div>
          </div>

          <div className="transport_part">
            <Container>
              <p className="mb-5">
                You learned about the parts of the highway transportation system
                (HTS), and which parts are controllable and uncontrollable.
              </p>

              <Row>
                <Col md={4} xs={12}>
                  <div
                    className="part_box text-center"
                    data-aos="fade-down"
                    data-aos-duration="2000"
                  >
                    <img src={Environment} alt="Image" />
                    <div className="part_box_content">
                      <h4>THE ENVIRONMENT</h4>

                      <p>
                        Composed of the road, the weather, other motor vehicles,
                        bicycles, pedestrians, surrounding obstacles
                      </p>
                    </div>
                  </div>
                </Col>

                <Col md={4} xs={12}>
                  <div
                    className="part_box text-center"
                    data-aos="fade-down"
                    data-aos-duration="2000"
                  >
                    <img src={Vehicle} alt="Image" />
                    <div className="part_box_content">
                      <h4>VEHICLES</h4>

                      <p>Differ by type, age and condition.</p>
                    </div>
                  </div>
                </Col>

                <Col md={4} xs={12}>
                  <div
                    className="part_box text-center"
                    data-aos="fade-down"
                    data-aos-duration="2000"
                  >
                    <img src={Driver} alt="Image" />
                    <div className="part_box_content">
                      <h4>DRIVERS</h4>

                      <p>
                        They vary in many ways, such as age, sex and fitness
                        levels.
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>

          <div className="rules_road pt-md-5 pb-md-5">
            <Container>
              <Row>
                <Col md={8} xs={12}>
                  <div className="right_rules mt-5">
                    <p>
                      You reviewed the rules of the road, as well as skills and
                      maneuvers that will aid you in your operating of a
                      vehicle, including:
                    </p>

                    <span>Right way of rules :</span>

                    <ul className="p-0 mt-3">
                      <li data-aos="slide-right" data-aos-duration="1000">
                        In intersections, expressways, one way streets.
                      </li>

                      <li data-aos="slide-right" data-aos-duration="2000">
                        In work zones.
                      </li>

                      <li data-aos="slide-right" data-aos-duration="3000">
                        Following signs, lights, pavement markings.
                      </li>
                    </ul>

                    <div className="border_rule"></div>

                    <p className="mt-4">
                      We discussed important driving habits, skills, and rules,
                      regarding:
                    </p>

                    <ul className="p-0 mt-3 mb-md-5">
                      <li data-aos="slide-right" data-aos-duration="1000">
                        Following distance.
                      </li>

                      <li data-aos="slide-right" data-aos-duration="2000">
                        Backing up, passing rules.
                      </li>

                      <li data-aos="slide-right" data-aos-duration="3000">
                        Space cushion system.
                      </li>
                    </ul>
                  </div>
                </Col>

                <Col md={4} xs={12}>
                  <div
                    className="law_man"
                    data-aos="fade-left"
                    data-aos-duration="2000"
                  >
                    <img src={Law} alt="Image" />
                  </div>
                </Col>
              </Row>
            </Container>
            <div
              className="rules_img mt-md-3"
              data-aos="zoom-in-right"
              data-aos-duration="2000"
            >
              <img src={Rules} alt="Image" />
            </div>
          </div>

          <div className="importance_driver">
            <Container>
              <p data-aos="fade-up" data-aos-duration="3000">
                You understood the importance of being a courteous driver. You
                have learned how to fit safely into the existing highway
                transportation system, using the following considerations and
                strategies:
              </p>

              <ul className="p-0">
                <li>
                  Driving is considered to be a social activity, in which
                  certain written and unwritten rules apply.
                </li>

                <li>Driving with other highway users and large vehicles.</li>

                <li>Arch of driver safety.</li>
              </ul>

              <div
                className="skill_set mb-5"
                data-aos="zoom-in-right"
                data-aos-duration="2000"
              >
                <img src={Importance} alt="Image" />

                <img src={ImportanceCar} className="car_man" alt="Image" />
              </div>

              <ul className="p-0">
                <li>Methods to follow to avoid collisions.</li>

                <li>The importance of knowing and checking blind spots.</li>
              </ul>

              <div
                className="vision_img mt-5"
                data-aos="zoom-in"
                data-aos-duration="2000"
              >
                <img src={VisionDegree} alt="Image" />
                <img
                  src={Vision}
                  alt="Image"
                  data-aos="zoom-in"
                  data-aos-duration="2000"
                />
              </div>

              <ul className="p-0">
                <li>
                  S.E.E. strategy:
                  <Row>
                    <Col md={4} xs={12}>
                      <p className="strategy_action blue_bgcolor">
                        S<sub>EArch</sub>
                      </p>
                    </Col>

                    <Col md={4} xs={12}>
                      <p className="strategy_action dark_bgcolor">
                        e<sub>VALUATE</sub>
                      </p>
                    </Col>

                    <Col md={4} xs={12}>
                      <p className="strategy_action blue_bgcolor">
                        e<sub>XECUTE</sub>
                      </p>
                    </Col>
                  </Row>
                </li>

                <li>The dangers of hydroplaning.</li>

                <li>How to prevent and react to a skid.</li>
              </ul>
            </Container>
          </div>

          <div className="attitude_driving">
            <Container>
              <p className="attitude_para">
                You learned about how feelings and attitudes can affect our
                driving, in situations such as:
              </p>

              <ul className="p-0">
                <li data-aos="slide-right" data-aos-duration="1000">
                  The difference between making calculated and impulsive risks.
                </li>

                <li data-aos="slide-right" data-aos-duration="3000">
                  The dangers of road rage and how to reduce stress while
                  driving.
                </li>
              </ul>

              <div className="white_border"></div>

              <div className="attitude_div">
                <Row>
                  <Col md={4} xs={12}>
                    <h5 className="mt-5 mb-5">
                    You learned the dangerous effects fatigue, drowsiness, 
                    drugs and alcohol have on driving.
                    </h5>
                  </Col>

                  <div className="danger_img">
                    <img
                      src={Danger}
                      alt="Image"
                      data-aos="zoom-in"
                      data-aos-duration="2000"
                    />
                  </div>
                </Row>
              </div>

              <div className="white_border"></div>

              <div className="">
                <p className="attitude_para pt-md-5">
                  You became more familiar with the rules, regulations, and
                  penalties for:
                </p>

                <Row>
                  <Col md={6} xs={12}>
                    <ul
                      className="p-0"
                      data-aos="slide-right"
                      data-aos-duration="2000"
                    >
                      <li>Road rage.</li>

                      <li>Drugs and alcohol laws.</li>

                      <li>
                        Driving around school buses, motorcycles, and railroads.
                      </li>
                    </ul>
                  </Col>

                  <Col md={6} xs={12}>
                    <ul
                      className="p-0"
                      data-aos="slide-left"
                      data-aos-duration="2000"
                    >
                      <li>Junior drivers.</li>

                      <li>Using electronic devices.</li>

                      <li>
                        Using safety measures, such as belts and child seats.
                      </li>
                    </ul>
                  </Col>
                </Row>
              </div>

              <div className="complete_course text-center">
                <h4 data-aos="zoom-in" data-aos-duration="3000">
                  AFTER COMPLETING THIS COURSE, <br />
                  YOU WILL KNOW THE IMPORTANCE OF MAKING WISE <br />
                  DECISIONS WHEN IT COMES TO EVERY ASPECT OF SAFE DRIVING.
                </h4>
              </div>
            </Container>
          </div>

          <div className="dmv_reporting">
            <h2 data-aos="slide-right" data-aos-duration="1000">
              DMV Reporting:
            </h2>
            <Row>
              <Col md={6} xs={12}>
                <img
                  src={dmv_reporting}
                  alt=""
                  data-aos="zoom-in"
                  data-aos-duration="3000"
                />
              </Col>

              <Col md={6} xs={12}>
                <p>
                  While graduates of the classroom-based pre-licensing course
                  receive a Pre-Licensing Course Certificate (MV-278), new
                  drivers who complete an Online Pre-Licensing (OPL) course with
                  Online Traffic Safety do not need to provide a paper
                  certificate to the NY DMV. Your course completion status will
                  be automatically reported to the NY DMV.
                </p>
                <p>
                  Online Traffic Safety provides an email confirmation when you
                  complete the course and notifies the NY DMV of your course
                  completion. After you pass the course, it may take up to 24 to
                  48 hours for your completion status to be posted to your
                  driver record. When you arrive to take your road test, NY DMV
                  records will show that you successfully completed the 5-hour
                  course.
                </p>
              </Col>
            </Row>

            <hr />
          </div>

          <div className="next_step">
            <h2>Next Steps After Completing the 5-Hour Course:</h2>
            {/* <p>
              Once you pass the New York 5-Hour Online Pre-Licensing Course, you
              can make an appointment with the NY DMV to take the road test for
              your driver license.
            </p> */}
            {/* <p>Before you take your road test, you need to:</p> */}
            <ul>
              <li>Schedule your Road test</li>
              <li>Prepare for your Road Test</li>
              <li>Take your Road Test</li>
            </ul>
            <p>
              Since Online Traffic Safety automatically reports your course
              completion to the NY DMV, you do not need to bring a 5-hour course
              certificate to your road test.
            </p>
          </div>

          <div className="certificate_report">
            <div
              className="driving_img"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              <img src={DrivingSchool} alt="Image" />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
