import {
  QUIZ_ATTEMPTS,
  QUIZ_QUESTION_ANSWER_VERIFY_SCCUESS
   } from '../../Actions/ActionTypes/UserQuizQuestionTypes'
   
   const iState = {

     userVerifyQuizAnswerSuccess: {},
     quizAttempts: '', 
   };
   
   export const userQuizQuestionRecucer = (state = iState, action) => {
     switch (action.type) {
       case QUIZ_QUESTION_ANSWER_VERIFY_SCCUESS:
         return {
           ...state,
           userVerifyQuizAnswerSuccess: action.payload
         }
      case QUIZ_ATTEMPTS:
      return {
        ...state,
        quizAttempts: action.payload
      }   
       default:
         return state;
     }
   };
   
   