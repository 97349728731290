import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Logo from "../../../../Images/logo.png";
import Alcohol from "../../../../Images/module8/alcohol.png";
import Drugs from "../../../../Images/module8/drugs.png";
import DriveAlcohol from "../../../../Images/module8/driveacohol.png";
import Physical from "../../../../Images/module8/physical.png";
import Suspension from "../../../../Images/module8/suspension.png";
import Law from "../../../../Images/module8/law.png";
import Pound from "../../../../Images/module8/pound.png";
import PersonCar from "../../../../Images/module8/person_car.png";
import Risk from "../../../../Images/module8/risk_drug.png";
import ProvisionLaw from "../../../../Images/module8/provision.png";
import LawEffect from "../../../../Images/module8/law_effect.png";
import Leandra from "../../../../Images/module8/leandra.mp4";

// import faces image
import face1Image from "../../../../Images/module8/character-face-images/face-1.jpg";
import face2Image from "../../../../Images/module8/character-face-images/face-2.jpg";
import face3Image from "../../../../Images/module8/character-face-images/face-3.jpg";
import face4Image from "../../../../Images/module8/character-face-images/face-4.jpg";
import face5Image from "../../../../Images/module8/character-face-images/face-5.jpg";
import face6Image from "../../../../Images/module8/character-face-images/face-6.jpg";
import face7Image from "../../../../Images/module8/character-face-images/face-7.jpg";
import face8Image from "../../../../Images/module8/character-face-images/face-8.jpg";
import face9Image from "../../../../Images/module8/character-face-images/face-9.jpg";
import face10Image from "../../../../Images/module8/character-face-images/face-10.jpg";
import face11Image from "../../../../Images/module8/character-face-images/face-11.jpg";
import face12Image from "../../../../Images/module8/character-face-images/face-12.jpg";
import face13Image from "../../../../Images/module8/character-face-images/face-13.jpg";
import face14Image from "../../../../Images/module8/character-face-images/face-14.jpg";
import face15Image from "../../../../Images/module8/character-face-images/face-15.jpg";
import face16Image from "../../../../Images/module8/character-face-images/face-16.jpg";
import face17Image from "../../../../Images/module8/character-face-images/face-17.jpg";
import face18Image from "../../../../Images/module8/character-face-images/face-18.jpg";
import face19Image from "../../../../Images/module8/character-face-images/face-19.jpg";

import swal from "sweetalert";
import peopleWhoAreHighRiskImage from "../../../../Images/module8/people-who-are-high-risk.jpg";
import peopleWhoAreLowRiskImage from "../../../../Images/module8/people-who-are-low-risk.jpg";
import situationsThatAreHighRiskImage from "../../../../Images/module8/situations-that-are-high-risk.jpg";
import situationsThatAreLowRiskImage from "../../../../Images/module8/situations-that-are-low-risk.jpg";

import AOS from "aos";
import "aos/dist/aos.css";

import "./ModuleEight.css";

import { H5PEditorUI, H5PPlayerUI } from "@lumieducation/h5p-react";
import { ContentService } from "../services/ContentService";

export const ModuleEightPart4 = () => {
  const [scroll, setScroll] = useState(false);

  const contentService = new ContentService("/h5p");
  const h5pPlayer = React.useRef(H5PPlayerUI);

  const [loading, setLoading] = useState(true);

  const onPlayerInitialized = () => {
    setLoading(false);
  };

  const [image1Clicked, setImage1Clicked] = useState(false);
  const [image2Clicked, setImage2Clicked] = useState(false);
  const [image3Clicked, setImage3Clicked] = useState(false);
  const [image4Clicked, setImage4Clicked] = useState(false);
  const [allFaceImagesClicked, setAllFaceImagesClicked] = useState(false);
  const [decisionMatrixImagesClicked, setDecisionMatrixImagesClicked] =
    useState(false);

  const handleImage1Click = (content) => {
    setImage1Clicked(true);
    handleImageClick("Details", content);
  };

  const handleImage2Click = (content) => {
    setImage2Clicked(true);
    handleImageClick("Details", content);
  };

  const handleImage3Click = (content) => {
    setImage3Clicked(true);
    handleImageClick("Details", content);
  };

  const handleImage4Click = (content) => {
    setImage4Clicked(true);
    handleImageClick("Details", content);
  };

  const clickedStatus = {
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
    8: false,
    9: false,
    10: false,
    11: false,
    12: false,
    13: false,
    14: false,
    15: false,
    16: false,
  };
  const handleFaceImageClick = (index) => {
    let content = "";
    let title = "";

    const clickedImage = document.querySelector(
      `.face-char-img[data-index="${index}"]`
    );
    if (clickedImage && !clickedImage.classList.contains("clicked")) {
      clickedImage.classList.add("clicked");
    }

    switch (index) {
      case 1:
        title = `Driving while ability impaired.`;
        content = `
    <span className="detail">
      John was pulled over by the police after swerving across lanes. Upon approaching the vehicle, the officer noticed the smell of alcohol on John's breath and observed his slurred speech. After conducting a field sobriety test, John's impaired ability to operate the motor vehicle was evident, with a blood alcohol content (BAC) of .07 percent, falling within the range specified under the law, leading to his arrest for driving while ability impaired.
    </span>`;
        break;

      case 2:
        title = `Driving while intoxicated; per se.`;
        content = `
    <span className="detail">
    Sarah was pulled over by the police for speeding. Upon conducting a routine traffic stop, the officer suspected Sarah of driving under the influence of alcohol due to her erratic behavior. Sarah consented to a breathalyzer test, which revealed a blood alcohol content (BAC) of 0.10 percent, exceeding the legal limit of 0.08 percent specified under the law. As per NYS Vehicle and Traffic Law §1192, driving with a BAC of 0.08 percent or higher is prohibited. Consequently, Sarah was arrested for driving while intoxicated; per se.
    </span>`;
        break;

      case 3:
        title = `Aggravated driving while intoxicated. Per se.`;
        content = `
    <span className="detail">
    Emily was stopped by law enforcement officers for swerving on the road and nearly colliding with another vehicle. Suspecting intoxication, the officers conducted a series of field sobriety tests on Emily. Subsequent breathalyzer testing revealed her blood alcohol content (BAC) to be 0.20 percent, surpassing the legal limit of 0.18 percent specified under NYS Vehicle and Traffic Law §1192. As per the law, driving with a BAC of 0.18 percent or higher constitutes aggravated driving while intoxicated; per se. Consequently, Emily was arrested for this offense.
    </span>`;
        break;

      case 4:
        title = ` Aggravated driving while intoxicated. With a child.`;
        content = `
    <span className="detail">
    Michael was pulled over by police officers for weaving between lanes. Upon approaching the vehicle, the officers detected the smell of alcohol emanating from Michael's breath. Subsequent testing revealed his blood alcohol content (BAC) to be 0.15 percent, exceeding the legal limit, as outlined in NYS Vehicle and Traffic Law §1192. Additionally, Michael's six-year-old daughter was found in the backseat of the car. As per the law, driving under the influence with a child aged 15 or younger in the vehicle constitutes aggravated driving while intoxicated. Consequently, Michael was arrested for this offense.
    </span>`;
        break;

      case 5:
        title = `Driving while intoxicated.`;
        content = `
    <span className="detail">
    Alex was stopped by law enforcement officers after they observed him swerving across lanes and driving erratically. Upon approaching the vehicle, the officers noticed the smell of alcohol emanating from Alex's breath and observed other signs of intoxication. Alex consented to a field sobriety test, which he failed. Subsequent breathalyzer testing revealed his blood alcohol content (BAC) to be 0.12 percent, exceeding the legal limit specified under NYS Vehicle and Traffic Law §1192. As per the law, driving while in an intoxicated condition is prohibited. Consequently, Alex was arrested for this offense.
    </span>`;
        break;

      case 6:
        title = `Driving while ability impaired by drugs.`;
        content = `
    <span className="detail">
    Rachel was pulled over by law enforcement officers for speeding and making abrupt lane changes without signaling. Upon approaching the vehicle, the officers noticed Rachel's dilated pupils and erratic behavior, leading them to suspect drug impairment. Rachel admitted to recently using marijuana. Subsequent drug testing confirmed the presence of THC, the active compound in marijuana, in her system. As per NYS Vehicle and Traffic Law §1192, driving while the ability to operate a motor vehicle is impaired by the use of a drug is prohibited. Consequently, Rachel was arrested for this offense.
    </span>`;
        break;

      case 7:
        title = `Driving while ability impaired by the combined influence of drugs or of alcohol and any drug or drugs.`;
        content = `
    <span className="detail">
    David was stopped by law enforcement officers for driving well below the speed limit and weaving between lanes. Upon approaching the vehicle, the officers noticed David's bloodshot eyes and slurred speech, indicating possible impairment. David admitted to consuming alcohol earlier in the evening and taking prescription medication for pain. Suspecting impairment from the combined influence of alcohol and drugs, the officers conducted a series of field sobriety tests. David's performance on these tests indicated impairment. Subsequent testing revealed the presence of both alcohol and prescription drugs in his system. As per NYS Vehicle and Traffic Law §1192, driving while the ability to operate a motor vehicle is impaired by the combined influence of drugs or alcohol and any drug or drugs is prohibited. Consequently, David was arrested for this offense.
    </span>`;
        break;

      case 8:
        title = `Commercial motor vehicles; per se – level l.`;
        content = `
    <span className="detail">
    Brian, a truck driver, was pulled over by law enforcement officers during a routine traffic stop. Suspecting impairment, the officers conducted a breathalyzer test, which revealed Brian's blood alcohol content (BAC) to be 0.05 percent. As per NYS Vehicle and Traffic Law §1192, driving a commercial vehicle with a BAC between 0.04 percent and 0.06 percent is prohibited. Consequently, Brian was arrested for this offense. Additionally, since Brian was under 21 years old and operating a commercial vehicle, his BAC fell within the range of 0.02 percent to 0.04 percent, resulting in a separate violation according to another part of the law.
    </span>`;
        break;

      case 9:
        title = `Commercial motor vehicles; per se – level ll.`;
        content = `
    <span className="detail">
    Emily, a truck driver, was stopped by law enforcement officers for a routine inspection. During the inspection, the officers conducted a breathalyzer test, which revealed Emily's blood alcohol content (BAC) to be 0.07 percent. As per NYS Vehicle and Traffic Law §1192, driving a commercial vehicle with a BAC above 0.06 percent but below 0.08 percent is prohibited. Consequently, Emily was arrested for this offense. Additionally, it's important to note that this rule does not preclude someone from being charged with a violation under a different part of the law.
    </span>`;
        break;

      case 10:
        title = `Where applicable.`;
        content = `
    <span className="detail">
    Mark was stopped by law enforcement officers for drinking alcohol in a parking lot adjacent to a bar. Despite being on private property, Mark was informed by the officers that the law prohibiting alcohol consumption applies to public highways, private roads accessible to cars, and any parking lot meeting specific criteria, as outlined in NYS Vehicle and Traffic Law § 1225. As the parking lot in question met these criteria, Mark was charged with consuming alcohol in a prohibited area. It's important to note that this rule does not apply to private areas associated with one or two-family houses.
    </span>`;
        break;

      case 11:
        title = `Effect of prior out-of-state conviction.`;
        content = `
    <span className="detail">
    Jane, who recently moved to New York from another state, was pulled over for speeding. During the stop, the officer discovered that Jane had a prior conviction for driving under the influence of alcohol in her previous state. Under NYS law, out-of-state convictions for driving under the influence of alcohol or drugs are considered when determining penalties or administrative actions. If the same behavior would have been considered a misdemeanor or felony in New York, it counts as a previous conviction with corresponding penalties. However, if the behavior would have been a less serious offense in New York, it is still counted as a previous conviction but is treated as a less serious violation when determining penalties or required administrative actions. Therefore, Jane's prior out-of-state conviction was taken into account in deciding the appropriate penalty for her current offense.
    </span>`;
        break;

      case 12:
        title = `Effect of prior finding of having consumed alcohol.`;
        content = `
    <span className="detail">
    Tom, who was under the age of 21, was pulled over for running a red light. During the stop, the officer discovered that Tom had a prior finding of driving after consuming alcohol, as outlined in section 1194-a of the law. According to NYS law, such prior findings are treated the same as previous convictions when determining the duration of a driver's license suspension or revocation. However, this applies only if the new offense occurs before the expiration of the time limit for keeping records of the previous offense, as specified in paragraph (k) of subdivision one of section 201. Consequently, Tom's prior finding of having consumed alcohol played a role in deciding the appropriate penalty for his current offense.
    </span>`;
        break;

      case 13:
        title = `Conviction of a different charge.`;
        content = `
    <span className="detail">
    Lisa was stopped by law enforcement officers for speeding, which falls under subdivision two of the law. However, during the course of the investigation, it was discovered that Lisa was also driving while under the influence of alcohol, which falls under subdivision one of the law. Despite being initially charged with speeding, Lisa was found guilty of driving while intoxicated. According to NYS law, a driver can be convicted of breaking any of the laws mentioned in subdivisions one, two, or three of the law, regardless of the original charge. This conviction can occur regardless of whether the driver pleads guilty or not. Therefore, Lisa's conviction for driving while intoxicated was based on the evidence gathered during the stop, even though she was initially pulled over for speeding.
    </span>`;
        break;

      case 14:
        title = `Plea bargain limitations.`;
        content = `
    <span className="detail">
      (a) Jack was charged with driving under the influence of alcohol, which falls under subdivision two of the law. When presented with the option to plead guilty, Jack was required to plead guilty to at least one of the specific violations mentioned in subdivisions two, three, four, or four-a, unless the district attorney determined that the charge wasn't warranted. In such cases, Jack might plead guilty to a different charge. The court must provide an explanation for allowing this plea bargain.
    </span>
    <br></br>
    <span className="detail">
      (ii) Emma, who was charged with driving while intoxicated, a violation falling under subdivision two of the law, decided to plead guilty to a lesser offense, such as driving while ability impaired (subdivision one). As part of the plea bargain, Emma agreed to attend and complete an alcohol and drug rehabilitation program. However, the court has the discretion to waive this requirement if Emma is already enrolled in a treatment program or for other valid reasons.
    </span>
    <br></br>
    <span className="detail">
      (iii) Tyler, who was under 21 years old, was charged with a violation related to alcohol consumption while driving, falling under subdivision one of the law. When pleading guilty, Tyler was required to plead guilty to that specific violation unless otherwise determined by the district attorney. The court was obligated to document the reasons for accepting this plea.
    </span>
    <br></br>
    <span className="detail">
      (b) Hannah, a commercial vehicle driver, was charged with driving while intoxicated, a violation falling under subdivision one of the law. When pleading guilty, Hannah was required to plead guilty to at least one of the specific violations mentioned in subdivisions one or six, unless the district attorney determined that the charge wasn't valid. In such cases, Hannah might plead guilty to a different charge.
    </span>
    <br></br>
    <span className="detail">
      (c) For drivers under 21 charged with a specific violation related to alcohol consumption while driving, the court might decide to resolve the charge by allowing the commissioner to take action. By agreeing to this resolution, the driver waives their right to a hearing, and the agreement is treated as a guilty finding. The driver must sign a waiver in court, acknowledging the consequences, and the commissioner then takes the necessary actions.
    </span>
    <br></br>
    <span className="detail">
      (d) If someone is charged with a specific violation related to refusing a breathalyzer test (subdivision two-a), and they plead guilty, it must be to a related offense mentioned in subdivisions two, two-a, or three. They cannot plead guilty to something else unless agreed upon by the district attorney. The guilty plea might require completing an alcohol and drug rehabilitation program, but the court can waive this requirement for valid reasons. This applies even if there are circumstances that would typically prevent participation in the program. However, this doesn't automatically entitle them to a conditional license.
    </span>`;
        break;

      case 15:
        title = `No person other than an operator of a commercial motor vehicle may be charged with or convicted of a violation of subdivision five or six of this section.`;
        content = `
        <span className="detail">
        Sophia, who was not operating a commercial motor vehicle, was involved in an accident where she was found to have a blood alcohol content (BAC) exceeding the legal limit. The charge against Sophia fell under subdivisions five or six of the law. According to NYS Vehicle and Traffic Law, only operators of commercial motor vehicles can be charged with or convicted of violations falling under these subdivisions. Therefore, Sophia cannot be charged or convicted of the violation as she was not operating a commercial motor vehicle at the time of the offense.
        </span>`;
        break;

      case 16:
        title = `Driving while intoxicated or while ability impaired by drugs--serious physical injury or death or child in the vehicle.`;
        content = `
    <span className="detail">
      (a) Sarah was pulled over by law enforcement officers for driving erratically, and upon investigation, it was determined that she was driving under the influence of alcohol, falling under subdivisions two, two-a, three, four, or four-a of the law. As required by NYS Vehicle and Traffic Law, the police officer made a special note on the traffic ticket indicating "D" if someone other than Sarah was killed, "S.P.I." if someone else was seriously hurt as defined in the penal law, and "C.I.V." if a child 15 or younger was in the vehicle. However, the absence of these notes doesn't alter the driving charge itself.
    </span>
    <br></br>
    <span className="detail">
      (b) John, who was the parent of a child aged 15 or younger, was accused by law enforcement officers of a specific violation falling under paragraph (b) of subdivision two-a of the law. As required by the law, the officer must report this situation following the social services law.
    </span>`;
        break;
      default:
        content = "Something went wrong";
        break;
    }

    clickedStatus[index] = true;
    const allClicked = Object.values(clickedStatus).every((status) => status);
    if (allClicked) {
      console.warn("All face images are clicked!");
      setAllFaceImagesClicked(true);
      sessionStorage.setItem("HAS_H5P_ACTIVITY_SUBMIT", true);

      const h5pContentNavigate = document.getElementById(
        "h5p_content_navigate"
      );
      if (h5pContentNavigate) {
        h5pContentNavigate.removeAttribute("id");
      }

      document.querySelectorAll(".add-h5p-class-1").forEach((element) => {
        element.setAttribute("id", "h5p_content_navigate");
      });
    }

    handleImageClick(title, content);
  };

  const handleImageClick = (title, content) => {
    swal({
      title: title,
      content: {
        element: "div",
        attributes: {
          innerHTML: content,
        },
      },
    });
  };

  // useEffect(() => {
  //   if (image1Clicked && image2Clicked && image3Clicked && image4Clicked) {
  //     console.warn("All decision Matrix images are visited.");
  //     setDecisionMatrixImagesClicked(true);
  //   }
  // }, [image1Clicked, image2Clicked, image3Clicked, image4Clicked]);

  // useEffect(() => {
  //   if (allFaceImagesClicked && decisionMatrixImagesClicked) {
  //     sessionStorage.setItem("HAS_H5P_ACTIVITY_SUBMIT", true);
  //   }
  // }, [allFaceImagesClicked, decisionMatrixImagesClicked]);

  useEffect(() => {
    AOS.init();
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  useEffect(() => {
    sessionStorage.setItem("HAS_VIDEO", true);
    sessionStorage.setItem("HAS_H5P_ACTIVITY", true);
    sessionStorage.setItem("HAS_H5P_ACTIVITY_SUBMIT", false);
  }, []);

  var video = document.getElementById("video");
  useEffect(() => {
    if (video) {
      var supposedCurrentTime = 0;
      video.addEventListener("timeupdate", function () {
        if (!video.seeking) {
          supposedCurrentTime = video.currentTime;
        }
      });
      // prevent user from seeking
      video.addEventListener("seeking", function () {
        // guard agains infinite recursion:
        // user seeks, seeking is fired, currentTime is modified, seeking is fired, current time is modified, ....
        var delta = video.currentTime - supposedCurrentTime;
        if (Math.abs(delta) > 0.01) {
          console.log("Seeking is disabled");
          video.currentTime = supposedCurrentTime;
        }
      });
      // delete the following event handler if rewind is not required
      video.addEventListener("ended", function () {
        // reset state in order to allow for rewind
        supposedCurrentTime = 0;
      });
    }
  }, [video]);

  return (
    <div className="module8_course module_font same_width_module mb-md-4">
      <Container>
        
        <div className="leandra_law">
          <Container>
            <h2
              className="same_alcohol_head"
              data-aos="zoom-in-right"
              data-aos-duration="2000"
            >
              LEANDRA’S LAW
            </h2>
            <p>
              Section 1192 of the NYS Vehicle and Traffic Law provides penalties
              when a person drives while intoxicated or while his or her ability
              is impaired by drugs or a combination of drugs and alcohol. In
              December, 2009, the Vehicle and Traffic Law, as well as NYS Penal
              and Executive laws, were amended to include increased penalties
              for a motorist who is in violation of these laws when a child 15
              years of age or younger is a passenger in the vehicle. This
              established “Leandra’s Law”, named in honor of 11-year old Leandra
              Rosado, who was killed in an automobile crash in October, 2009 in
              a vehicle driven by the intoxicated mother of her friend.
            </p>
            <h4 className="mt-3 mb-3">What is “Leandra’s Law”?</h4>
            <p>
              While all persons who drive while intoxicated pose a danger to
              others, the person who knowingly chooses to drive under the
              influence of alcohol or drugs with children in the car has made a
              conscious decision to put those children at risk of harm or death.
              An adult can take steps to avoid riding with an impaired driver.
              But a child may not have that choice, particularly when the driver
              is a parent or guardian. This amendment to Section 1192 created a
              new aggravated driving while intoxicated offense for a person who
              operates a vehicle while intoxicated by alcohol, impaired by
              drugs, or a combination of both{" "}
              <span>
                while a child who is 15 years of age or younger is a passenger
                in the vehicle.
              </span>
              This is a class E felony (maximum sentence of up to four years). A
              person convicted of aggravated DWI must install and maintain an
              ignition interlock device. The applicable Penal Law penalties are
              increased by one felony class level if the operator causes the
              death of, or serious physical injury to, such child. In addition,
              all individuals convicted of misdemeanor offenses (§1192 (2) and
              (3)) will be required to install and maintain an ignition
              interlock device.
            </p>
            <video
              id="video"
              width="100%"
              height="400"
              autoplay={true}
              onEnded={() => {
                sessionStorage.setItem("VIDEO_PLAYED", "COMPLETED");
              }}
              controls
              controlsList="nodownload noplaybackrate"
            >
              <source src={Leandra} type="video/mp4" />
            </video>
          </Container>
        </div>

        <div className="provision_law pb-5">
          <Container>
            <h2
              className="same_alcohol_head"
              data-aos="zoom-in-right"
              data-aos-duration="2000"
            >
              PROVISIONS OF LEANDRA’S LAW
            </h2>

            <ul className="p-0">
              <li>
                Driving under the influence with a child aged 15 or younger in
                the vehicle is considered a criminal offense under Leandra’s
                Law. Law enforcement must report incidents where a child or
                other individual in the vehicle has been killed, seriously
                injured, or was present during the arrest. If the driver is the
                child's parent, guardian, or custodian, the officer must notify
                the Department of Social Services.
              </li>

              <li>
                Driving while intoxicated with a child in the vehicle is
                classified as a Class E felony, carrying a potential prison
                sentence of up to four years. In addition to fines or
                imprisonment, the court must order probation or conditional
                discharge, requiring the installation of an ignition interlock
                device on any vehicle owned or operated by the offender for at
                least six months. Those charged with aggravated driving while
                intoxicated with a child cannot have the charge reduced to a
                non-criminal violation.
              </li>

              <Row>
                <Col md={6} xs={12}>
                  <li>
                    Amendments to the Penal Law ensure that those convicted of
                    driving while intoxicated or aggravated driving while
                    intoxicated face the full range of penalties associated with
                    misdemeanors or Class E felonies. They are also required to
                    undergo probation or conditional discharge to enforce the
                    use of ignition interlock devices.
                  </li>
                </Col>

                <Col md={6} xs={12}>
                  <div
                    className="provision_law_img"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    <img src={ProvisionLaw} alt="Image" />
                  </div>
                </Col>
              </Row>

              <li>
                <h3>Additional amendments include:</h3>
                <ul>
                  <li>
                    <h4>
                      <b>Vehicular Assault (First Degree)</b>
                    </h4>
                    <p>
                      A Class D felony (up to seven years) now covers those who
                      commit second-degree vehicular assault, causing serious
                      injury to a child in the vehicle.
                    </p>
                  </li>

                  <li>
                    <h4>
                      <b>Aggravated Vehicular Assault</b>
                    </h4>
                    <p>
                      A Class C felony (up to 15 years) includes reckless
                      driving in addition to second-degree vehicular assault
                      causing serious injury to a child.
                    </p>
                  </li>

                  <li>
                    <h4>
                      <b>Vehicular Manslaughter (First Degree)</b>
                    </h4>
                    <p>
                      A Class C felony (up to 15 years) now applies to
                      individuals who commit second-degree vehicular
                      manslaughter, resulting in the death of a child in the
                      vehicle.
                    </p>
                  </li>

                  <li>
                    <h4>
                      <b>Aggravated Vehicular Homicide</b>
                    </h4>
                    <p>
                      A Class B felony (up to 25 years) now applies to those who
                      commit vehicular manslaughter, drive recklessly, and cause
                      the death of a child.
                    </p>
                  </li>
                </ul>
              </li>

              <li>
                <p>
                  Under the Executive Law, individuals released on parole or
                  conditional release following convictions for these offenses,
                  including aggravated driving while intoxicated with a child in
                  the vehicle, must install and maintain an ignition interlock
                  device as a condition of their release.
                </p>
              </li>
            </ul>
          </Container>
        </div>

        <div className="provision_law pb-5">
          <Container>
            <h2
              className="same_alcohol_head"
              data-aos="zoom-in-right"
              data-aos-duration="2000"
            >
              EXAMPLES OF INDIVIDUALS IN VIOLATION OF LEANDRA'S LAW AND THEIR
              REPERCUSSIONS
            </h2>

            <ul className="p-0">
              <li>
                Example 1: Bob is a 32-year-old father who goes out for a night
                of drinking with friends. After drinking a few too many beers,
                he gets behind the wheel to drive home with his 8-year-old son,
                Timmy, in the car. A police officer pulls him over for a routine
                traffic stop and suspects that he may be under the influence of
                alcohol. The officer requests a chemical test, which shows that
                Bob's BAC is .08%. According to Leandra's Law, this is a
                violation and Bob is charged with aggravated DWI, which is a
                class E felony. He faces up to four years in prison and must
                install and maintain an ignition interlock device on his car.
              </li>

              <li>
                Example 2: Jane is a 25-year-old mother who goes out for a night
                of drinking with friends. After drinking a few too many
                cocktails, she gets behind the wheel to drive home with her
                4-year-old daughter, Emily, in the car. A police officer pulls
                her over for a routine traffic stop and suspects that she may be
                under the influence of alcohol. The officer requests a chemical
                test, which shows that Jane's BAC is .09%. According to
                Leandra's Law, this is a violation and Jane is charged with
                aggravated DWI, which is a class E felony. She faces up to four
                years in prison and must install and maintain an ignition
                interlock device on her car.
              </li>

              <li>
                Example 3: Mark is a 45-year-old businessman who goes out for a
                night of drinking with colleagues. After drinking a few too many
                glasses of wine, he gets behind the wheel to drive home with his
                15-year-old son, David, in the car. A police officer pulls him
                over for a routine traffic stop and suspects that he may be
                under the influence of alcohol. The officer requests a chemical
                test, which shows that Mark's BAC is .10%. According to
                Leandra's Law, this is a violation and Mark is charged with
                aggravated DWI, which is a class E felony. He faces up to four
                years in prison and must install and maintain an ignition
                interlock device on his car.
              </li>
            </ul>
          </Container>
        </div>

        <div className="class_law">
          <div className="traffic_law pb-5">
            <Container>
              <h2
                className="same_traffic_head"
                data-aos="zoom-in-right"
                data-aos-duration="2000"
              >
                NYS VEHICLE AND TRAFFIC LAW §1192
                <span className="d-block">
                  OPERATING A MOTOR VEHICLE WHILE UNDER THE INFLUENCE OF ALCOHOL
                  OR DRUGS
                </span>
              </h2>
              <p className="main_para mt-3 mb-5">
                Section 1192 of the Vehicle and Traffic Law is very explicit in
                relation to drinking and driving, and the penalties are quite
                severe. A BAC of greater than .05 percent but not more than .07
                percent can be relevant evidence to determine alcohol
                impairment. A BAC of greater than .07 percent but less than .08
                percent can be primary evidence in determining whether the
                ability to operate a motor vehicle was impaired. Driving a motor
                vehicle with a blood alcohol content (BAC) of .08 percent or
                greater is prima facie evidence of intoxication.
              </p>
              <h3 className="h5p-content-note">
                Note: Click on each photo to view an example of a person
                committing each offense.
              </h3>
              <div id="h5p_content_navigate">
                <Container>
                  <Row>
                    <Col xs={12} sm={6} md={3}>
                      <img
                        src={face1Image}
                        alt="Image 1"
                        className="face-char-img"
                        onClick={() => handleFaceImageClick(1)}
                        style={{ maxWidth: "100%" }}
                        data-index={1}
                      />
                      <h2 className="title-text-wrapper">
                        Driving while ability impaired.
                      </h2>
                    </Col>
                    <Col xs={12} sm={6} md={3}>
                      <img
                        src={face2Image}
                        alt="Image 2"
                        className="face-char-img"
                        onClick={() => handleFaceImageClick(2)}
                        style={{ maxWidth: "100%" }}
                        data-index={2}
                      />
                      <h2 className="title-text-wrapper">
                        Driving while intoxicated; per se.
                      </h2>
                    </Col>
                    <Col xs={12} sm={6} md={3}>
                      <img
                        src={face3Image}
                        alt="Image 3"
                        className="face-char-img"
                        onClick={() => handleFaceImageClick(3)}
                        style={{ maxWidth: "100%" }}
                        data-index={3}
                      />
                      <h2 className="title-text-wrapper">
                        Aggravated driving while intoxicated. Per se.
                      </h2>
                    </Col>
                    <Col xs={12} sm={6} md={3}>
                      <img
                        src={face4Image}
                        alt="Image 4"
                        className="face-char-img"
                        onClick={() => handleFaceImageClick(4)}
                        style={{ maxWidth: "100%" }}
                        data-index={4}
                      />
                      <h2 className="title-text-wrapper">
                        Aggravated driving while intoxicated. With a child.
                      </h2>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={6} md={3}>
                      <img
                        src={face5Image}
                        alt="Image 5"
                        className="face-char-img"
                        onClick={() => handleFaceImageClick(5)}
                        style={{ maxWidth: "100%" }}
                        data-index={5}
                      />
                      <h2 className="title-text-wrapper">
                        Driving while intoxicated.
                      </h2>
                    </Col>
                    <Col xs={12} sm={6} md={3}>
                      <img
                        src={face6Image}
                        alt="Image 6"
                        className="face-char-img"
                        onClick={() => handleFaceImageClick(6)}
                        style={{ maxWidth: "100%" }}
                        data-index={6}
                      />
                      <h2 className="title-text-wrapper">
                        Driving while ability impaired by drugs.
                      </h2>
                    </Col>
                    <Col xs={12} sm={6} md={3}>
                      <img
                        src={face18Image}
                        alt="Image 7"
                        className="face-char-img"
                        onClick={() => handleFaceImageClick(7)}
                        style={{ maxWidth: "100%" }}
                        data-index={7}
                      />
                      <h2 className="title-text-wrapper">
                        Driving while ability impaired by the combined influence
                        of drugs or of alcohol and any drug or drugs.
                      </h2>
                    </Col>
                    <Col xs={12} sm={6} md={3}>
                      <img
                        src={face8Image}
                        alt="Image 8"
                        className="face-char-img"
                        onClick={() => handleFaceImageClick(8)}
                        style={{ maxWidth: "100%" }}
                        data-index={8}
                      />
                      <h2 className="title-text-wrapper">
                        Commercial motor vehicles; per se – level l.{" "}
                      </h2>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={6} md={3}>
                      <img
                        src={face9Image}
                        alt="Image 9"
                        className="face-char-img"
                        onClick={() => handleFaceImageClick(9)}
                        style={{ maxWidth: "100%" }}
                        data-index={9}
                      />
                      <h2 className="title-text-wrapper">
                        Commercial motor vehicles; per se – level ll.
                      </h2>
                    </Col>
                    <Col xs={12} sm={6} md={3}>
                      <img
                        src={face10Image}
                        alt="Image 10"
                        className="face-char-img"
                        onClick={() => handleFaceImageClick(10)}
                        style={{ maxWidth: "100%" }}
                        data-index={10}
                      />
                      <h2 className="title-text-wrapper">Where applicable.</h2>
                    </Col>
                    <Col xs={12} sm={6} md={3}>
                      <img
                        src={face11Image}
                        alt="Image 11"
                        className="face-char-img"
                        onClick={() => handleFaceImageClick(11)}
                        style={{ maxWidth: "100%" }}
                        data-index={11}
                      />
                      <h2 className="title-text-wrapper">
                        Effect of prior out-of-state conviction.
                      </h2>
                    </Col>
                    <Col xs={12} sm={6} md={3}>
                      <img
                        src={face12Image}
                        alt="Image 12"
                        className="face-char-img"
                        onClick={() => handleFaceImageClick(12)}
                        style={{ maxWidth: "100%" }}
                        data-index={12}
                      />
                      <h2 className="title-text-wrapper">
                        Effect of prior finding of having consumed alcohol.
                      </h2>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={6} md={3}>
                      <img
                        src={face13Image}
                        alt="Image 13"
                        className="face-char-img"
                        onClick={() => handleFaceImageClick(13)}
                        style={{ maxWidth: "100%" }}
                        data-index={13}
                      />
                      <h2 className="title-text-wrapper">
                        Conviction of a different charge.
                      </h2>
                    </Col>
                    <Col xs={12} sm={6} md={3}>
                      <img
                        src={face14Image}
                        alt="Image 14"
                        className="face-char-img"
                        onClick={() => handleFaceImageClick(14)}
                        style={{ maxWidth: "100%" }}
                        data-index={14}
                      />
                      <h2 className="title-text-wrapper">
                        Plea bargain limitations.
                      </h2>
                    </Col>
                    <Col xs={12} sm={6} md={3}>
                      <img
                        src={face15Image}
                        alt="Image 15"
                        className="face-char-img"
                        onClick={() => handleFaceImageClick(15)}
                        style={{ maxWidth: "100%" }}
                        data-index={15}
                      />
                      <h2 className="title-text-wrapper">
                        No person other than an operator of a commercial motor
                        vehicle may be charged with or convicted of a violation
                        of subdivision five or six of this section.
                      </h2>
                    </Col>
                    <Col xs={12} sm={6} md={3}>
                      <img
                        src={face16Image}
                        alt="Image 16"
                        className="face-char-img"
                        onClick={() => handleFaceImageClick(16)}
                        style={{ maxWidth: "100%" }}
                        data-index={16}
                      />
                      <h2 className="title-text-wrapper">
                        Driving while intoxicated or while ability impaired by
                        drugs--serious physical injury or death or child in the
                        vehicle.
                      </h2>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Container>
          </div>
        </div>

        <div className="provision_law pb-5">
          <Container>
            <h2
              className="same_alcohol_head"
              data-aos="zoom-in-right"
              data-aos-duration="2000"
            >
              Penalties for alcohol or drug-related violations
            </h2>
            <p className="mt-3">
              Driving while intoxicated is a crime. Your judgment, coordination
              and ability to drive a vehicle change when you consume any amount
              of alcohol. The level of impairment depends on five conditions.
            </p>
            <ul className="p-0">
              <li>the amount of alcohol you drink</li>
              <li>
                the amount of food you eat before or while you drink alcohol
              </li>
              <li>the length of time you drink alcohol</li>
              <li>your body weight</li>
              <li>your gender</li>
            </ul>
            <p>
              There is no quick method to become sober. The best method is to
              wait until your body absorbs the alcohol. The average rate that
              your body processes alcohol is approximately one drink per hour.
            </p>

            <h5 className="mt-5 mb-3">
              Types of alcohol and drug-related violations in New York State:
            </h5>

            <Col>
              <div className="definition_drug odd_color">
                <h6>Driving While Intoxicated (DWI)</h6>

                <p>
                  .08 Blood Alcohol Content (BAC) or higher or other evidence of
                  intoxication. For drivers of commercial motor vehicles: .04
                  BAC or other evidence of intoxication.{" "}
                </p>
              </div>
            </Col>
            <Col>
              <div className="definition_drug even_color">
                <h6>Aggravated Driving While Intoxicated (Aggravated DWI)</h6>

                <p>.18 BAC or higher</p>
              </div>
            </Col>
            <Col>
              <div className="definition_drug odd_color">
                <h6>
                  Driving While Ability Impaired by Alcohol (DWAI/Alcohol)
                </h6>

                <p>
                  More than .05 BAC but less than .07 BAC, or other evidence of
                  impairment.
                </p>
              </div>
            </Col>
            <Col>
              <div className="definition_drug even_color">
                <h6>
                  Driving While Ability Impaired by a Single Drug other than
                  Alcohol (DWAI/Drug)
                </h6>
              </div>
            </Col>
            <Col>
              <div className="definition_drug odd_color">
                <h6>
                  Driving While Ability Impaired by a Combined Influence of
                  Drugs or Alcohol (DWAI/Combination)
                </h6>
              </div>
            </Col>
            <Col>
              <div className="definition_drug even_color">
                <h6>Chemical Test Refusal</h6>
                <p>
                  A driver who refuses to take a chemical test (normally a test
                  of breath, blood or urine).
                </p>
              </div>
            </Col>
            <Col>
              <div className="definition_drug odd_color">
                <h6>Zero Tolerance Law</h6>
                <p>
                  A driver who is less than 21 years of age and who drives with
                  a .02 BAC to .07 BAC violates the Zero Tolerance Law.
                </p>
              </div>
            </Col>

            <p className="mt-5 mb-5">
              In New York State, the penalties for an alcohol or drug-related
              violation include the loss of driving privileges, fines, and a
              possible jail term.
            </p>
            <table className="table">
              <thead>
                <tr>
                  <th>VIOLATION</th>
                  <th>MANDATORY FINE</th>
                  <th>MAXIMUM JAIL TERM</th>
                  <th>MANDATORY DRIVER LICENSE ACTION</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Aggravated Driving While Intoxicated (AGG DWI)</td>
                  <td>$1,000 - $2,500</td>
                  <td>1 year</td>
                  <td>Revoked for at least one year</td>
                </tr>
                <tr>
                  <td>Second AGG DWI in 10 years (E felony)</td>
                  <td>$1,000 - $5,000</td>
                  <td>4 years</td>
                  <td>Revoked for at least 18 months</td>
                </tr>
                <tr>
                  <td>Third AGG DWI in 10 years (D felony)</td>
                  <td>$2,000 - $10,000</td>
                  <td>7 years</td>
                  <td>Revoked for at least 18 months</td>
                </tr>
                <tr>
                  <td>
                    Driving While Intoxicated (DWI) or Driving While Impaired by
                    a Drug (DWAI-Drug)
                  </td>
                  <td>$500 - $1,000</td>
                  <td>1 year</td>
                  <td>Revoked for at least six months</td>
                </tr>
                <tr>
                  <td>Second DWI/DWAI-Drug violation in 10 years (E felony)</td>
                  <td>$1,000 - $5,000</td>
                  <td>4 years</td>
                  <td>Revoked for at least one year</td>
                </tr>
                <tr>
                  <td>
                    Third DWI or DWAI-Drug violation in 10 years (D felony)
                  </td>
                  <td>$2,000 - $10,000</td>
                  <td>7 years</td>
                  <td>Revoked for at least one year</td>
                </tr>
                <tr>
                  <td>
                    Driving While Ability Impaired by a Combination of
                    Alcohol/Drugs (DWAI-Combination)
                  </td>
                  <td>$500 - $1,000</td>
                  <td>1 year</td>
                  <td>Revoked for at least six months</td>
                </tr>
                <tr>
                  <td>Second DWAI-Combination in 10 years (E felony)</td>
                  <td>$1,000 - $5,000</td>
                  <td>4 years</td>
                  <td>Revoked for at least one year</td>
                </tr>
                <tr>
                  <td>Third DWAI-Combination in 10 years (D felony)</td>
                  <td>$2,000 - $10,000</td>
                  <td>7 years</td>
                  <td>Revoked for at least one year</td>
                </tr>
                <tr>
                  <td>Driving While Ability Impaired by Alcohol (DWAI)</td>
                  <td>$300 - $500</td>
                  <td>15 days</td>
                  <td>Suspended for 90 days</td>
                </tr>
                <tr>
                  <td>Second DWAI violation in 5 years</td>
                  <td>$500 - $750</td>
                  <td>30 days</td>
                  <td>Revoked for at least six months</td>
                </tr>
                <tr>
                  <td>
                    Third or subsequent DWAI within 10 years (Misdemeanor)
                  </td>
                  <td>$750 - $1,500</td>
                  <td>180 days</td>
                  <td>Revoked for at least six months</td>
                </tr>
                <tr>
                  <td>Zero Tolerance Law</td>
                  <td>
                    $125 civil penalty and $100 fee to terminate suspension
                  </td>
                  <td>None</td>
                  <td>Suspended for six months</td>
                </tr>
                <tr>
                  <td>Second Zero Tolerance Law</td>
                  <td>$125 civil penalty and $100 re-application fee</td>
                  <td>None</td>
                  <td>Revoked for one year or until age 21</td>
                </tr>
                <tr>
                  <td>Chemical Test Refusal</td>
                  <td>$500 civil penalty ($550 for commercial drivers)</td>
                  <td>None</td>
                  <td>
                    Revoked for at least one year, 18 months for commercial
                    drivers.
                  </td>
                </tr>
                <tr>
                  <td>
                    Chemical Test Refusal within five years of a previous
                    DWI-related charge/Chemical Test Refusal
                  </td>
                  <td>$750 civil penalty</td>
                  <td>None</td>
                  <td>
                    Revoked for at least 18 months, one-year or until age 21 for
                    drivers under age 21, permanent CDL revocation for
                    commercial drivers.
                  </td>
                </tr>
                <tr>
                  <td>Chemical Test Refusal - Zero Tolerance Law</td>
                  <td>$300 civil penalty and $100 re-application fee</td>
                  <td>None</td>
                  <td>Revoked for at least one year</td>
                </tr>
                <tr>
                  <td>
                    Chemical Test Refusal - Second or subsequent Zero Tolerance
                    Law
                  </td>
                  <td>$750 civil penalty and $100 re-application fee</td>
                  <td>None</td>
                  <td>Revoked for at least one year</td>
                </tr>
                <tr>
                  <td>Driving Under the Influence (Out-of-State)</td>
                  <td>N/A</td>
                  <td>N/A</td>
                  <td>
                    Revoked for at least 90 days. If less than 21 years of age,
                    revoked at least one year.
                  </td>
                </tr>
                <tr>
                  <td>
                    Driving Under the Influence (Out-of State) with any previous
                    alcohol-drug violation
                  </td>
                  <td>N/A</td>
                  <td>N/A</td>
                  <td>
                    Revoked for at least 90 days (longer term with certain prior
                    offenses). If less than 21 years of age, revoked at least
                    one year or until age 21 (longest term).
                  </td>
                </tr>
              </tbody>
            </table>

            <h5 className="mt-5 mb-3">Additional penalties</h5>
            <ul className="p-0">
              <li>
                Greater penalties can also apply for multiple alcohol or drug
                violations within a 25-year period.
              </li>
              <li>
                Surcharges are added to alcohol-related misdemeanors ($260) and
                felonies (generally $400, but varies slightly depending on court
                of conviction).
              </li>
              <li>
                Three or more alcohol or drug-related convictions or refusals
                within 10 years can result in permanent revocation, with a
                waiver request permitted after at least 5 years.
              </li>
              <li>
                A driver with an Aggravated DWI violation conviction within the
                prior 10 years will receive a minimum 18-month revocation if
                convicted of DWI, DWAI/Drugs or DWAI/Combination. Also, a driver
                with a prior DWI, Aggravated DWI, DWAI/Drugs or DWAI/Combination
                with the prior 10 years will receive a minimum 18-month
                revocation.
              </li>
              <li>
                A driver convicted of an Aggravated DWI, DWI, DWAI/Drug,
                DWAI/combination, vehicular assault and aggravated vehicular
                assault, or vehicular manslaughter and vehicular homicide three
                or more times in the preceding 15 year period is guilty of a
                Class D felony.
              </li>
              <li>
                In addition to the penalties for alcohol and drug related
                violations of the NYS Vehicle and Traffic Law, there are
                penalties set forth in the NYS Penal Law relating to driving
                while intoxicated or impaired by drugs.
              </li>
            </ul>

            <h5 className="mt-5 mb-3">
              Vehicular assault in the second degree
            </h5>
            <p>Section 120.03 of the Penal Law </p>
            <p>
              A person commits the crime of vehicular assault in the second
              degree if they seriously injure someone else because they were
              driving under the influence. This includes:
            </p>
            <ul className="p-0">
              <li>
                Driving while intoxicated (DWI) as per Vehicle and Traffic Law
                §1192(2) – driving with a blood alcohol level above the legal
                limit.
              </li>
              <li>
                Driving while intoxicated (DWI) as per §1192(3) – driving under
                the influence of alcohol.
              </li>
              <li>Driving while ability impaired by drugs as per §1192(4).</li>
              <li>
                Driving while ability impaired by a combination of drugs or
                alcohol and drugs as per §1192(4-a).
              </li>
            </ul>
            <p>
              The injury must be a direct result of the driver being intoxicated
              or impaired.
            </p>
            <p>
              Vehicular assault in the second degree is a class E felony. The
              maximum sentence for this crime can be up to 4 years in prison.
            </p>
          </Container>
        </div>
      </Container>
    </div>
  );
};
