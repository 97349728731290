import "./App.css";
import {Routes} from './Routes/Routes';
import { ToastContainer, toast } from 'react-toastify';
import { useEffect } from "react";
import ConnectionNotification from 'react-connection-notification';


const App = () => {

  return (
    <div className="App_container">
      <div className="App_header_container">
        <ConnectionNotification
          position="top-right"
          style={{zIndex:"1000"}}
          duration={4000}
        />
        <Routes /> {/**Routes set */}
      </div>
  </div>
  );
};

export default App;