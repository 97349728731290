import { OTP_SEND, OTP_VERIFY } from '../../Actions/ActionTypes/UserOtpTypes';

const iState = {

    userOtpSendSuccess: {},
    userOtpVerifySuccess: {},
};

export const userOtpRecucer = (state = iState, action) => {
    switch (action.type) {
        case OTP_SEND:
            return {
                ...state,
                userVerifyQuizAnswerSuccess: action.payload
            }
        case OTP_VERIFY:
            return {
                ...state,
                quizAttempts: action.payload
            }
        default:
            return state;
    }
};

