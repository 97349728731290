import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Alcohol from "../../../../Images/module8/alcohol.png";
import Drugs from "../../../../Images/module8/drugs.png";
import DriveAlcohol from "../../../../Images/module8/driveacohol.png";
import Physical from "../../../../Images/module8/physical.png";
import Suspension from "../../../../Images/module8/suspension.png";
import Law from "../../../../Images/module8/law.png";
import Pound from "../../../../Images/module8/pound.png";
import PersonCar from "../../../../Images/module8/person_car.png";
import Risk from "../../../../Images/module8/risk_drug.png";
import ProvisionLaw from "../../../../Images/module8/provision.png";
import LawEffect from "../../../../Images/module8/law_effect.png";
import Leandra from "../../../../Images/module8/leandra.mp4";
import Chemical from "../../../../Images/module8/m8p1-chemical-test.jpg";

import AOS from "aos";
import "aos/dist/aos.css";

import "./ModuleEight.css";

import { H5PEditorUI, H5PPlayerUI } from "@lumieducation/h5p-react";
import { ContentService } from "../services/ContentService";

export const ModuleEightPart2 = () => {
  const [scroll, setScroll] = useState(false);

  const contentService = new ContentService("/h5p");
  const h5pPlayer = React.useRef(H5PPlayerUI);

  const [loading, setLoading] = useState(true);

  const [showText, setShowText] = useState(false);

  const handleButtonClick = () => {
    sessionStorage.setItem("HAS_H5P_ACTIVITY_SUBMIT", true);
    setShowText(true);
  };

  const onPlayerInitialized = () => {
    setLoading(false);
  };

  useEffect(() => {
    sessionStorage.setItem("HAS_H5P_ACTIVITY", true);
    sessionStorage.setItem("HAS_H5P_ACTIVITY_SUBMIT", false);
  }, []);

  useEffect(() => {
    AOS.init();
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  return (
    <div className="module8_course module_font same_width_module mb-md-4">
      <Container>
        <div className="vehicle_undersection">
          <Container>
            <h2
              className="same_vehicle_head"
              data-aos="zoom-in-right"
              data-aos-duration="2000"
            >
              SECTION 1194 OF THE VEHICLE & TRAFFIC LAW REGARDING CHEMICAL
              TESTING & IMPLIED CONSENT
            </h2>

            <ul className="p-0">
              <li>
                Chemical tests use blood, breath, urine or saliva to measure the
                alcohol and/or drug content of a person’s blood.
              </li>

              <li>
                When a driver is arrested for an alcohol or drug-related
                violation, the police officer may request that he or she submit
                to a chemical test.
              </li>

              <li>
                Under New York’s “Implied Consent” law, any motorist who drives
                in this state is considered to have already given consent for
                this test.
              </li>
            </ul>

            <h6>CAN YOU REFUSE A CHEMICAL TEST?</h6>

            <div className="h5p-iframe-module-8" id="h5p_content_navigate">
              
              <img src={Chemical} width="100%" alt="Image" />

              {!showText && (
                <button onClick={handleButtonClick} className="press-btn">
                  Press here for the correct answer after making a prediction.
                </button>
              )}
              {showText && (
                <h4 className="para-content">
                  No, you may not refuse a chemical test. - Under New York's
                  "Implied Consent" law, any motorist who drives in this state
                  is considered to have already given consent for this test. -
                  The penalties for refusing are in addition to penalties
                  imposed after conviction of the alcohol or drug related
                  traffic violation. If a motorist refuses to take the chemical
                  test after being arrested, his or her license will be
                  suspended at the court arraignment. The fact that the chemical
                  test was refused may be introduced in court when the motorist
                  is tried on the alcohol or drug-related charge. - A separate
                  DMV hearing will be held to determine whether the chemical
                  test was refused. If the hearing confirms that the test was
                  refused, the motorist's license will be revoked, even if he or
                  she is found not guilty of the alcohol or other drug-related
                  violation. In addition to the license revocation, the motorist
                  will be required to pay a civil penalty as well as take a
                  driver responsibility assessment. - The court will also order
                  a screening to determine whether treatment for alcoholism or
                  substance abuse is required. - Specific information about
                  sanctions and penalties for chemical test refusal may be found
                  in chapters two and nine of the Driver's Manuel.
                </h4>
              )}

              <h3 className="h5p-content-note">
              Please complete the activity above before going further.
              </h3>
            </div>

           

            <h6>THE DRIVER RESPONSIBILITY ASSESSMENT (DRA)</h6>

            <p className="mb-3 vehiclesection">
              Sections 503(4) and 1199 of the Vehicle and Traffic Law impose a
              Driver Responsibility Assessment (DRA) following convictions for
              certain traffic violations.
            </p>

            <p className="mb-3 vehiclesection">
              This assessment is in addition to any fines, fees, penalties or
              surcharges that are already collected by the courts. DMV (not the
              courts) is responsible for collecting this assessment from
              motorists.
            </p>

            <h6>THE DRA IS IMPOSED IN THE FOLLOWING CIRCUMSTANCES:</h6>

            <ul className="p-0">
              <li>
                When a motorist is convicted of any alcohol or drug-related
                driving offense or of a refusal to submit to a chemical test
                that does not arise out of the same incident as the conviction.
                The assessment is $250 each year for three years.
              </li>

              <li>
                The DMV assigns points for conviction of some traffic
                violations. When a motorist accumulates six or more points on
                their driving record within an 18-month period, the assessment
                is $100 each year for three years, plus $25 for each additional
                point accumulated during that 18-month period.
              </li>

              <li>
                Completing a point reduction course will not reduce the number
                of points for the purposes of the DRA.
              </li>
            </ul>

            <Row>
              <Col md={8} xs={12}>
                <h6 className="mt-3">DRA SUSPENSION</h6>

                <p className="dra_para">
                  If a DRA remains unpaid for a period of 45 days, driving
                  privileges are suspended. The suspension will only be cleared
                  upon receipt of payment.
                </p>
              </Col>

              <Col md={4} xs={12}>
                <div
                  className="suspension_img mb-3"
                  data-aos="fade-left"
                  data-aos-duration="2000"
                >
                  <img src={Suspension} alt="Image" />
                </div>
              </Col>
            </Row>

            <h6>EXAMPLE OF AN INDIVIDUAL VIOLATING THE IMPLIED CONSENT LAW:</h6>

            <ul className="p-0">
              <li>
                An individual in New York who refuses a chemical test requested
                by a law enforcement officer is in violation of the state's
                implied consent law. The law requires drivers to submit to a
                chemical test (such as a breathalyzer or blood test) if they are
                suspected of driving under the influence of alcohol or drugs.
              </li>

              <li>
                Here is an example scenario: John is pulled over by a police
                officer in New York after running a red light. The officer
                suspects that John may be under the influence of alcohol and
                requests that he submit to a breathalyzer test. John refuses to
                take the test, stating that he does not believe in it.
              </li>

              <li>
                As a result of John's refusal, he has violated New York's
                implied consent law. The law states that by operating a vehicle
                on the state's roads, drivers have already given their consent
                to take a chemical test if requested by law enforcement. John's
                refusal to take the test will result in automatic license
                suspension and potential fines.
              </li>

              <li>
                Additionally, John's refusal can be used as evidence against him
                in court if he is charged with a DUI. The prosecution can argue
                that John's refusal to take the test suggests that he was aware
                that he was under the influence and did not want to provide
                evidence of it.
              </li>

              <li>
                In summary, refusing a chemical test in New York is a violation
                of the state's implied consent law and can result in license
                suspension, fines, and negative implications in court.
              </li>
            </ul>
          </Container>
        </div>
        <div className="zero_tolerance pb-3">
          <Container>
            <h2
              className="same_alcohol_head"
              data-aos="zoom-in-right"
              data-aos-duration="2000"
            >
              THE ZERO TOLERANCE LAW
            </h2>

            <Row>
              <Col md={8} xs={12}>
                <h4 className="mt-4">WHAT IS THE "ZERO TOLERANCE" LAW?</h4>

                <p className="mb-4">
                  This law makes it illegal for a driver under age 21 to have
                  consumed any alcohol. A police officer may temporarily detain
                  you to request or administer a chemical test to determine your
                  Blood Alcohol Content (BAC). If your BAC is .02 to .07
                  percent, you will be notified to appear at a DMV hearing. If
                  the judge's finding supports the charge, the penalty is a 6-
                  month license suspension, a $125 civil penalty, and a $100
                  suspension termination fee. Each additional offense will
                  result in your license being revoked for at least one year or
                  until age 21, whichever is longer, plus a $125 civil penalty,
                  and a $100 license re- application fee.
                </p>
              </Col>

              <Col md={4} xs={12}>
                <div className="law_img">
                  <img
                    src={Law}
                    alt="Image"
                    data-aos="fade-left"
                    data-aos-duration="2000"
                  />
                </div>
              </Col>
            </Row>

            <p className="law_note">
              NOTE: If your BAC is .05 percent or greater, the police may charge
              you with driving while ability impaired (DWAI) or driving while
              intoxicated (DWI), and may prosecute your arrest in criminal
              court.
            </p>
            <p>
              § 1192-a. Operating a motor vehicle after having consumed alcohol;
              under the age of twenty-one; per se.
            </p>
            <p>
              If you're under 21 years old, you're not allowed to drive if
              you've had any alcohol. Specifically, you're considered to have
              had alcohol if your blood alcohol level is 0.02% to 0.07%. This
              level is found out by testing your blood, breath, urine, or
              saliva. This rule doesn't usually apply to those driving
              commercial vehicles like trucks. Also, being found driving with a
              BAC of 0.02% to 0.07% when under 21 is not the same as being
              convicted of a crime.
            </p>
          </Container>
        </div>

        <div className="law_effect">
          <Container>
            <h2
              className="same_alcohol_head"
              data-aos="zoom-in-right"
              data-aos-duration="2000"
            >
              THE ZERO TOLERANCE LAW WILL HAVE A DIRECT EFFECT ON DRIVERS UNDER
              THE AGE OF 21
            </h2>

            <h6 className="mt-3 mb-3">
              THE MAJOR PROVISIONS OF THE ZERO TOLERANCE LAW:
            </h6>

            <Row>
              <Col md={6} xs={12}>
                <ul className="p-0">
                  <li>
                    It is illegal for a person under the age of 21 to operate a
                    vehicle with a Blood Alcohol Content (BAC) of{" "}
                    <span> 0.2-0.7% </span>.
                  </li>

                  <li>
                    Violators must appear at a DMV hearing. If the youth is
                    found to have committed the offense, the license is
                    suspended for six months.
                  </li>

                  <li>
                    In addition to a license suspension, the violator must pay a
                    $125 civil penalty and a $100 suspension termination fee
                    before license reissuance.
                  </li>

                  <li>
                    If the youth refuses to submit to a chemical test, a DMV
                    hearing is scheduled. If the police officer had grounds for
                    the chemical test request, the penalties are:
                  </li>
                </ul>

                <p className="effect_para">
                  $300 civil penalty, one-year license revocation, and license
                  reapplication fee.
                </p>
              </Col>

              <Col md={6} xs={12}>
                <div className="penalties_div">
                  <h4 className="mb-4">
                    PENALTIES FOR SECOND OR SUBSEQUENT OFFENSES ARE:
                  </h4>

                  <Table responsive className="text-center" cellspacing="5">
                    <tbody>
                      <tr>
                        <td className="penalty_one">Alcohol Consumption</td>
                        <td className="penalty_two">
                          six month license revocation
                        </td>
                      </tr>
                      <tr>
                        <td className="penalty_one">
                          Refusal Of Chemical Test
                        </td>
                        <td className="penalty_two">
                          one year revocation of license loss until age 21
                          (whichever is longer)
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
                <ul className="p-0 mt-5">
                  <li>
                    Violation remains on youth’s record for three years or until
                    age 21, whichever is longer.
                  </li>

                  <li>
                    A finding that a youth aged 16-20 drove after consuming
                    alcohol is considered an administrative violation and not a
                    criminal conviction.
                  </li>
                </ul>

                <div
                  className="law_effect_img"
                  data-aos="zoom-in-left"
                  data-aos-duration="2000"
                >
                  <img src={LawEffect} alt="Image" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="provision_law pb-5">
          <Container>
            <h2
              className="same_alcohol_head"
              data-aos="zoom-in-right"
              data-aos-duration="2000"
            >
              EXAMPLES OF INDIVIDUALS IN VIOLATION OF THE ZERO TOLERANCE LAW AND
              THEIR REPERCUSSIONS
            </h2>

            <ul className="p-0">
              <li>
                Example 1 (First Offense): John is a 20-year-old college student
                who went out to celebrate his birthday with friends. After
                having a few drinks, he gets behind the wheel to drive home. A
                police officer pulls him over for a routine traffic stop and
                suspects that he may be under the influence of alcohol. The
                officer requests a chemical test, which shows that John's BAC is
                .06%. According to the Zero Tolerance law, this is a violation
                and John's driver's license will be suspended for six months. He
                will also be required to pay a $125 civil penalty and a $100
                suspension termination fee before he can have his license
                reissued.
              </li>

              <li>
                Example 2 (Chemical Test Refusal): Sara is a 17-year-old high
                school student who is pulled over by a police officer on her way
                home from a party. The officer suspects that she has been
                drinking and requests a chemical test. Sara refuses to take the
                test and is cited for violating the Zero Tolerance law.
                According to the law, Sara must appear at a DMV hearing where
                the officer will present evidence to support the chemical test
                request. If the officer's evidence is found to be valid, Sara
                will be required to pay a $300 civil penalty, have her license
                revoked for one year, and pay a license reapplication fee.
              </li>

              <li>
                Example 3 (Multiple Offenses): Michael is a 19-year-old college
                student who was caught violating the Zero Tolerance law for the
                second time. He was previously convicted of driving under the
                influence of alcohol when he was 18. This time, his BAC was
                .05%. Michael will be required to have his license revoked for
                at least one year or until he turns 21, whichever is longer. He
                will also be required to pay a $125 civil penalty and a $100
                license reapplication fee.
              </li>
            </ul>
          </Container>
        </div>
      </Container>
    </div>
  );
};
