import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Driving from "../../../../Images/module2/student_driving.png";
import DriverLogo from "../../../../Images/module2/studentlogo.png";
import Logo from "../../../../Images/logo.png";
import CarShop from "../../../../Images/module2/car-shop.png";
import School from "../../../../Images/module2/driving_school.png";
import Environment from "../../../../Images/module2/environment.png";
import Vehicles from "../../../../Images/module2/vehicles.png";
import Driver from "../../../../Images/module2/driver.png";
import Part from "../../../../Images/module2/part.png";
import Rules from "../../../../Images/module2/rules.png";
import Safety from "../../../../Images/module2/safety.png";
import DriveCar from "../../../../Images/module2/driving1.png";
import RadialChart from "./RadialChart";

import { H5PEditorUI, H5PPlayerUI } from "@lumieducation/h5p-react";
import { ContentService } from "../services/ContentService";

import AOS from "aos";
import "aos/dist/aos.css";

import "./ModuleTwo.css";

export const ModuleTwo = () => {
  const contentService = new ContentService("/h5p");
  const h5pPlayer = React.useRef(H5PPlayerUI);

  const [loading, setLoading] = useState(true);

  const onPlayerInitialized = () => {
    setLoading(false);
  };

  const [scroll, setScroll] = useState(false);

  const handleAllClicked = () => {
    console.log("All elements are clicked!");
    sessionStorage.setItem("HAS_H5P_ACTIVITY_SUBMIT", true);
  };

  useEffect(() => {
    AOS.init();
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  return (
    <div>
      <div className="module2_course same_width_module mt-md-4 mb-md-4">
        <Container>
          <div className="driving_section">
            <Container>
              <div className="module_content_head_position">
                <h1>
                  <span>driving within</span> the highway transportation system
                </h1>

                <p>module 2</p>
              </div>

              <img src={DriverLogo} className="student_logo" alt="Image" />
              <img src={Driving} className="drive_img" alt="Image" />
              {/* 
              <div className="main_logo">
                <img src={Logo} alt="Image" />
              </div> */}
            </Container>
          </div>

          <div>
            <Container>
              <div className="module_drive_content module_objective">
                <div
                  className="module_drive_head mb-3"
                  data-aos="zoom-in-right"
                  data-aos-duration="2000"
                >
                  <h3>objectives</h3>
                </div>
                <Row className="mb-5">
                  <Col md={6} xs={12}>
                    <ul className="mt-5 module_drive_list pl-md-3 mb-md-4 pl-2">
                      <li>Explain why driving is a social activity.</li>
                      <li>
                        Identify the three basic parts of the Highway
                        Transportation System (HTS) and their components.
                      </li>
                    </ul>
                  </Col>

                  <Col md={6} xs={12}>
                    <ul className="mt-md-5 pl-md-3 pl-2 module_drive_list mb-4">
                      <li>
                        Understand how parts of the HTS interrelate and state
                        whether each part is controllable or uncontrollable.
                      </li>
                      <li>
                        Describe the characteristics of a safe driver as
                        represented by the Arch of Driver Safety.
                      </li>
                    </ul>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>

          <div className="module_bg_overview">
            <Container>
              <div className="module_drive_content">
                <div className="overview_section_module3">
                  <div
                    className="module_drive_head mb-3"
                    data-aos="zoom-in-right"
                    data-aos-duration="2000"
                  >
                    <h3>overview</h3>
                  </div>

                  <Row>
                    <Col md={6} xs={12}>
                      <ul className="mt-5 pl-md-3 pl-2 module_overview_list mb-md-4">
                        <li>
                          Being a safe driver begins with an understanding of
                          the Vehicle and Traffic Law. Drivers cannot act as
                          isolated individuals on highways; drivers share the
                          road with other vehicles and pedestrians.
                        </li>

                        <li>
                          Improved licensing procedures, driver safety programs,
                          and strict legislation for chronic offenders have all
                          been designed to help the driver.
                        </li>

                        <li>
                          The purpose of the Highway Transportation System (HTS)
                          is to move goods and people efficiently, economically
                          and safely. Efforts are continually being made to
                          improve the System by modifying its individual
                          components:
                        </li>
                      </ul>

                      <div className="overview_tab ml-md-3">
                        <span
                          className="btn btn_overviews"
                          data-aos="zoom-in-right"
                          data-aos-duration="2000"
                        >
                          The environment
                        </span>
                        <span
                          className="btn btn_overviews"
                          data-aos="zoom-in-right"
                          data-aos-duration="2000"
                        >
                          The vehicle
                        </span>
                        <span
                          className="btn btn_overviews"
                          data-aos="zoom-in-right"
                          data-aos-duration="2000"
                        >
                          The driver
                        </span>
                      </div>
                    </Col>

                    <Col md={6} xs={12}>
                      <ul className="mt-md-5 mt-3 pl-md-3 pl-2 module_overview_list mb-4">
                        <li>
                          The environment has been improved by standardization
                          of traffic controls and laws, and by the construction
                          of limited access highways.
                        </li>

                        <li>
                          Vehicles have more safety devices, which lessen the
                          risk of injury and death.
                        </li>

                        <li>
                          HOWEVER, the driver remains the most important
                          component of the Highway Transportation System.
                        </li>
                      </ul>

                      <div
                        className="car_shop"
                        data-aos="fade-left"
                        data-aos-duration="2000"
                      >
                        <img src={CarShop} alt="Image" />
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Container>
          </div>

          <div className="module_drive_social mt-md-5 mt-5">
            <Container>
              <div className="module_drive_content">
                <div
                  className="module_drive_head mb-3"
                  data-aos="zoom-in-right"
                  data-aos-duration="2000"
                >
                  <h3>why is driving considered a social activity?</h3>
                </div>

                <Row>
                  <Col md={8} xs={12}>
                    <p className="mt-md-5 pl-md-4 pr-md-0 mt-5 pl-3 pr-2 para_top">
                      Any activity involving the interaction of two or more
                      people can be referred to as a social activity. These
                      activities are usually governed by written or unwritten
                      rules designed to protect everyone. Ice stacking is a
                      social activity in the entertainment system; driving is a
                      social activity in the highway transportation system.
                    </p>

                    <p className="mt-lg-3 drive_rules pt-lg-5 pl-md-4 pl-3 pr-2">
                      In driving, the following rules of behavior apply:
                    </p>
                  </Col>

                  <Col md={4} xs={12}>
                    <div
                      className="school_drive"
                      data-aos="fade-down-left"
                      data-aos-duration="2000"
                    >
                      <img src={School} alt="Image" />
                    </div>
                  </Col>
                </Row>

                <div className="drive_rules_apply pl-md-4 pr-md-4 mb-5">
                  <Row>
                    <Col md={4} xs={12}>
                      <div className="drive_rules_apply_content">
                        <h5>MAINTAIN A COMFORTABLE SPACE AROUND YOURSELF.</h5>

                        <p>(Don’t violate my space!)</p>
                      </div>
                    </Col>

                    <Col md={4} xs={12}>
                      <div className="drive_rules_apply_content">
                        <h5>AVOID COLLISIONS.</h5>

                        <p>(Don’t endanger me or damage my property!)</p>
                      </div>
                    </Col>

                    <Col md={4} xs={12}>
                      <div className="drive_rules_apply_content">
                        <h5>SIGNAL YOUR INTENTIONS.</h5>

                        <p>
                          (Let me know before you cross my path, especially if
                          you will interfere with my “right of way!”)
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Container>
          </div>

          <div className="module_parts">
            <Container>
              <div className="module_drive_content">
                <div
                  className="module_drive_head mb-3"
                  data-aos="zoom-in-right"
                  data-aos-duration="2000"
                >
                  <h3>THE PARTS OF THE HIGHWAY TRANSPORTATION SYSTEM (HTS)</h3>
                </div>

                <div className="mt-lg-0 mt-md-5 mt-0 pt-5 mb-3 pb-3">
                  <Row>
                    <Col md={4} xs={12}>
                      <div className="module_parts_box">
                        <div className="module_parts_box_img">
                          <img src={Environment} alt="Image" />
                        </div>

                        <div className="module_parts_content">
                          <h5>THE ENVIRONMENT</h5>
                          <p>
                            Composed of the road, the weather, other motor
                            vehicles, bicycles, pedestrians, surrounding
                            obstacles.
                          </p>
                        </div>
                      </div>
                    </Col>

                    <Col md={4} xs={12}>
                      <div className="module_parts_box">
                        <div className="module_parts_box_img">
                          <img src={Vehicles} alt="Image" />
                        </div>
                        <div className="module_parts_content">
                          <h5>VEHICLES</h5>
                          <p>Differ by type, age, and condition</p>
                        </div>
                      </div>
                    </Col>

                    <Col md={4} xs={12}>
                      <div className="module_parts_box">
                        <div className="module_parts_box_img">
                          <img src={Driver} alt="Image" />
                        </div>
                        <div className="module_parts_content">
                          <h5>DRIVERS</h5>
                          <p>
                            They vary in many ways, such as age, sex and fitness
                            levels.
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Container>
          </div>

          <div className="pt-4 pb-4 mb-3">
            <Container>
              <div className="module_control_part module_drive_content">
                <Row>
                  <Col lg={6} md={6} xs={12} style={{ paddingRight: "0" }}>
                    <div
                      className="module_control_part_img mt-md-3"
                      data-aos="zoom-in-right"
                      data-aos-duration="2000"
                    >
                      <img src={Part} alt="Image" />
                    </div>
                  </Col>

                  <Col lg={6} md={6} xs={12}>
                    <div className="module_control_part_content">
                      <h4>
                        WHICH PARTS OF THE HIGHWAY TRANSPORTATION SYSTEM CAN YOU
                        CONTROL?
                      </h4>

                      <ul className="p-0">
                        <li>
                          While no driver can control weather or the actions of
                          other drivers, each driver can control his or her
                          behavior.
                        </li>

                        <li>
                          Driving is based on trust and being able to predict
                          the actions of others.
                        </li>
                      </ul>
                    </div>
                  </Col>

                  <Col md={12} xs={12}>
                    <div className="module_control_part_content">
                      <ul className="p-0">
                        <li>
                          For example, a person trusts that their car will
                          perform as planned, that the road will be in good
                          shape, that the weather will be manageable and that
                          other drivers will drive safely.
                        </li>

                        <li>
                          The most important part of the Highway Transportation
                          System (HTS) is you, the driver. Drivers are
                          responsible for over 90 percent of all collisions,
                          while the other parts of the HTS are responsible for
                          the remainder. The driver is the least predictable to
                          change, but he is the only component that can react to
                          changing conditions.
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>

          <div className="module_safe_driver">
            <Container>
              <div className="module_drive_content">
                <div
                  className="module_drive_head mb-3"
                  data-aos="zoom-in-right"
                  data-aos-duration="2000"
                >
                  <h3>THE CHARACTERISTICS OF A SAFE DRIVER</h3>
                </div>

                <div
                  id="h5p_content_navigate"
                  className="radial-chart module_safe_driver_content"
                >
                  <h3 className="mb-0 mt-4 mt-md-0 module_safe_driver_content pt-5">
                    ARCH OF DRIVER SAFETY
                  </h3>
                  <h4 className="h5p-content-note">
                    Please click on the color blocks for more information
                  </h4>
                  <RadialChart onAllClicked={handleAllClicked} />
                  <img src={Safety} alt="Image" />
                </div>

                {/* <div className="box">
                  <iframe
                    src="https://h5p.org/h5p/embed/1223895"
                    frameborder="0"
                    allowfullscreen="allowfullscreen"
                    allow="geolocation *; microphone *; camera *; midi *; encrypted-media *"
                    title="Module 2 - Arch of Driver Safety"
                  ></iframe>
                  <script
                    src="https://h5p.org/sites/all/modules/h5p/library/js/h5p-resizer.js"
                    charset="UTF-8"
                  ></script>
                </div> */}

                {/*  <p className="safe_driver_para">
              This interactive activity will explain the characteristics of a safe driver. Click on the (+) icons to expand on each characteristic of the Arch of Driver Safety. Make sure you have reviewed all of the characteristics before proceeding.
              </p>

              <div className="pl-4 pr-3"> 

              <H5PPlayerUI
          ref={h5pPlayer}
          contentId={"3997066629"}
          loadContentCallback={
              contentService.getPlay
          }
          onInitialized={onPlayerInitialized}
          onxAPIStatement={(
              statement,
              context,
              event
          ) =>{
          
          }}
          />
          </div>  */}
                {/* <div className="module_safe_driver_content pt-5">
                  <h3 className="mb-0 mt-4 mt-md-0">ARCH OF DRIVER SAFETY</h3>

                  <div className="module_safe_driver_img text-center">
                    <img
                      src={Rules}
                      alt="Image"
                      data-aos="fade-up"
                      data-aos-duration="2000"
                    />
                    <div
                      className="module_safety_image"
                      data-aos="zoom-in-up"
                      data-aos-duration="2000"
                    >
                      <img src={Safety} alt="Image" />
                    </div>
                  </div>

                  <h3 className="mt-3 mb-3">WHICH PARTS CAN YOU CONTROL?</h3>

                  <Row>
                    <Col md={6} xs={12}>
                      <div className="driver_characteristics mb-4">
                        <h6>PHYSICAL FITNESS</h6>
                        <p>
                          The ability to use one's body to operate the vehicle.
                        </p>
                      </div>

                      <div className="driver_characteristics mb-4">
                        <h6>DRIVING SKILLS</h6>
                        <p>Developed through behind-the-wheel experience.</p>
                      </div>

                      <div className="driver_characteristics mb-4">
                        <h6>EMOTIONAL FITNESS</h6>
                        <p>
                          The ability to control the effects of one’s feelings
                          on driving performance.
                        </p>
                      </div>

                      <div className="driver_characteristics mb-4">
                        <h6>GOOD DRIVING HABITS</h6>
                        <p>
                          Combinations of skillful driving actions a driver has
                          participated in so frequently, that they have become
                          second nature, and are performed automatically.
                        </p>
                      </div>
                    </Col>

                    <Col md={6} xs={12}>
                      <div className="driver_characteristics mb-4">
                        <h6>COURTEOUS ATTITUDE</h6>
                        <p>
                          Being considerate of others and not driving in an
                          aggressive or reckless manner.
                        </p>
                      </div>

                      <div className="driver_characteristics mb-4">
                        <h6>KNOWLEDGE</h6>
                        <p>
                          Continually acquiring information one can use to
                          safely operate a vehicle.
                        </p>
                      </div>

                      <div className="driver_characteristics mb-4">
                        <h6>MENTAL FITNESS</h6>
                        <p>
                          The ability to properly react to the information one
                          receives from their senses.
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div> */}
              </div>
            </Container>
          </div>

          <div
            className="school_drive"
            data-aos="zoom-in"
            data-aos-duration="2000"
          >
            <img src={DriveCar} alt="Image" />
          </div>
        </Container>
      </div>
    </div>
  );
};
