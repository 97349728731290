import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { TopBar } from "../Screens";
import { Loader } from "../Containers/Loader";
import tawkTo from "tawkto-react";
import { TAWKTO_KEY, TAWKTO_PROPERTY_ID } from "../Utils/TawktoConstants";
import io from "socket.io-client";
import { baseURL } from "../Services/BaseApi";
import { socketConnectAction } from "../Redux/Actions";
import { useDispatch } from "react-redux";
import { parse, stringify } from "flatted";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const UserPrivateRoute = ({
  component: Component,
  disableChat,
  authUserTokens,
  ...rest
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!disableChat) {
      tawkTo(TAWKTO_PROPERTY_ID, TAWKTO_KEY);
      const tawk_API = window?.tawk_API || {};
      if (tawk_API) {
        tawk_API.visitor = {
          name: "visitor name",
          email: "visitor@email.com",
        };
      }
    }
  }, [disableChat]);

  useEffect(() => {
    window.Tawk_API = window.Tawk_API || {};
    // checks if chat is disabled and calls function
    if (window.Tawk_API && window.Tawk_API.onLoaded) {
      console.log(window);
      if (disableChat) {
        window.Tawk_API.hideWidget();
      } else {
        window.Tawk_API.showWidget();
      }
    }
  }, [disableChat]);

  useEffect(() => {
    socketSuccess();
  }, []);

  const socketSuccess = async () => {
    const token = sessionStorage.getItem("userAccessToken");
    const socket = io(baseURL, {
      auth: { token },
      secure: true,
      reconnect: true,
      rejectUnauthorized: false,
    });
    await dispatch(socketConnectAction(socket));
  };

  return (
    <Route
      {...rest}
      render={(props) =>
        authUserTokens ? (
          <>
            <div>
              <div>
                <TopBar></TopBar>
              </div>

              <Component {...props} />
            </div>
            <Loader />
            <ToastContainer />
          </>
        ) : (
          <Redirect to="/user/signin/:course_id" />
        )
      }
    />
  );
};
