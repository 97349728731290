import React from "react";
import { Accordion, Card, Col, Form } from "react-bootstrap";

export const ModuleCard = (props) => {
  // variables
  const { index, isModuleOpen, ischecked, isResume, tb, isLocked } = props;
  // Quiz related variables
  const { hasQuiz, isQuizCompleted } = props;
  // functions
  const { handleModuleNavigation, handleResume } = props;

  return (
    <>
      <Card
        key={`tobqw-${index}`}
        className="QuizCollapse"
        style={{ cursor: "pointer" }}
      >
        <Accordion.Toggle
          as={Card.Header}
          eventKey={`${index}`}
          onClick={(e) => {
            if (!isLocked) {
              if (isModuleOpen) {
                handleModuleNavigation(tb.id, tb.sub_module_id, tb.module_type);
              } else if (isResume) {
                handleResume();
              }
            }
          }}
          className="quizTabs pt-4 pb-4"
        >
          <h3>
            <Form.Group
              as={Col}
              className="samecheckbox nopadd accordian_maincontainer"
            >
              <div className="custom-control custom-checkbox text-left">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="customCheck1"
                  disabled
                  checked={ischecked}
                />
                <label className="custom-control-label" htmlFor="customCheck1">
                  {tb.name ? tb.name : "Module Name"}
                </label>
              </div>
            </Form.Group>
          </h3>
          {/* <span className="accordionDownIcon"></span> */}
        </Accordion.Toggle>
      </Card>
      {/* =========================== Quiz Module ======================== */}
      {hasQuiz ? (
        <Card key={`tobqwq-${index}`} className="QuizCollapse">
          <Accordion.Toggle
            as={Card.Header}
            eventKey={`${index}`}
            onClick={(e) => {
              if (isModuleOpen) {
                handleModuleNavigation(tb.id, tb.sub_module_id, tb.module_type);
              } else if (isResume) {
                handleResume();
              }
            }}
            className="quizTabs pt-4 pb-4"
          >
            <h3>
              <Form.Group
                as={Col}
                className="samecheckbox nopadd accordian_maincontainer"
              >
                <div className="custom-control custom-checkbox text-left">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="customCheck1"
                    disabled
                    checked={isQuizCompleted}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customCheck1"
                  >
                    {" "}
                    Quiz
                  </label>
                </div>
              </Form.Group>
            </h3>
            {/* <span className="accordionDownIcon"></span> */}
          </Accordion.Toggle>
        </Card>
      ) : null}
    </>
  );
};
