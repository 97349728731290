import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import "./CourseComplete.css";
import swal from "sweetalert";
import { Header } from "../Header/Header";
import { useDispatch, useSelector } from "react-redux";
import {
  getReceiptAction,
  socketMarkModuleComplete,
  socketMarkCourseComplete,
} from "../../../Redux/Actions";
import Form from "react-bootstrap/Form";
import { Modal } from "react-bootstrap";
import Emoji1 from "../../../Images/survey/poor.png";
import Emoji2 from "../../../Images/survey/fair.png";
import Emoji3 from "../../../Images/survey/good.png";
import Emoji4 from "../../../Images/survey/verygood.png";
import Emoji5 from "../../../Images/survey/excellent.png";
import {
  updateUserSurveyAction,
  socketDisconnectAction,
  logoutUserAction,
  userPaymentStatusCheckAction,
} from "../../../Redux/Actions";

const scaleList = [
  {
    icon: Emoji1,
    label: "Poor",
    value: 1,
  },
  {
    icon: Emoji2,
    label: "Fair",
    value: 2,
  },
  {
    icon: Emoji3,
    label: "Good",
    value: 3,
  },
  {
    icon: Emoji4,
    label: "Very Good",
    value: 4,
  },
  {
    icon: Emoji5,
    label: "Excellent",
    value: 5,
  },
];

const CourseComplete = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  let params = useParams();
  const socket = useSelector((state) => {
    return state.userSocketioReducer?.socketConnectSuccess?.socketInstance;
  });
  const module_list = useSelector((state) => {
    return state.tableofContentReducer?.tableofContentDetailList?.data;
  });

  const [courseShow, setCourseShow] = useState(true);
  const handleCourseClose = () => setCourseShow(false);
  const handleCourseShow = () => setCourseShow(true);
  const [surveyResponse, setSurveyResponse] = useState({
    convenience_of_location_choice: false,
    convenience_of_time_choice: false,
    cost_choice: false,
    availability_choice: false,
    other_choice: false,
  });

  const handleSurveyInput = (e) => {
    let newResponse = { ...surveyResponse };
    const name = e.target.name;
    const value = e.target.value;
    if (
      name === "convenience_of_location_choice" ||
      name === "convenience_of_time_choice" ||
      name === "cost_choice" ||
      name === "availability_choice" ||
      name === "other_choice"
    ) {
      newResponse[name] = e.target.checked;
    } else {
      newResponse[name] = value;
    }
    console.log(newResponse);
    setSurveyResponse(newResponse);
  };

  useEffect(() => {
    paymentStatusCheck();
  }, []);

  const checkDisable = (forValidation = false) => {
    const list = [
      "convenience_of_location_choice",
      "convenience_of_time_choice",
      "cost_choice",
      "availability_choice",
      "other_choice",
    ];

    let count = 0;
    list.forEach((key, i) => {
      if (surveyResponse[key] === true) {
        count += 1;
      }
    });

    return forValidation ? count > 0 : count >= 3;
  };

  const paymentStatusCheck = async () => {
    var paymentResponse = await dispatch(
      userPaymentStatusCheckAction(params?.course_id)
    );
    paymentResponse = paymentResponse?.payload;
    if (paymentResponse?.data?.data?.is_paid) {
    } else {
      swal({
        title: "Error!",
        text: "Your payment is due.",
        icon: "error",
        timer: 5000,
      });

      dispatch(logoutUserAction());
      history.push(`/user/signin/${params.course_id}`);
    }
  };

  const validate = () => {
    if (!surveyResponse.delivery_satisfaction_rating) return false;
    if (!surveyResponse.participant_interest_rating) return false;
    if (!surveyResponse.information_usefulness_rating) return false;

    if (!checkDisable(true)) return false;
    return true;
  };

  const handleSurveyUpdate = async (e) => {
    e.preventDefault();
    try {
      const data = {
        course_id: params.course_id,
        ...surveyResponse,
      };

      if (!validate()) {
        swal({
          title: "Please fill in all responses!",
          text: "",
          icon: "error",
          timer: 3000,
        });
        return;
      }

      let response = await dispatch(updateUserSurveyAction(data));
      response = response?.payload;
      if (response) {
        if (response?.data) {
          if (response?.data?.code == 200) {
            // if (module_list) {
            //   var list = [...module_list];

            //   const currentModuleQuizes = list.at(-1);

            //   dispatch(
            //     socketMarkModuleComplete(socket, currentModuleQuizes?.id)
            //   );
            // }

            dispatch(socketMarkCourseComplete(socket, params.course_id));

            // swal("Thanks for sharing your feedback!", {
            //   // title: "Thanks for sharing your feedback!",
            //   // text: response.data.message,
            //   icon: "success",
            //   timer: 3000,
            //   button: false,
            // });
            handleCourseClose();
            // setTimeout(async function () {
            //   history.push(`/user/CourseCompleteCongratulations/${params.course_id}`);
            // }, 2000);
          } else {
            // error message
            swal({
              title: "Error!",
              text: response.data.message,
              icon: "error",
              timer: 6000,
            });
          }
        }
      }
    } catch (err) {
      if (err?.response?.data?.code === 401) {
        swal({
          title: "Error!",
          text: err.response.data.err,
          icon: "error",
          timer: 5000,
        });
        dispatch(socketDisconnectAction(socket));
        dispatch(logoutUserAction());
        history.push(`/user/signin/${params.course_id}`);
      }
    }
  };

  return (
    <div>
      {/* <Header></Header> */}

      <div className="mainSection marginmainTop mt-3 pb-4">
        {!courseShow && ( <Container>
          <Row>
            <Col
              md={12}
              className="text-center payment_container success_content"
            >
              <h5>Congratulations!!!</h5>
              <h4> You have successfully completed the NY 5-Hour Pre-Licensing Course!</h4>
              <p className="text-muted">
              A confirmation email has been sent to your registered email for your records.
            </p>
              {/* <p>Order ID : 000-12-922-0999 | Transaction ID: 123545</p> */}
              <Button
              // variant="success"
              variant="primary"
              className="btnSign mt-4"
              onClick={() => history.push(`/user/dashboard/${params.course_id}`)}
            >
              Back to Dashboard
            </Button>
              {/* <Button
                variant="primary"
                className="btnSign mt-4"
                onClick={handleCourseShow}
              >
                Please share Your Feedback
              </Button> */}
            </Col>
          </Row>
        </Container>
        )}
        <Modal
          className="thankyou_modal course_complete survey_modal"
          show={courseShow}
          onHide={() => {}}
        >
          <Modal.Header>
            <Modal.Title>
            Before finishing, please complete the mandatory course completion survey. This step is required to finalize your certification and ensure successful submission to the DMV.
            </Modal.Title>
          </Modal.Header>
          <Form
            noValidate
            onSubmit={handleSurveyUpdate}
            onChange={handleSurveyInput}
          >
            <Modal.Body className="pl-0 pr-0">
              <Row>
                <Col md={12}>
                  <h5>
                    1) On a scale of 1 to 5, how satisfied are you with the
                    method of delivery of this course?
                  </h5>
                  <div className="">
                    {scaleList.map((scale, i) => (
                      <div className="form-check form-check-inline" key={i}>
                        <label
                          className="radio-img"
                          htmlFor={`exampleRadio${i}`}
                        >
                          <input
                            type="radio"
                            id={`exampleRadio${i}`}
                            name="delivery_satisfaction_rating"
                            value={scale.value}
                          />
                          <div className="image survey">
                            <img src={scale.icon} />
                            <span>{scale.label} </span>
                          </div>
                        </label>
                      </div>
                    ))}
                  </div>
                </Col>
              </Row>

              <Row className="mt-3">
                <Col md={12}>
                  <h5>
                    2) On a scale of 1 to 5, how interesting did you find this
                    course?
                  </h5>
                  <div className="">
                    {scaleList.map((scale, i) => (
                      <div className="form-check form-check-inline" key={i}>
                        <label className="radio-img" htmlFor={`example${i}`}>
                          <input
                            type="radio"
                            id={`example${i}`}
                            name="participant_interest_rating"
                            value={scale.value}
                          />
                          <div className="image survey">
                            <img src={scale.icon} />
                            <span>{scale.label} </span>
                          </div>
                        </label>
                      </div>
                    ))}
                  </div>
                </Col>
              </Row>

              <Row className="mt-3">
                <Col md={12}>
                  <h5>
                    3) On a scale of 1 to 5, how useful did you find the
                    information in this course?
                  </h5>
                  <div className="">
                    {scaleList.map((scale, i) => (
                      <div className="form-check form-check-inline" key={i}>
                        <label className="radio-img" htmlFor={`info${i}`}>
                          <input
                            type="radio"
                            id={`info${i}`}
                            name="information_usefulness_rating"
                            value={scale.value}
                          />
                          <div className="image survey">
                            <img src={scale.icon} />
                            <span>{scale.label} </span>
                          </div>
                        </label>
                      </div>
                    ))}
                  </div>
                </Col>
              </Row>

              <Row className="mt-3">
                <Col md={12}>
                  <h5>
                    4) What factors most contributed to your choice of taking
                    the 5 Hour Pre-Licensing Course online? (choose upto 3
                    choices)
                  </h5>
                  <Form.Group
                    as={Col}
                    md="12"
                    sm="12"
                    xs="12"
                    className="w-100"
                  >
                    <div className="custom-control custom-checkbox pl-2">
                      <input
                        type="checkbox"
                        className="custom-control-input headall_text"
                        id="customCheck20"
                        name="convenience_of_location_choice"
                        checked={surveyResponse.convenience_of_location_choice}
                        disabled={
                          checkDisable() &&
                          !surveyResponse.convenience_of_location_choice
                        }
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customCheck20"
                      >
                        Convenience of online access
                      </label>
                    </div>
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md="12"
                    sm="12"
                    xs="12"
                    className="w-100"
                  >
                    <div className="custom-control custom-checkbox pl-2">
                      <input
                        type="checkbox"
                        className="custom-control-input headall_text"
                        id="customCheck21"
                        name="convenience_of_time_choice"
                        checked={surveyResponse.convenience_of_time_choice}
                        disabled={
                          checkDisable() &&
                          !surveyResponse.convenience_of_time_choice
                        }
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customCheck21"
                      >
                        Convenience of time
                      </label>
                    </div>
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md="12"
                    sm="12"
                    xs="12"
                    className="w-100"
                  >
                    <div className="custom-control custom-checkbox pl-2">
                      <input
                        type="checkbox"
                        className="custom-control-input headall_text"
                        id="customCheck22"
                        name="cost_choice"
                        checked={surveyResponse.cost_choice}
                        disabled={checkDisable() && !surveyResponse.cost_choice}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customCheck22"
                      >
                        Cost
                      </label>
                    </div>
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md="12"
                    sm="12"
                    xs="12"
                    className="w-100"
                  >
                    <div className="custom-control custom-checkbox pl-2">
                      <input
                        type="checkbox"
                        className="custom-control-input headall_text"
                        id="customCheck23"
                        name="availability_choice"
                        checked={surveyResponse.availability_choice}
                        disabled={
                          checkDisable() && !surveyResponse.availability_choice
                        }
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customCheck23"
                      >
                        Limited/ no availability of classroom course
                      </label>
                    </div>
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md="12"
                    sm="12"
                    xs="12"
                    className="w-100"
                  >
                    <div className="custom-control custom-checkbox pl-2">
                      <input
                        type="checkbox"
                        className="custom-control-input headall_text"
                        id="customCheck24"
                        name="other_choice"
                        checked={surveyResponse.other_choice}
                        disabled={
                          checkDisable() && !surveyResponse.other_choice
                        }
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customCheck24"
                      >
                        Other
                      </label>
                      {surveyResponse?.other_choice && (
                        <div>
                          <div className="mt-3 mb-3">
                            <textarea
                              name="other_choice_reason"
                              className="form-control"
                              value={surveyResponse?.other_choice_reason}
                              rows="3"
                            ></textarea>
                          </div>
                        </div>
                      )}
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              {/* <Row className="mt-3">
                      <Col md={12}>
                        <h5>
                        Was the whole process of taking course easy for you?
                        </h5>
                        <div className="rating star-rating">
                            <input type="radio" name="ease_of_use_rating" value="1" className="fa fa-star-o mr-3 checked"  /><i></i>
                            <input type="radio" name="ease_of_use_rating" value="2"/><i></i>
                            <input type="radio" name="ease_of_use_rating" value="3"/><i></i>
                            <input type="radio" name="ease_of_use_rating" value="4"/><i></i>
                            <input type="radio" name="ease_of_use_rating" value="5"/><i></i>
                        </div>
                      </Col>
                    </Row>

                    <Row className="mt-3">
                      <Col md={12}>
                        <h5>
                        Were you able to choose the location of your choice?
                        </h5>
                        <div className="starno_rate">
                            <input type="radio" name="convenience_of_location_choice" value={true} className="mr-3 checked"  id='convenience_of_location_choice' /><i></i>
                            <label className="custom-radio" htmlFor='convenience_of_location_choice' >True </label>
                            <input type="radio" name="convenience_of_location_choice" value={false} id='convenience_of_location_choice' className="ml-3"/><i></i>
                            <label className="custom-radio pl-2" htmlFor='convenience_of_location_choice' >False </label>
                        </div>
                      </Col>
                    </Row>

                    <Row className="mt-3">
                      <Col md={12}>
                        <h5>
                        Was the time duration and availability of the course convenient for you?
                        </h5>
                        <div className="rating">
                            <input type="radio" name="convenience_of_time_choice" value={true} className=" mr-3 checked"  /><i></i>
                            <label className="custom-radio" htmlFor='convenience_of_time_choice' >True </label>
                            <input type="radio" name="convenience_of_time_choice" className="ml-3" value={false}/><i></i>
                            <label className="custom-radio pl-2" htmlFor='convenience_of_time_choice' >False </label>
                        </div>
                      </Col>
                    </Row>

                    <Row className="mt-3">
                      <Col md={12}>
                        <h5>
                        Did you find the cost of the course worth paying?
                        </h5>
                        <div className="rating">
                            <input type="radio" name="cost_choice" value={true} className="mr-3 checked"  /><i></i>
                            <label className="custom-radio" htmlFor='cost_choice' >True </label>
                            <input type="radio" name="cost_choice" className="ml-3" value={false}/><i></i>
                            <label className="custom-radio pl-2" htmlFor='cost_choice' >False </label>
                        </div>
                      </Col>
                    </Row> */}

              <Row className="mt-3">
                <Col md={12}>
                  <h5>
                    5) On a scale of 1 to 5, how would you rate the ease of use
                    of this course?
                  </h5>
                  <div className="">
                    {scaleList.map((scale, i) => (
                      <div className="form-check form-check-inline" key={i}>
                        <label className="radio-img" htmlFor={`ease${i}`}>
                          <input
                            type="radio"
                            id={`ease${i}`}
                            name="ease_use_rating"
                            value={scale.value}
                          />
                          <div className="image survey">
                            <img src={scale.icon} />
                            <span>{scale.label} </span>
                          </div>
                        </label>
                      </div>
                    ))}
                  </div>
                </Col>
              </Row>

              <Form.Group className="mt-3">
                <label>Additional Feedback</label>

                {/* <Form.Label>Additional Feedback</Form.Label> */}
                <Form.Control
                  as="textarea"
                  name="additional_feedback"
                  rows={3}
                  value={surveyResponse?.additional_feedback}
                />
              </Form.Group>

              <Button
                variant="primary"
                className="btnSign btn_modal"
                type="submit"
              >
                Submit
              </Button>
            </Modal.Body>
          </Form>
        </Modal>
      </div>
    </div>
  );
};

export default CourseComplete;
