import React, { useEffect, useRef, useState } from "react";
import * as echarts from "echarts";
import swal from "sweetalert";
import sessionStorage from "redux-persist/es/storage/session";
import "./ModuleTwo.css";
import icon1Image from "../../../../Images/module2/icons/icon-1.png";
import icon2Image from "../../../../Images/module2/icons/icon-2.png";
import icon3Image from "../../../../Images/module2/icons/icon-3.png";
import icon4Image from "../../../../Images/module2/icons/icon-4.png";
import icon5Image from "../../../../Images/module2/icons/icon-5.png";
import icon6Image from "../../../../Images/module2/icons/icon-6.png";
import icon7Image from "../../../../Images/module2/icons/icon-7.png";

const PieChart = ({ onAllClicked }) => {
  useEffect(() => {
    sessionStorage.setItem("HAS_H5P_ACTIVITY", true);
    sessionStorage.setItem("HAS_H5P_ACTIVITY_SUBMIT", false);
  }, []);

  const [clickState, setClickState] = useState({
    "PHYSICAL FITNESS": false,
    "DRIVING SKILLS": false,
    "EMOTIONAL FITNESS": false,
    "GOOD DRIVING HABITS": false,
    "COURTEOUS ATTITUDE": false,
    KNOWLEDGE: false,
    "MENTAL FITNESS": false,
  });
  const chartRef = useRef(null);

  useEffect(() => {
    if (chartRef.current) {
      const myChart = echarts.init(chartRef.current, null, { renderer: "svg" });
      myChart.setOption(option);

      myChart.on("click", function (params) {
        console.log("params.data ===== ", params.data);
        const clickedName = params.data.name;
        setClickState((prevState) => ({
          ...prevState,
          [clickedName]: true,
        }));

        if (params.data && params.data.description) {
          swal({
            text: params.data.description,
            icon: params.data.iconUrl
          });
        }
      });

      return () => {
        myChart.dispose();
      };
    }
  }, []);

  useEffect(() => {
    const allClicked = Object.values(clickState).every((value) => value);
    if (allClicked) {
      onAllClicked();
    }
  }, [clickState, onAllClicked]);

  const data = [
    {
      value: 200,
      name: "PHYSICAL FITNESS",
      itemStyle: { color: "rgb(23,72,145)" },
      iconUrl: icon1Image,
      description: "The ability to use one's body to operate the vehicle.",
    },
    {
      value: 200,
      name: "DRIVING SKILLS",
      itemStyle: { color: "rgb(243,108,33)" },
      iconUrl: icon2Image,
      description: "Developed through behind-the-wheel experience.",
    },
    {
      value: 200,
      name: "EMOTIONAL FITNESS",
      itemStyle: { color: "rgb(131,155,93)" },
      iconUrl: icon3Image,
      description:
        "The ability to control the effects of one’s feelings on driving performance.",
    },
    {
      value: 200,
      name: "GOOD DRIVING HABITS",
      itemStyle: { color: "rgb(88,46,98)" },
      iconUrl: icon4Image,
      description:
        "Combinations of skillful driving actions a driver has participated in so frequently, that they have become second nature, and are performed automatically.",
    },
    {
      value: 200,
      name: "COURTEOUS ATTITUDE",
      itemStyle: { color: "rgb(248,195,17)" },
      iconUrl: icon5Image,
      description:
        "Being considerate of others and not driving in an aggressive or reckless manner.",
    },
    {
      value: 200,
      name: "KNOWLEDGE",
      itemStyle: { color: "rgb(36,78,109)" },
      iconUrl: icon6Image,
      description:
        "Continually acquiring information one can use to safely operate a vehicle.",
    },
    {
      value: 200,
      name: "MENTAL FITNESS",
      itemStyle: { color: "rgb(226,57,59)" },
      iconUrl: icon7Image,
      description:
        "The ability to properly react to the information one receives from their senses.",
    },
  ];

  const option = {
    series: [
      {
        name: "Access From",
        type: "pie",
        radius: ["40%", "70%"],

        startAngle: 180,
        endAngle: 360,
        data: data,
        label: {
          formatter: function (params) {
            const words = params.name
              .split(" ")
              .filter((word) => word.trim() !== "");
            return words.join("\n");
          },
          padding: 4,
          position: "inside",
          fontWeight: "1000",
          fontSize: "16px",
        },
      },
    ],
  };

  return <div ref={chartRef} className="svg-div" style={{ height: "1000px"}} />;
};

export default PieChart;
