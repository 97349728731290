import axios from "axios";
import { baseURL } from "./BaseApi";

const getHeaders = () => {
    var accessToken;
    if (!sessionStorage.getItem("userAccessToken")) {
      accessToken = localStorage.getItem("accessToken");
    } else {
      accessToken = sessionStorage.getItem("userAccessToken");
    }
    return {
      headers: {
        authorization: `Bearer ${accessToken}`,
        Accept: "application/json",
      },
    };
  };
  
export const userOtpService = {
  sendOtp: (detail) => {
    return axios.post(
      baseURL + `/api/user/otp/sendotp`,
      detail,
      getHeaders()
    );
  },

  verifyOtp: (detail) => {
    return axios.post(
      baseURL + `/api/user/otp/verifyotp`,
      detail,
      getHeaders()
    );
  },
}

