import { loadingAction } from "../LoadingAction";
import { userMarkCourseModuleCompleteService } from "../../../Services/UserSocketioService";
import {
  SOCKET_EMIT_START_STOP_MODULE_SUCCESS,
  SOCKET_TIMER_SUCCESS,
  SOCKET_ERROR,
  SOCKET_OPEN_CLOSE_SUCCESS,
  SOCKET_CLOSE_RESPONSE_SUCCESS,
  SOCKET_TIMER_UP,
  SOCKET_NON_INTERACTIVE_START,
  MARK_COURSE_MODULE_COMPLETE,
  SOCKET_CONNECT_SUCCESS,
} from "../ActionTypes/UserSocketioServiceType";
import {
  MODULE_TIMER,
  NON_INTERACTIVE_START,
  RESET_ALL_MODULES,
  RESET_SINGLE_MODULE,
  START_MODULE,
  STOP_MODULE,
  TIMER_UP,
} from "./SocketEvents";

export const socketConnectAction = (socket) => {
  return (dispatch) => {
    socket.on("connect", () => {
      dispatch({
        type: SOCKET_CONNECT_SUCCESS,
        payload: { socketInstance: socket },
      });
    });
  };
};

export const socketDisconnectAction = (socket) => {
  if (socket) {
    socket.disconnect?.();
    return (dispatch) => {
      socket?.on?.("disconnect", (reason) => {
        dispatch({
          type: SOCKET_OPEN_CLOSE_SUCCESS,
          payload: { connected: false },
        });
        dispatch({
          type: SOCKET_CLOSE_RESPONSE_SUCCESS,
          payload: reason,
        });
      });
    };
  } else {
    return false;
  }
};

export const socketEmitStartModuleAction = (socket, module_id, callback) => {
  socket?.emit?.(START_MODULE, { module_id: module_id }); // event emit
  return (dispatch) => {
    callback && callback();
    socket.on(START_MODULE, (response) => {
      dispatch({
        type: SOCKET_EMIT_START_STOP_MODULE_SUCCESS,
        payload: response,
      });
    });

    socket.on(MODULE_TIMER, (response) => {
      dispatch({
        type: SOCKET_TIMER_SUCCESS,
        payload: response,
      });
    });

    socket.on(TIMER_UP, (response) => {
      dispatch({
        type: SOCKET_TIMER_UP,
        payload: response,
      });
    });
  };
};

export const socketEmitStopModuleAction = (socket, module_id, callback) => {
  socket?.emit?.("STOP_MODULE", { module_id: module_id });
  return (dispatch) => {
    socket?.on?.("STOP_MODULE", (response) => {
      callback && callback();
      console.log(response?.message);
      if (response.message == "Module stoped") {
        dispatch({
          type: SOCKET_TIMER_UP,
          payload: {},
        });
        //     dispatch({
        //         type: SOCKET_TIMER_SUCCESS,
        //         payload: {}
        //     });
      }
    });
  };
};

export const socketEmitNonInteractiveTimeAction = (module_id) => {
  // socket.emit(NON_INTERACTIVE_START, {module_id: module_id})
  return (dispatch) => {
    // socket.on(NON_INTERACTIVE_START, (response)=>{
    //     dispatch({
    //         type: SOCKET_NON_INTERACTIVE_START,
    //         payload: response
    //     });
    // })
  };
};

//  Socket Error catch

export const socketErrorAction = (socket) => {
  return (dispatch) => {
    socket?.on("error", (response) => {
      dispatch({
        type: SOCKET_ERROR,
        payload: response,
      });
    });
  };
};

export const socketMarkModuleComplete = (socket, module_id, callback) => {
  socket?.emit?.("MODULE_COMPLETE", { module_id: module_id });
  return (dispatch) => {
    socket?.on("MODULE_COMPLETE", (response) => {
      console.log(response);
      if (response.message == "Module marked as complete") {
        callback && callback();
      }
      if (response.message == "Module stoped") {
        dispatch({
          type: SOCKET_TIMER_UP,
          payload: {},
        });
        //     dispatch({
        //         type: SOCKET_TIMER_SUCCESS,
        //         payload: {}
        //     });
      }
    });
  };
};

export const socketMarkCourseComplete = (socket, course_id, callback) => {
  socket?.emit?.("COURSE_COMPLETE", { course_id: course_id });
  return (dispatch) => {
    socket?.on("COURSE_COMPLETE", (response) => {
      console.log(response);
      if (response.message === "Course marked as complete") {
        callback && callback();
      }
      if (response.message === "Course stopped") {
        dispatch({
          type: SOCKET_TIMER_UP,
          payload: {},
        });
        // Additional dispatch actions related to course completion can be added here
      }
    });
  };
};

export const socketResetCourse = (socket, data, callback) => {
  socket?.emit?.(RESET_ALL_MODULES, {
    course_id: data.course_id,
    module_id: data.module_id,
  });
  return (dispatch) => {
    socket?.on(RESET_ALL_MODULES, (response) => {
      console.log("response");
      console.log(response);
      callback && callback();
      if (response.message == "Reset time of all modules and course") {
        dispatch({
          type: SOCKET_TIMER_UP,
          payload: {},
        });
      }
    });
  };
};

export const socketResetModule = (socket, data, callback) => {
  socket?.emit?.(RESET_SINGLE_MODULE, {
    course_id: data.course_id,
    module_id: data.module_id,
  });
  return (dispatch) => {
    socket?.on?.(RESET_SINGLE_MODULE, (response) => {
      console.log("response");
      console.log(response);
      callback && callback();
      if (response.message == "Reset time of single module") {
        dispatch({
          type: SOCKET_TIMER_UP,
          payload: {},
        });
        dispatch({
          type: SOCKET_TIMER_SUCCESS,
          payload: {},
        });
      }
    });
  };
};

export const socketLockCourse = (socket, course_id, callback) => {
  socket?.emit?.("LOCKED_COURSE", { course_id });
  return (dispatch) => {
    socket?.on?.("LOCKED_COURSE", (response) => {
      console.log("response");
      console.log(response);
      callback && callback();
      //   if (response.message == "Complete course locked") {
      //     dispatch({
      //       type: SOCKET_TIMER_UP,
      //       payload: {},
      //     });
      //     dispatch({
      //       type: SOCKET_TIMER_SUCCESS,
      //       payload: {},
      //     });
      //   }
    });
  };
};
