import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LOGO_Horizontal from "../../../../Images/LOGO_Horizontal.png";
import Overview from "../../../../Images/module6/overview.png";
import Objective from "../../../../Images/module6/objective.png";
import Driving from "../../../../Images/module6/driving.png";
import Hammer from "../../../../Images/module6/hammer.png";
import Action from "../../../../Images/module6/action.png";
import Expression1 from "../../../../Images/module6/expression/1.png";
import Expression2 from "../../../../Images/module6/expression/2.png";
import Expression3 from "../../../../Images/module6/expression/3.png";
import Expression4 from "../../../../Images/module6/expression/4.png";
import Expression5 from "../../../../Images/module6/expression/5.png";
import Expression6 from "../../../../Images/module6/expression/6.png";
import Expression7 from "../../../../Images/module6/expression/7.png";
import Expression8 from "../../../../Images/module6/expression/8.png";
import Expression9 from "../../../../Images/module6/expression/9.png";
import Expression10 from "../../../../Images/module6/expression/10.png";
import Expression11 from "../../../../Images/module6/expression/11.png";
import Expression12 from "../../../../Images/module6/expression/12.png";
import Drugs from "../../../../Images/module6/drugs.png";
import Graduated from "../../../../Images/module6/graduated.png";
import Factor from "../../../../Images/module6/factor.png";
import License from "../../../../Images/module6/license.png";
import SchoolDriving from "../../../../Images/module6/driving_school.png";
import Attitude from "../../../../Images/module6/attitude.png";
import JuniorDriver from "../../../../Images/module6/junior_driver.png";
import DriverLicense from "../../../../Images/module6/m616.jpg";
import TrafficViolation from "../../../../Images/module6/m617.png";
import TrafficViolationFooter from "../../../../Images/module6/m618.jpg";
import GraduatedLaw from "../../../../Images/module6/graduatedlaw-without-logo.mp4";
import AOS from "aos";
import "aos/dist/aos.css";

import "./ModuleSix.css";

import { H5PEditorUI, H5PPlayerUI } from "@lumieducation/h5p-react";
import { ContentService } from "../services/ContentService";

export const ModuleSixPart1 = () => {
  const [scroll, setScroll] = useState(false);

  const contentService = new ContentService("/h5p");
  const h5pPlayer = React.useRef(H5PPlayerUI);

  const [loading, setLoading] = useState(true);
  const [active, setActive] = useState(false);

  const [selectedAnswer, setSelectedAnswer] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);
  const correctAnswer = "A";

  const question = "How do feelings and attitude differ?";

  const answerChoices = [
    {
      label: "A",
      value: "A",
      content:
        "Feelings are constantly changing, while attitudes tend to be constant.",
    },
    {
      label: "B",
      value: "B",
      content: "Feelings and attitudes are the same thing.",
    },
    {
      label: "C",
      value: "C",
      content:
        "Attitudes cannot influence the way you act, while feelings can.",
    },
    {
      label: "D",
      value: "D",
      content: "An 'I don't care' attitude can help you become a safe driver.",
    },
  ];

  const handleAnswerSelect = (answer) => {
    setActive(true);
    setSelectedAnswer(answer);
  };

  const handleSubmit = () => {
    if (!isSubmitted) {
      setIsSubmitted(true);
      sessionStorage.setItem("HAS_H5P_ACTIVITY_SUBMIT", true);
      if (selectedAnswer === correctAnswer) {
        setIsCorrect(true);
        console.log("Correct answer! Quiz submitted.");
      } else {
        setIsCorrect(false);
        console.log("Incorrect answer. Quiz submitted.");
      }
    }
  };

  const handleOptionClick = (value) => {
    if (!isSubmitted) {
      setSelectedAnswer(value);
    }
  };

  const onPlayerInitialized = () => {
    setLoading(false);
  };

  useEffect(() => {
    AOS.init();
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  useEffect(() => {
  //   sessionStorage.setItem("HAS_VIDEO", true);
    sessionStorage.setItem("HAS_H5P_ACTIVITY", true);
    sessionStorage.setItem("HAS_H5P_ACTIVITY_SUBMIT", false);
  }, []);

  // var video = document.getElementById("video");
  // useEffect(() => {
  //   if (video) {
  //     var supposedCurrentTime = 0;
  //     video.addEventListener("timeupdate", function () {
  //       if (!video.seeking) {
  //         supposedCurrentTime = video.currentTime;
  //       }
  //     });
  //     // prevent user from seeking
  //     video.addEventListener("seeking", function () {
  //       // guard agains infinite recursion:
  //       // user seeks, seeking is fired, currentTime is modified, seeking is fired, current time is modified, ....
  //       var delta = video.currentTime - supposedCurrentTime;
  //       if (Math.abs(delta) > 0.01) {
  //         console.log("Seeking is disabled");
  //         video.currentTime = supposedCurrentTime;
  //       }
  //     });
  //     // delete the following event handler if rewind is not required
  //     video.addEventListener("ended", function () {
  //       // reset state in order to allow for rewind
  //       supposedCurrentTime = 0;
  //     });
  //   }
  // }, [video]);

  return (
    <div className="module6_course module_font same_width_module mb-md-4">
      <Container>
        <div className="module_risk">
          <Container>
            <div
              className="module_logo"
              data-aos="fade-down"
              data-aos-duration="2000"
            >
              <img src={LOGO_Horizontal} alt="Image" />
            </div>
            <div
              className="risk_content"
              data-aos="zoom-in"
              data-aos-duration="2000"
            >
              <p className="mb-1">Module 6 - Part 1</p>
              <h1 className="mb-0">
                FEELINGS, <br />
                <span>
                  {" "}
                  ATTITUDES & <br />{" "}
                </span>
                TAKING RISKS
              </h1>
            </div>
          </Container>
        </div>

        <div className="module_risk_overview">
          <Container>
            <Row>
              <Col lg={12} md={12} xs={12}>
                <h2
                  className="module_risk_same mb-4"
                  data-aos="zoom-in"
                  data-aos-duration="2000"
                >
                  OVERVIEW
                  <span className="border_head"></span>
                </h2>

                <ul className="p-0">
                  <Row>
                    <Col lg={6} md={6} xs={6}>
                      <li>
                        Most of the risks we face in the Highway Transportation
                        System (HTS) are due to the actions of drivers. These
                        actions relate mostly to the state of mind of each
                        individual driver. Perceptions, values, life pressures,
                        life styles, and other factors can influence our
                        decision-making and actions when we use streets and
                        highways.
                      </li>
                    </Col>
                    <Col lg={6} md={6} xs={6}>
                      <div className="module_risk_overview_img">
                        <img
                          src={Overview}
                          data-aos="zoom-in-left"
                          data-aos-duration="2000"
                          alt="Image"
                        />
                      </div>
                    </Col>
                  </Row>

                  <li>
                    Our driving behaviour is influenced by feelings and
                    attitudes. We need to be conscious of our feelings and
                    attitudes and of how they can affect our actions while
                    driving.
                  </li>

                  <li>
                    Most of our driving experiences will be uneventful. We each
                    have a personal challenge and responsibility to be mentally
                    prepared to handle the risks of driving.
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="module_risk_objective">
          <Container>
            <Row className="mt-4">
              <Col md={6} xs={12}>
                <div className="module_risk_objective_img mt-lg-0 mt-5">
                  <img
                    src={Objective}
                    data-aos="zoom-in-right"
                    data-aos-duration="2000"
                    alt="Image"
                  />
                </div>
              </Col>

              <Col md={6} xs={12}>
                <h2
                  className="module_risk_same text-white mb-4"
                  data-aos="zoom-in"
                  data-aos-duration="2000"
                >
                  OBJECTIVES
                  <span className="border_head"></span>
                </h2>

                <ul className="p-0 text-white">
                  <li>
                    Discuss various mental factors that comprise our feelings
                    and attitudes, and how they influence our decisions and
                    actions as drivers.
                  </li>

                  <li>
                    Discuss the influence of fatigue and the use of alcohol and
                    other drugs on the mental task of driving.
                  </li>

                  <li>
                    Identify and discuss the types of risks involved in driving
                    decisions, and the potential outcome of those decisions.
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="module_driving_affect mt-3">
          <Container>
            <Row>
              <Col lg={12} md={12} xs={12}>
                <h2
                  className="mb-4"
                  data-aos="zoom-in"
                  data-aos-duration="2000"
                  style={{ width: "60%" }}
                >
                  FEELINGS AND HOW THEY CAN AFFECT OUR ACTIONS WHILE DRIVING
                  <span className="border_head"></span>
                </h2>

                <ul className="p-0">
                  <Row>
                    <Col lg={6} md={6} xs={12}>
                      <li>
                        Feelings are part of our everyday lives. We can have
                        more than one feeling at the same time. Our feelings can
                        differ in how long they last and how strong they are.
                      </li>

                      <li>
                        Our feelings influence the way we think and behave,
                        though we may not always openly express them. We need to
                        become aware of the ways that feelings come into play
                        while we drive, whether they are positive or negative.
                        There could be serious consequences if we allow our
                        feelings to control the way we drive.
                      </li>
                    </Col>
                    <Col lg={5} md={16} xs={12}>
                      <div className="module_driving_affect_img">
                        <img
                          src={Driving}
                          data-aos="zoom-in-left"
                          data-aos-duration="2000"
                          alt="Image"
                        />
                      </div>
                    </Col>
                  </Row>

                  <li>
                    Some things can bring out or subdue our feelings, leading us
                    to overreact or underreact to a situation. For example,
                    alcohol can bring out our feelings.
                  </li>

                  <li>
                    Dealing with feelings is an important part of driving. There
                    are ways to deal with those feelings before they lead to
                    irresponsible actions.
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="various_expression text-center mt-3 pt-4 pb-5">
          <Container>
            <img src={Hammer} className="various_img" alt="Image" />
            <h2
              className="mb-md-4 mb-2"
              data-aos="zoom-in"
              data-aos-duration="2000"
            >
              EXPRESSION OF VARIOUS FEELINGS
            </h2>
            <img
              src={Hammer}
              className="various_img hammer_right"
              alt="Image"
            />

            <Row>
              <Col lg={2} md={3} xs={4}>
                <div className="expression_img">
                  <img src={Expression1} alt="Image" />
                </div>
              </Col>
              <Col lg={2} md={3} xs={4}>
                <div className="expression_img">
                  <img src={Expression2} alt="Image" />
                </div>
              </Col>
              <Col lg={2} md={3} xs={4}>
                <div className="expression_img">
                  <img src={Expression3} alt="Image" />
                </div>
              </Col>
              <Col lg={2} md={3} xs={4}>
                <div className="expression_img">
                  <img src={Expression4} alt="Image" />
                </div>
              </Col>
              <Col lg={2} md={3} xs={4}>
                <div className="expression_img">
                  <img src={Expression5} alt="Image" />
                </div>
              </Col>
              <Col lg={2} md={3} xs={4}>
                <div className="expression_img">
                  <img src={Expression6} alt="Image" />
                </div>
              </Col>
              <Col lg={2} md={3} xs={4}>
                <div className="expression_img">
                  <img src={Expression7} alt="Image" />
                </div>
              </Col>
              <Col lg={2} md={3} xs={4}>
                <div className="expression_img">
                  <img src={Expression8} alt="Image" />
                </div>
              </Col>
              <Col lg={2} md={3} xs={4}>
                <div className="expression_img">
                  <img src={Expression9} alt="Image" />
                </div>
              </Col>{" "}
              <Col lg={2} md={3} xs={4}>
                <div className="expression_img">
                  <img src={Expression10} alt="Image" />
                </div>
              </Col>
              <Col lg={2} md={3} xs={4}>
                <div className="expression_img">
                  <img src={Expression11} alt="Image" />
                </div>
              </Col>
              <Col lg={2} md={3} xs={4}>
                <div className="expression_img">
                  <img src={Expression12} alt="Image" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="influence_action pt-5 pb-3">
          <Container>
            <Row>
              <Col md={8} xs={12}>
                <div>
                  <h2
                    className="action_content text-white"
                    data-aos="zoom-in"
                    data-aos-duration="2000"
                  >
                    ATTITUDES AND HOW THEY INFLUENCE OUR ACTIONS{" "}
                    <span className="border_head"></span>
                  </h2>

                  <ul className="p-0 mt-5 mb-4">
                    <li>
                      Our feelings are constantly changing. However, attitudes
                      are more constant. They provide us with a sense of mental
                      balance and perspective, ideally being an aid in keeping
                      our feelings under control.
                    </li>

                    <li>
                      Our values and beliefs are part of our attitudes and
                      influence the way we act. For example, if you believe that
                      drinking and driving is wrong, you will do whatever
                      possible to avoid it and also encourage others to do the
                      same.
                    </li>
                  </ul>
                </div>
              </Col>
              <Col md={4} xs={12}>
                <div
                  className="influence_action_img mt-md-5"
                  data-aos="zoom-in-left"
                  data-aos-duration="2000"
                >
                  <img src={Action} alt="Image" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="module_risk_listdiv pt-3 pb-5">
          <Container>
            <ul className="p-0">
              <li>
                If you have an "I don't care" attitude, you will likely engage
                in riskier behavior.
              </li>

              <li>
                Most drivers share a common belief in terms of their attitude
                towards safe driving. The majority of us generally believe that
                there are real possibilities of getting hurt, so we trust that
                other drivers will also act in a way to avoid that outcome.
              </li>

              <li>
                There are other drivers that have a very different attitude
                about the same possibility of getting hurt. They are those who
                believe “it can’t happen to me; it only happens to others.” Even
                when they are involved in a crash, that attitude can be so
                significant that they will insist that someone else is at fault.
              </li>

              <img src={Attitude} className="attitude_img" alt="Image" />

              <li className="mt-5">
                Sometimes, when one's attitude towards something is different
                from others', it can put a great deal of pressure on that person
                to act against what they believe is right. For example, if all
                of your friends are doing something that you disagree with, they
                may work very hard to pressure you into going against what you
                believe in and acting upon it.
              </li>

              <li>
                It is important to learn to be conscious of our attitudes, as
                well as the positive and negative influence they can have on our
                actions on the road.
              </li>

              <div className="h5p-module-6" id="h5p_content_navigate">
                <div className="content-main-box">
                  <div className="content-heading">
                    <div className="cont-box">
                      <hr />
                      <h2>QUESTION</h2>
                      <hr />
                    </div>
                    <h3>{question}</h3>
                  </div>
                  <p>Select the choice that best fits</p>
                  <div className="options-container">
                    {answerChoices.map((choice) => (
                      <div
                        className={`option-div ${
                          isSubmitted && choice.value === correctAnswer
                            ? "correct"
                            : ""
                        } ${
                          isSubmitted &&
                          selectedAnswer === choice.value &&
                          choice.value !== correctAnswer
                            ? "incorrect"
                            : ""
                        }`}
                      >
                        <div className="option-box">
                          <div
                            className={`option-card ${
                              selectedAnswer === choice.value ? "active" : ""
                            }`}
                            key={choice.value}
                            onClick={() => handleOptionClick(choice.value)}
                          >
                            <input
                              type="radio"
                              id={choice.label}
                              name="answer"
                              value={choice.value}
                              checked={selectedAnswer === choice.value}
                              onChange={() => {
                                handleAnswerSelect(choice.value);
                              }}
                              disabled={isSubmitted}
                            />
                            <span>{choice.label}</span>
                            <label htmlFor={choice.label}>
                              {choice.content}
                            </label>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <button
                    onClick={handleSubmit}
                    disabled={isSubmitted || selectedAnswer === ""}
                  >
                    Submit
                  </button>
                </div>

                <h3 className="h5p-content-note">
                  Note: Please answer the above question before going further.
                </h3>
              </div>

             
            </ul>
          </Container>
        </div>

        <div className="calculate_risk">
          <Container>
            <h4 data-aos="zoom-in" data-aos-duration="2000">
              CALCULATED RISK VS IMPULSIVE RISK{" "}
              <span className="border_head"></span>
            </h4>

            <div className="calculate_content mt-3">
              <h6 className="mb-2">WHAT IS A RISK?</h6>
              <p>
                A risk is the chance of suffering some kind of loss, damage, or
                injury when we do things, regardless of what might be gained by
                what we do.
              </p>

              <span className="d-block mb-3">
                We can categorize risks into two categories: impulsive risks and
                calculated risks.
              </span>

              <ul className="p-0">
                <li>
                  Impulsive risks occur when a driver acts hastily and without
                  much thought about the consequences.
                </li>

                <li>
                  An example of an impulsive risk is speeding through a yellow
                  light as it’s about to turn to red. The result of this
                  impulsive behaviour is unpredictable and will give other
                  drivers in the area little chance to evaluate the situation
                  and make the necessary adjustments.
                </li>

                <li>
                  Calculated risks occur when the driver acts only after sizing
                  up a situation. For example, a person traveling on a snowy day
                  decides to continue driving at a fixed speed after considering
                  the many conditions related to the weather. This person made a
                  calculated choice to drive at a certain speed to
                  reach a destination on time in spite of the hazards. This
                  situation is less likely to get out of control than with the
                  impulsive driver, because the driver is aware of what the
                  situation demands and is better prepared to make a change, if
                  necessary.
                </li>

                <li>
                  There is no way to completely avoid risk. However, taking
                  risks rarely results in appropriate actions. It only leads the
                  driver to take actions with unpredictable outcomes.
                </li>

                <li>
                  It is important to try and reduce the level of risk-taking by
                  being vigilant in surveying the driving situation.
                </li>
               
                <Row className="mt-5 mb-4">
                  <Col md={5} xs={12}>
                    <div className="drug_img mt-md-5 pt-md-3">
                      <img src={Drugs} alt="Image" />
                    </div>
                  </Col>

                  <Col md={7} xs={12}>
                    <h2
                      className="mb-4 drug_head"
                      data-aos="zoom-in"
                      data-aos-duration="2000"
                    >
                      ALCOHOL & DRUGS <span className="border_head"></span>
                    </h2>

                    <ul className="p-0">
                      <li>
                        Alcohol use and drug use reduce the ability of a person
                        to safely perform the mental task of driving. They
                        reduce the person’s ability to concentrate and increase
                        the effect of feelings and attitude.
                      </li>

                      <li>
                        Drinking alcohol causes loss of judgment and lowering of
                        inhibitions. The expression of our attitudes can surface
                        inappropriately, or become distorted.
                      </li>
                    </ul>
                  </Col>
                </Row>

                <li>
                  Alcohol is the most widely available and used drug. Other
                  drugs that can influence our feelings and the way we think are
                  sedatives, stimulants and hallucinogens, including
                  over-the-counter, prescription, and illicit drugs.
                </li>

                <h2
                  className="mb-4 drug_head mt-4"
                  data-aos="zoom-in"
                  data-aos-duration="2000"
                >
                  FATIGUE & DROWSINESS <span className="border_head"></span>
                </h2>

                <li>
                  Invoke the danger of a driver to lapse into sleep while at the
                  wheel.
                </li>

                <li>
                  Can lead to confusion and inappropriate expressions of
                  feeling.
                </li>
              </ul>
            </div>
          </Container>
        </div>

        {/* <div className="graduate_driver">
          <div
            className="graduate_head_section"
            data-aos="zoom-in"
            data-aos-duration="2000"
          >
            <h2>
              THE GRADUATED DRIVER LICENSING LAW & SPECIAL LIMITATIONS PLACED
              UPON JUNIOR DRIVERS
            </h2>

            <span>
              Note: The information in this section pertains to 16- and
              17-year-old drivers.
            </span>
          </div>

          <Container>
            <h4>WHAT IS A JUNIOR DRIVER?</h4>

            <Row>
              <Col md={8} xs={12}>
                <ul className="p-0">
                  <li>
                    Drivers under the age of 18 that don't have a driver
                    education MV-285 Student Certificate of Completion are
                    considered to be junior drivers.
                    <p className="mt-3">
                      <span className="mb-2"> Young driver statistics: </span>
                      Car crashes are the leading cause of death for young
                      drivers (drivers ages 16-24).
                    </p>
                  </li>

                  <li>
                    Drivers ages 16-20 are overrepresented in crashes; 4% of the
                    licensed drivers in New York are ages 16-20, but 8% of the
                    drivers involved in crashes in 2015 were in this age group.
                  </li>
                </ul>
              </Col>

              <div className="drive_img">
                <img src={SchoolDriving} alt="Image" />
              </div>

              <Col md={12} xs={12}>
                <ul className="p-0">
                  <li>
                    The younger the driver, the more likely he or she is to
                    crash. Crash rates of 16- and 17-year-old drivers are 35%
                    higher than that of 18 to 20-year-old drivers, and 62%
                    higher than 21 to 24-year olds.
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="risk_factor mt-5">
          <Container>
            <h5 className="mb-3">KEY RISK FACTORS FOR TEENS:</h5>
            <Row>
              <Col md={3} xs={12} className="mb-md-0 mb-2">
                <div className="risk_factor_content">
                  <p>Immaturity</p>
                </div>
              </Col>

              <Col md={3} xs={12} className="mb-md-0 mb-2">
                <div className="risk_factor_content">
                  <p>Inexperience and lack of driving skills</p>
                </div>
              </Col>

              <Col md={3} xs={12} className="mb-md-0 mb-2">
                <div className="risk_factor_content">
                  <p>
                    Judgment and decision-making ability is not fully developed
                  </p>
                </div>
              </Col>

              <Col md={3} xs={12} className="mb-md-0 mb-2">
                <div className="risk_factor_content">
                  <p>
                    Risky driving behaviours (speeding, distracted driving,
                    impaired driving)
                  </p>
                </div>
              </Col>
            </Row>
            {/* <p className="text-center mt-2 mb-4">
            Source: Institute for Traffic Safety Management & Research
          </p> */}
            {/* <h5 className="mt-3 mb-3">GRADUATED DRIVER LICENSING LAW (GDL)</h5>
            <ul className="p-0">
              <li> */}
                {/* The Graduated Driver Licensing law (GDL) was developed to address
              the safety and risk issues of young drivers. */}
                {/* The Graduated Driver Licensing Law (GDL) & Special Limitations
                Placed Upon Junior Drivers
              </li>

              <li>
                GDL gradually introduces young drivers to unrestricted driving
                privileges through three stages:
              </li>
            </ul>
            <div
              className="risk_factor_img text-center mt-5 mb-5"
              data-aos="zoom-in"
              data-aos-duration="2000"
            >
              <img src={Factor} />
            </div>
            <Row>
              <Col md={5} xs={12}>
                <div
                  className="graduated_img"
                  data-aos="zoom-in"
                  data-aos-duration="2000"
                >
                  <img src={Graduated} alt="Image" />
                </div>
              </Col>

              <Col md={7} xs={12}>
                <h5 className="mt-md-0 mt-3">
                  THE GRADUATED LICENSE LAW AND RESTRICTIONS FOR DRIVERS UNDER
                  18
                </h5>

                <p className="risk_para">
                  The Graduated License Law imposes limitations on individuals
                  under 18 years old holding a junior learner permit or junior
                  driver license (Class DJ, MJ, or DJMJ). These restrictions
                  vary depending on if you have a Junior License or Junior
                  Permit, and when and where you will be driving in the state of
                  New York.
                </p>

                <ul className="p-0">
                  <li>
                    Whether you have a junior learner permit or a junior driver
                    license
                  </li>

                  <li>Where and when you will be driving in New York State</li>
                </ul>
              </Col>
            </Row>
            <div className="check_region mt-3 mb-5">
              <Row>
                <Col md={5} xs={12}>
                  <h6 className="check_region_content">
                    Check the restrictions in your region!
                  </h6>
                </Col>

                <Col md={2} xs={12}>
                  <div className="check_region_img text-md-right pt-3">
                    <img src={License} alt="Image" />
                  </div>
                </Col>

                <Col md={5} xs={12}>
                  <p className="check_region_content_para mt-2">
                    DMV - graduated license law & restrictions for drivers under
                    18
                  </p>
                </Col>
              </Row>
            </div>
            <p className="region_para">
              The following video will explain The Graduated License Law,
              Restrictions, and Penalties for Drivers Under 18. Click the Play
              button to start the video.
            </p>
            <video
              id="video"
              width="100%"
              height="400"
              onEnded={() => {
                sessionStorage.setItem("VIDEO_PLAYED", "COMPLETED");
              }}
              autoplay={true}
              controls
              controlsList="nodownload noplaybackrate"
            >
              <source src={GraduatedLaw} type="video/mp4" />
            </video>
            <div className="mt-5">
              <h5 className="mt-md-0 mt-3">
                GENERAL RESTRICTIONS FOR JUNIOR DRIVERS
              </h5>
              <ul className="p-0">
                <li>
                  If you're applying for a junior permit or junior license
                  (Class DJ or MJ), you will need to get parental consent.
                  Junior drivers must hold a valid permit for at least six
                  months before they are eligible to take the road test. Keep in
                  mind that any time the permit is suspended or revoked does not
                  count toward the six-month requirement.
                </li>

                <li>
                  When supervision is required by law, only the supervising
                  driver is allowed to sit in the front seat. Additionally,
                  junior drivers are restricted from having more than one
                  passenger under the age of 21 in the vehicle, unless those
                  passengers are immediate family members or the supervising
                  driver is a licensed parent, guardian, or certified driving
                  instructor.
                </li>
              </ul>

              <div>
                <ul className="p-0">
                  <Row>
                    <Col md={6}>
                      <li>
                        All junior drivers and their passengers must wear seat
                        belts, with one seat belt per person. Children under
                        four years old must be secured in a federally-approved
                        child safety seat.
                      </li>
                      <li>
                        Before taking the road test, a junior permit holder must
                        submit a Certification of Supervised Driving form
                        (MV-262) to the examiner. This form verifies that the
                        junior driver has completed a minimum of 50 hours of
                        supervised driving, including at least 15 hours of
                        nighttime driving.
                      </li>
                    </Col>
                    <Col md={6}>
                      <img
                        src={JuniorDriver}
                        className=""
                        width="100%"
                        alt="Image"
                      />
                    </Col>
                  </Row>
                </ul>
                <img
                  src={DriverLicense}
                  className=""
                  width="100%"
                  alt="Image"
                />
              </div>
              <div className="mt-5 before-star">
                <h5 className="mt-md-0 mt-5">
                  Junior permit holders may not drive:
                </h5>
                <ul className="p-0">
                  <li>
                    At any time on any street within a park in New York City, or
                    on bridges or tunnels managed by the Triborough Bridge and
                    Tunnel Authority.
                  </li>
                  <li>
                    On the Cross County, Hutchinson River, Saw Mill River, or
                    Taconic State Parkways in Westchester County.
                  </li>
                  <li>In any DMV Road Test Area under any conditions.</li>
                  <li>
                    Without a qualified supervising driver or outside the
                    geographical restrictions that apply to the junior permit
                    holder's area.
                  </li>
                </ul>
              </div>

              <div className="mt-5 traffic_penalities">
                <h5 className="mt-md-0 mt-5">
                  Penalties for traffic violation as a junior driver
                </h5>
                <ul className="p-0">
                  <li>
                    If the junior driver is convicted of a single "use of a
                    mobile telephone/use of portable electronic device" offense
                    during the probationary period, their license will be
                    suspended for 120 days.
                  </li>
                  <li>
                    If a junior driver has their license, permit, or driving
                    privileges reinstated after a suspension or revocation and
                    commits another offense, their junior permit or license will
                    be revoked for 60 days.
                  </li>
                  <div className="violation_img">
                    <img
                      src={TrafficViolation}
                      className=""
                      width="60%"
                      alt="Image"
                    />
                  </div>
                  <Row className="mb-5">
                    <Col md={6} xs={12} className="mb-md-0 mb-2">
                      <div className="violation_block">
                        <p>
                          If a junior driver is convicted of any two moving
                          violations during their probationary period, their
                          junior permit or license will be revoked.
                        </p>
                      </div>
                    </Col>

                    <Col md={6} xs={12} className="mb-md-0 mb-2">
                      <div className="violation_block">
                        <p>
                          If a junior driver is found guilty of committing a
                          single, more serious violation (as listed below)
                          during the probationary period.
                        </p>
                      </div>
                    </Col>
                  </Row>
                  <h5>
                    A “serious traffic violation” is generally a violation that
                    carries three or more driver violation points. Some examples
                    are:
                  </h5>
                  <ul className="p-0">
                    <li>Speeding</li>

                    <li>Reckless driving</li>
                    <li>Following too closely</li>
                    <li>Participating in a speed contest</li>
                    <li>Use of mobile telephone (such as a cellular phone)</li>
                    <li>
                      Use of portable electronic device (such as a smartphone,
                      GPS or MP3 player)
                    </li>
                  </ul>
                </ul>
                <p>
                  If, however, the junior driver is found guilty of committing a
                  single "use of a mobile telephone/use of portable electronic
                  device" violation during the probation period, their license
                  will be revoked for 1 year.
                </p>
              </div>
            </div>
            <div className="">
              <img
                src={TrafficViolationFooter}
                className=""
                width="100%"
                alt="Image"
              />
            </div>
          </Container>
        </div> */} 
      </Container>
    </div>
  );
};
