export const SOCKET_CONNECT_SUCCESS = "SOCKET_CONNECT_SUCCESS";
export const SOCKET_OPEN_CLOSE_SUCCESS = "SOCKET_OPEN_CLOSE_SUCCESS";
export const SOCKET_EMIT_START_STOP_MODULE_SUCCESS = "SOCKET_EMIT_START_STOP_MODULE_SUCCESS";
export const SOCKET_CLOSE_RESPONSE_SUCCESS = "SOCKET_CLOSE_RESPONSE_SUCCESS";
// export const SOCKET_EMIT_STOP_MODULE_SUCCESS = "SOCKET_EMIT_STOP_MODULE_SUCCESS";
export const SOCKET_NON_INTERACTIVE_START = "SOCKET_NON_INTERACTIVE_START"; 
export const SOCKET_TIMER_SUCCESS = "SOCKET_TIMER_SUCCESS"; 
export const SOCKET_TIMER_UP = "SOCKET_TIMER_UP"; 
export const SOCKET_ERROR = "SOCKET_ERROR";
export const MARK_COURSE_MODULE_COMPLETE = 'MARK_COURSE_MODULE_COMPLETE';
// export const SOCKET_CLOSE_SUCCESS = "SOCKET_CLOSE_SUCCESS";