import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { getCourseListAllAction, clearCourseReducerAction, getEnrolledCourcesAction, getCompletedCourcesAction, getRegisteredUserAction, getTotalCoursePaymentAction, getUsersWithNoPaymentsAction, getCoursePerformanceAction, getExpiredCourcesAction } from "../../../Redux/Actions";
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import calenderIcon from '../../../Images/calander_icon.png';

import "./Home.css";

import Chart from "./Chart";
import PieChart from "./PieChart";
import moment from "moment";

const Home = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const [courseList, setCourseList] = useState([]);
    const [registeredUserCount, setRegisteredUserCount] = useState(0);
    const [completedCourseCount, setCompletedCourseCount] = useState(0);
    const [enrolledCourseCount, setEnrolledCourseCount] = useState(0)
    const [expiredCourseCount, setExpiredCourseCount] = useState(0)
    const [withoutPaymentUser, setWithoutPaymentUser] = useState(0);
    const [totalCoursePayment, setTotalCoursepayment] = useState([]);
    const [coursePerformanceResult, setCoursePerformanceResult] = useState(0);
    const [selectedCourseID, setSelectedCourseID] = useState();
    const [showCalender, setShowCalender] = useState(false)
    const todayDate = new Date();
    const [dateRange, setDateRange] = useState([
        {
            startDate: new Date(new Date().getFullYear(), 0, 1),
            endDate: new Date(new Date().getFullYear(), 11, 31),
            key: 'selection'
        }
    ]);

    const onChangeDate = (item) => {
        // console.log(item.selection)
        setDateRange([item.selection])
        let startDate = moment(item.selection.startDate).format('YYYY-MM-DD')
        let endDate = moment(item.selection.endDate).format('YYYY-MM-DD')
        setTimeout(()=>{
            getRegisteredUser(selectedCourseID, startDate, endDate)
        },2000)
    }

    useEffect(() => {
        getCourseList(1)
    }, [])

    const getCourseList = async () => {
        try {
            const response = await dispatch(getCourseListAllAction(1, 1000));
            const allCoursResponse = response?.payload;

            // Check this once component object saved in db is finalized 
            if (allCoursResponse) {
                if (allCoursResponse.data) {
                    if (allCoursResponse.data.code == 200) {
                        const arrange = allCoursResponse?.data?.data?.rows;

                        var d = new Date();
                        // getRegisteredUser(arrange[0]?.id, d.getFullYear())
                        setSelectedCourseID(arrange[0]?.id)
                        setCourseList(arrange)
                        // API calls
                        getCoursePerformance(arrange[0].id, d.getFullYear())
                        getTotalCoursePayment(arrange[0].id, d.getFullYear())
                        const start_of_year = moment().startOf('year').format('YYYY-MM-DD');
                        const end_of_year = moment().endOf('year').format('YYYY-MM-DD');
                        getRegisteredUser(arrange[0]?.id, start_of_year, end_of_year)
                    } else {
                        swal({
                            title: "Error!",
                            text: allCoursResponse.data.message,
                            icon: "error",
                            timer: 6000
                        });
                    }
                }
            }
        }
        catch (err) {
            if (err?.response?.data?.code === 401) {
                swal({
                    title: "Error!",
                    text: err.response.data.err,
                    icon: "error",
                    timer: 5000
                });
                history.push('/')
            }
        }
    }
    const clearCourseReducer = () => {
        const response = dispatch(clearCourseReducerAction());
        const success = response?.payload;
        if (success) {
            if (success.data) {
                if (success.data.code == 200) {
                    history.push('/courses/coursecreation')
                }
            }
        }
    }

    const getRegisteredUser = async (cid, startDate, endDate) => {
        try {
            const response = await dispatch(getRegisteredUserAction(cid, startDate, endDate));
            getCompletedCources(cid, startDate, endDate)

            let registerdUserResponse = response?.payload;
            if (registerdUserResponse) {
                if (registerdUserResponse?.data) {
                    // console.log('registerdUserResponse?.data')
                    // console.log(registerdUserResponse?.data)
                    setRegisteredUserCount(registerdUserResponse?.data?.data)
                }
            }
        }
        catch (err) {
            if (err?.response?.data?.code === 401) {
                swal({
                    title: "Error!",
                    text: err?.response?.data?.err,
                    icon: "error",
                    timer: 5000
                });
                history.push('/')
            }
        }
    }

    const getCompletedCources = async (cid, startDate, endDate) => {
        try {
            const response = await dispatch(getCompletedCourcesAction(cid, startDate, endDate));
            getEnrolledCources(cid, startDate, endDate)
            getExpiredCources(cid, startDate, endDate)
            let completedCourcesResponse = response?.payload;
            if (completedCourcesResponse) {
                if (completedCourcesResponse.data) {
                    if (completedCourcesResponse.data.code == 200) {
                        setCompletedCourseCount(completedCourcesResponse?.data?.data?.count)
                    } else {
                        swal({
                            title: "Error!",
                            text: completedCourcesResponse.data.message,
                            icon: "error",
                            timer: 6000
                        });
                    }
                }
            }
        }
        catch (err) {
            if (err?.response?.data?.code === 401) {
                swal({
                    title: "Error!",
                    text: err.response.data.err,
                    icon: "error",
                    timer: 5000
                });
                history.push('/')
            }
        }
    }
    const getEnrolledCources = async (cid, startDate, endDate) => {
        try {
            const response = await dispatch(getEnrolledCourcesAction(cid, startDate, endDate));
            getUsersWithNoPayments(cid, startDate, endDate)
            // getCoursePerformance(cid, year)
            let getEnrolledCourcesResponse = response?.payload;
            if (getEnrolledCourcesResponse) {
                if (getEnrolledCourcesResponse.data) {
                    if (getEnrolledCourcesResponse.data.code == 200) {
                        setEnrolledCourseCount(getEnrolledCourcesResponse?.data?.data?.count)
                    } else {
                        swal({
                            title: "Error!",
                            text: getEnrolledCourcesResponse.data.message,
                            icon: "error",
                            timer: 6000
                        });
                    }
                }
            }
        }
        catch (err) {
            if (err?.response?.data?.code === 401) {
                swal({
                    title: "Error!",
                    text: err.response.data.err,
                    icon: "error",
                    timer: 5000
                });
                history.push('/')
            }
        }
    }

    const getExpiredCources = async (cid, startDate, endDate) => {
        try {
            const response = await dispatch(getExpiredCourcesAction(cid, startDate, endDate));
            getUsersWithNoPayments(cid, startDate, endDate)
            // getCoursePerformance(cid, year)
            let getExpiredCourcesResponse = response?.payload;
            if (getExpiredCourcesResponse) {
                if (getExpiredCourcesResponse.data) {
                    if (getExpiredCourcesResponse.data.code == 200) {
                        setExpiredCourseCount(getExpiredCourcesResponse?.data?.data?.count)
                    } else {
                        swal({
                            title: "Error!",
                            text: getExpiredCourcesResponse.data.message,
                            icon: "error",
                            timer: 6000
                        });
                    }
                }
            }
        }
        catch (err) {
            if (err?.response?.data?.code === 401) {
                swal({
                    title: "Error!",
                    text: err.response.data.err,
                    icon: "error",
                    timer: 5000
                });
                history.push('/')
            }
        }
    }


    const getTotalCoursePayment = async (courseid, year) => {
        try {
            const response = await dispatch(getTotalCoursePaymentAction(courseid, year));
            let totalCoursePaymentResponse = response?.payload;
            if (totalCoursePaymentResponse) {
                if (totalCoursePaymentResponse?.data) {
                    if (totalCoursePaymentResponse?.data?.data?.length > 0) {
                        setTotalCoursepayment(totalCoursePaymentResponse?.data?.data)
                    } else {
                        setTotalCoursepayment([])
                    }

                }
            }
        }
        catch (err) {
            if (err?.response?.data?.code === 401) {
                swal({
                    title: "Error!",
                    text: err?.response?.data?.err,
                    icon: "error",
                    timer: 5000
                });
                history.push('/')
            }
        }
    }

    const getUsersWithNoPayments = async (cid, startDate, endDate) => {
        try {
            const response = await dispatch(getUsersWithNoPaymentsAction(cid, startDate, endDate));
            let responseWithNoPayment = response?.payload;
            if (responseWithNoPayment) {
                if (responseWithNoPayment.data) {
                    setWithoutPaymentUser(responseWithNoPayment?.data?.data?.length)
                }
            }
        }
        catch (err) {
            if (err?.response?.data?.code === 401) {
                swal({
                    title: "Error!",
                    text: err?.response?.data?.err,
                    icon: "error",
                    timer: 5000
                });
                history.push('/')
            }
        }
    }

    const getDetailByCourseId = async(courseId, year) => {
        // api 
        // console.log(courseId, year)
        setSelectedCourseID(courseId)
        setDateRange([
            {
                startDate: new Date(year, 0, 1),
                endDate: new Date(year, 11, 31),
                key: 'selection'
            }
        ])
        getTotalCoursePayment(courseId, year)
        getCoursePerformance(courseId, year)
        const start_of_year = moment(year, 'YYYY-MM-DD').startOf('year').format('YYYY-MM-DD');
        const end_of_year = moment(year, 'YYYY-MM-DD').endOf('year').format('YYYY-MM-DD');
        getRegisteredUser(courseId, start_of_year, end_of_year);

    }

    const getCoursePerformance = async (cid, year) => {
        try {
            const response = await dispatch(getCoursePerformanceAction(cid, year));
            let getCoursePerformanceResponse = response?.payload;
            setCoursePerformanceResult(getCoursePerformanceResponse?.data?.data?.count)
        }
        catch (err) {
            if (err?.response?.data?.code === 401) {
                swal({
                    title: "Error!",
                    text: err.response.data.err,
                    icon: "error",
                    timer: 5000
                });
                history.push('/')
            }
        }
    }

    const coursePageredirect = () => {
        history.push("/courses/courselist")
    }

    return (
        <>
            <div className="pt-4 pl-lg-3 pr-lg-3 pl-0 pr-0 pb-4 mb-4 containerHome dashboardSec">
                <div className="row">
                    <div className="col-xl-8 col-lg-7 col-md-7 col-12">
                        <div className="card">
                            { <Chart courseList={courseList} courseid={selectedCourseID}  getDetailByCourseId={getDetailByCourseId} totalCoursePayment={totalCoursePayment} />}
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-5 col-md-5 col-12">
                        <div className="card">
                            <PieChart coursePerformanceResult={coursePerformanceResult} />
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-xl-8 col-lg-7 col-md-7 col-12">
                        <div className="card">
                            <div className="card-body pt-3 pb-4">
                                <div className="cousestitle">
                                    <h4 className="card-title courseTitle">Courses</h4>

                                    <a className="btn btnBlue" onClick={clearCourseReducer}>
                                        Create Course
                                    </a>

                                </div>
                                <div className="tableScroll">
                                    <table className="table">
                                        <thead>
                                            <tr className="tableHeading">
                                                <th scope="col">#</th>
                                                <th scope="col">Course Name</th>
                                                <th scope="col">Created by</th>
                                                <th scope="col">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {courseList ?
                                                courseList.map((course, i) => {
                                                    let status = "";
                                                    if (course.is_active) {
                                                        status = "Active"
                                                    } else {
                                                        status = "Inactive"
                                                    }
                                                    return (
                                                        <tr key={`home-${i}`}>
                                                            <td>{i + 1}</td>
                                                            <td>{course.course_title || 'Introduction to the course'}</td>
                                                            <td>{course.createdBy?.first_name}&nbsp;{course.createdBy?.last_name}</td>
                                                            <td className="active">Active</td>

                                                        </tr>
                                                    )
                                                }) : null}
                                        </tbody>
                                    </table>
                                </div>

                                <a

                                    className="ViewAlltag text-center w-100 d-block" onClick={() => coursePageredirect()}
                                >
                                    View All
                                </a>

                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4 col-lg-5 col-md-5 col-12">
                        <div className="card">
                            <div className="chartHearder noFlex">
                                <div className="heading latest_info_head">
                                    <h3>
                                        Latest Info
                                    </h3>
                                    <img src={calenderIcon} onClick={() => setShowCalender(!showCalender)} />
                                </div>
                                <div className="borderBottom"></div>
                            </div>
                            {
                                showCalender &&
                                <div className="date_picker_card">
                                    <DateRangePicker
                                        className="date_picker"
                                        onChange={item => onChangeDate(item)}
                                        showSelectionPreview={true}
                                        moveRangeOnFirstSelection={false}
                                        months={1}
                                        ranges={dateRange}
                                        maxDate={todayDate}
                                        direction="horizontal"
                                    />
                                </div>
                            }
                            <ul className="list-group list-group-flush latestInfoList p-0">
                                <li className="list-group-item latestInfo">
                                    <div className="iconData">
                                        <span className="infoIcon icon001"></span>
                                        {registeredUserCount}&nbsp; New Registration
                                    </div>
                                    {/* <span className="infoStatus">Last 24 Hours</span> */}
                                </li>
                                <div className="borderListBottom"></div>
                                <li className="list-group-item latestInfo">
                                    <div className="iconData">
                                        <span className="infoIcon icon002"></span>
                                        {completedCourseCount}&nbsp; Completed Course
                                    </div>
                                    {/* <span className="infoStatus">Last 24 Hours</span> */}
                                </li>
                                <div className="borderListBottom"></div>
                                {/* <li className="list-group-item latestInfo">
                                    <div className="iconData">
                                        <span className="infoIcon icon003"></span>
                                        {enrolledCourseCount} &nbsp; Enrolled Course
                                    </div>
                                </li> */}
                                <div className="borderListBottom"></div>
                                <li className="list-group-item latestInfo">
                                    <div className="iconData">
                                        <span className="infoIcon icon003"></span>
                                        {expiredCourseCount} &nbsp;  Expired Course
                                    </div>
                                </li>
                                <div className="borderListBottom"></div>
                                <li className="list-group-item latestInfo">
                                    <div className="iconData">
                                        <span className="infoIcon icon003"></span>
                                        {withoutPaymentUser} &nbsp; Unsuccessful payment
                                    </div>
                                    {/* <span className="infoStatus">Last 24 Hours</span> */}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default Home;