export const SECURITY_QUESTION_LIST_SUCCESS = "SECURITY_QUESTION_LIST_SUCCESS";
export const QUIZ_QUESTION_LIST_SUCCESS     = "QUIZ_QUESTION_LIST_SUCCESS";
export const COURSE_MODULE_ADD_SUCCESS      = 'COURSE_MODULE_ADD_SUCCESS';
export const COURSE_MODULE_LIST_ALL_SUCCESS = 'COURSE_MODULE_LIST_ALL_SUCCESS';
export const COURSE_MODULE_UPDATE_SUCCESS   = 'COURSE_MODULE_UPDATE_SUCCESS';
export const COURSE_MODULE_GETBYID_SUCCESS  = 'COURSE_MODULE_GETBYID_SUCCESS';
export const COURSE_MODULE_DELETE_SUCCESS   = 'COURSE_MODULE_DELETE_SUCCESS';
export const COURSE_MODULE_CONTAIN_ID       = 'COURSE_MODULE_CONTAIN_ID';
export const COURSE_MODULE_LIST_SUCCESS     = 'COURSE_MODULE_LIST_SUCCESS';
export const COURSE_MODULE_SECURITY_QUESTION_SUCCESS = 'COURSE_MODULE_SECURITY_QUESTION_SUCCESS'
export const COURSE_MODULE_SECURITY_QUESTION_DETAIL_SUCCESS = 'COURSE_MODULE_SECURITY_QUESTION_DETAIL_SUCCESS'
export const COURSE_MODULE_SECURITY_QUESTION_UPDATE_SUCCESS = 'COURSE_MODULE_SECURITY_QUESTION_UPDATE_SUCCESS';
export const COURSE_MODULE_QUIZ_DETAIL_ID       = 'COURSE_MODULE_QUIZ_DETAIL_ID';
export const COURSE_MODULE_SECURITY_ID       = 'COURSE_MODULE_SECURITY_ID';
export const COURSE_MODULE_AUDIO_UPLOAD      = 'COURSE_MODULE_AUDIO_UPLOAD';
export const COURSE_MODULE_AUDIO_UPDATE      = 'COURSE_MODULE_AUDIO_UPDATE';
export const COURSE_MODULE_DOCS_UPLOAD      = 'COURSE_MODULE_AUDIO_UPLOAD';
export const COURSE_MODULE_DOCS_UPDATE      = 'COURSE_MODULE_AUDIO_UPDATE';
export const COURSE_MODULE_DOCS_DELETE      = 'COURSE_MODULE_AUDIO_DELETE';
export const COURSE_MODULE_TYPING_DNA_DETAIL_SUCCESS = 'COURSE_MODULE_TYPING_DNA_DETAIL_SUCCESS';
export const COURSE_MODULE_REORDER_UPDATE_SUCCESS = 'COURSE_MODULE_REORDER_UPDATE_SUCCESS';
export const COURSE_MODULE_OTP_DETAIL_SUCCESS = 'COURSE_MODULE_OTP_DETAIL_SUCCESS';
