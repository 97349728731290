export const COURSE_ADD_SUCCESS      = 'COURSE_ADD_SUCCESS';
export const COURSE_LIST_ALL_SUCCESS = 'COURSE_LIST_ALL_SUCCESS';
export const COURSE_UPDATE_SUCCESS   = 'COURSE_UPDATE_SUCCESS';
export const COURSE_GETBYID_SUCCESS  = 'COURSE_GETBYID_SUCCESS';
export const COURSE_DELETE_SUCCESS   = 'COURSE_DELETE_SUCCESS';
export const COURSE_CONTAIN_ID       = 'COURSE_CONTAIN_ID';
export const CLEAR_COURSE_REDUCER = 'CLEAR_COURSE_REDUCER';
export const COURSE_ID_MULTIPLE_USES = 'COURSE_ID_MULTIPLE_USES';
export const COURSE_UPLOAD_VIDEO_SUCCESS = 'COURSE_UPLOAD_VIDEO_SUCCESS';
export const CUSTOM_FIELD_SAVE_SUCCESS = 'CUSTOM_FIELD_SAVE_SUCCESS';
export const CUSTOM_FIELD_UPDATE_SUCCESS = 'CUSTOM_FIELD_UPDATE_SUCCESS'