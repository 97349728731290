import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";

import swal from "sweetalert";

import AOS from "aos";
import "aos/dist/aos.css";

import "./ModuleEight.css";

import { H5PEditorUI, H5PPlayerUI } from "@lumieducation/h5p-react";
import { ContentService } from "../services/ContentService";

export const ModuleEightPart3 = () => {
  const [scroll, setScroll] = useState(false);

  const contentService = new ContentService("/h5p");
  const h5pPlayer = React.useRef(H5PPlayerUI);

  const [loading, setLoading] = useState(true);


  const [image1Clicked, setImage1Clicked] = useState(false);
  const [image2Clicked, setImage2Clicked] = useState(false);
  const [image3Clicked, setImage3Clicked] = useState(false);
  const [image4Clicked, setImage4Clicked] = useState(false);
  const [allFaceImagesClicked, setAllFaceImagesClicked] = useState(false);
  const [decisionMatrixImagesClicked, setDecisionMatrixImagesClicked] =
    useState(false);

  const handleImage1Click = (content) => {
    setImage1Clicked(true);
    handleImageClick("Details", content);
  };

  const handleImage2Click = (content) => {
    setImage2Clicked(true);
    handleImageClick("Details", content);
  };

  const handleImage3Click = (content) => {
    setImage3Clicked(true);
    handleImageClick("Details", content);
  };

  const handleImage4Click = (content) => {
    setImage4Clicked(true);
    handleImageClick("Details", content);
  };

  const clickedStatus = {
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
    8: false,
    9: false,
    10: false,
    11: false,
    12: false,
    13: false,
    14: false,
    15: false,
    16: false,
  };
  
  const handleImageClick = (title, content) => {
    swal({
      title: title,
      content: {
        element: "div",
        attributes: {
          innerHTML: content,
        },
      },
    });
  };

  useEffect(() => {
    if (image1Clicked && image2Clicked && image3Clicked && image4Clicked) {
      console.warn("All decision Matrix images are visited.");
      setDecisionMatrixImagesClicked(true);
    }
  }, [image1Clicked, image2Clicked, image3Clicked, image4Clicked]);


  useEffect(() => {
    AOS.init();
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  useEffect(() => {
    sessionStorage.removeItem('HAS_H5P_ACTIVITY');
    sessionStorage.removeItem('HAS_H5P_ACTIVITY_SUBMIT');
  }, []);

  return (
    <div className="module8_course module_font same_width_module mb-md-4">
      <Container>
        <div className="module_drugs">
          <Container>
            <div
              className="drugs_content"
              data-aos="zoom-in"
              data-aos-duration="2000"
            >
              <p className="mb-1">Module 8 - Part 3</p>
              <h1 className="mb-0">
                ALCOHOL, <br />
                OTHER DRUGS <br />& DRIVING
              </h1>
            </div>
          </Container>
        </div>

        <div className="nys_vehicle">
          <div className="vehicle_law">
            <Container>
              <h2
                className="vehicle_traffic"
                data-aos="zoom-in-right"
                data-aos-duration="2000"
              >
                NYS Vehicle and Traffic Law §1198
                <span className="d-block">
                  Installation and operation of ignition interlock devices
                </span>
              </h2>

              <h4 className="risk_head">1. Applicability</h4>
              <p>
                This rule is for the whole state. It says that if a court orders
                someone to install and use an ignition interlock device in their
                car as a condition of probation or conditional discharge, they
                must do it for any car they own or drive.
              </p>

              <h4 className="risk_head">2. Requirements</h4>
              <p>
                (a) Apart from other penalties, the court must order anyone
                convicted of certain alcohol-related driving offenses (like
                subdivision two, two-a, or three of section 1192) to install and
                maintain an ignition interlock device in their car. This is a
                requirement if they are on probation or conditional discharge.
                However, the court can't let someone drive if their license has
                been revoked, except in specific situations.
              </p>
              <p>
                (b) Courts can change the probation conditions of people
                convicted of these offenses before this rule was effective. They
                can require these individuals to install and maintain the
                ignition interlock device, and then these individuals will be
                under the rules of this section.
              </p>
              <p>
                (c) This section doesn't allow a court to put someone on
                probation or conditional discharge just to make them follow
                these ignition interlock device rules. They can only do this if
                the person would normally be eligible for probation or
                conditional discharge.
              </p>

              <h4 className="risk_head">3. Conditions</h4>
              <p>
                (a) The commissioner can give a conditional license after
                license revocation to someone who's been convicted of certain
                driving offenses (subdivision two, two-a, or three of section
                1192) and is on probation or conditional discharge. This can
                happen if they've completed the minimum revocation period and
                can only drive a vehicle with an ignition interlock device.
                However, they can't get this license if they've violated certain
                driving or alcohol-related conditions during their revocation
                period. After their probation or conditional discharge ends,
                they can apply for a regular license.
              </p>
              <p>(b) This conditional license lets the holder drive:</p>

              <p className="ml-4">1. To and from work.</p>

              <p className="ml-4">2. For work if it requires driving.</p>

              <p className="ml-4">
                3. To and from school or vocational training.
              </p>

              <p className="ml-4">
                4. To and from court-ordered probation activities.
              </p>

              <p className="ml-4">
                5. To a motor vehicle office for license-related business.
              </p>

              <p className="ml-4">
                6. For a 3-hour period during the day when not working.
              </p>

              <p className="ml-4">
                7. To and from necessary medical appointments.
              </p>
              <p className="ml-4">
                8. To and from required alcohol and drug rehab program
                activities.
              </p>
              <p className="ml-4">
                9. To and from a place where their child is cared for, if needed
                for work or school.
              </p>
              <p>
                (c) The commissioner can revoke this conditional license for
                reasons like not following court conditions, getting convicted
                of traffic offenses (except parking-related), alcohol or drug
                offenses, or not maintaining the ignition interlock device.
              </p>
              <p>
                (d) The court can require, as part of probation or conditional
                discharge, the installation of an ignition interlock device in
                any vehicle the person owns or drives, especially if it's
                necessary for public safety. This doesn't affect any license
                suspension or revocation periods.
              </p>
              <p>
                (e) The court can also impose other probation or conditional
                discharge conditions like alcohol or drug treatment,
                restitution, and community service.
              </p>
              <p>
                (f) The commissioner will record that the person can only drive
                vehicles with an ignition interlock device.
              </p>

              <h4 className="risk_head_4">
                4. Proof of compliance and recording of condition
              </h4>
              <p>
                (a) When the court orders someone to use an ignition interlock
                device as part of probation or conditional discharge, the person
                must show proof that they're following this rule to both the
                court and the probation department (or whoever is supervising
                their probation or discharge). If they don't show proof and the
                court doesn't have a good reason for this (which must be
                recorded), the court can change or end their probation or
                conditional discharge. A valid reason might be that the person
                swears they don't own a car and won't drive any car during the
                time they're supposed to have the interlock device, except as
                the law might allow otherwise. The term "owner" is defined as in
                section 128 of this chapter.
              </p>
              <p>
                (b) When the court orders the ignition interlock device, it must
                inform the commissioner in a way the commissioner decides. Then,
                the commissioner will mark this requirement on the person’s
                driving record.
              </p>

              <h4 className="risk_head_4">
                5. Cost, installation and maintenance
              </h4>
              <p>
                (a) The person who is required to have an ignition interlock
                device must pay for its installation and upkeep. However, if the
                court decides that they can't afford it, the cost can be
                adjusted into a payment plan or even waived. If it's waived, the
                cost will be covered according to specific regulations or
                agreements set for providing the device. This cost is treated
                like a fine under the criminal procedure law. It's an additional
                cost and doesn't replace any other fines or charges that the
                person might have to pay under this law or other applicable
                laws.
              </p>
              <p>
                (b) The company that installs and services the ignition
                interlock device is responsible for putting it in, setting it up
                properly, and keeping it working.
              </p>

              <h4 className="risk_head">6. Certification</h4>
              <p>
                (a) The commissioner of the department of health will approve
                certain ignition interlock devices for use and will publish a
                list of these approved devices.
              </p>
              <p>
                (b) After talking with ignition interlock device manufacturers
                and the national highway traffic safety administration, the
                commissioner of health, along with the commissioner and the
                office of probation and correctional alternatives, will set
                regulations for the standards and use of these devices. These
                standards will include setting a minimum and maximum range for
                the device to work properly. The devices must:
              </p>
              <p className="ml-4">
                1. Be hard to bypass and not affect safe driving.
              </p>
              <p className="ml-4">
                2. Work accurately and reliably on their own.
              </p>
              <p className="ml-4">
                3. Be tamper-resistant and show if someone tries to tamper with
                them.
              </p>
              <p className="ml-4">4. Be easy to use for sober drivers.</p>
              <p className="ml-4">
                5. Require a proper breath sample or other accurate measure of
                blood alcohol content.
              </p>
              <p className="ml-4">
                6. Work well in different car environments.
              </p>
              <p className="ml-4">
                7. Match well with legal alcohol limits set by the court or law.
              </p>
              <p className="ml-4">
                8. Be made by a company with product liability insurance.
              </p>
              <p>
                (c) The health commissioner can also use guidelines, rules,
                regulations, studies, or tests from other states that have been
                used to certify or approve ignition interlock devices.
              </p>

              <h4 className="risk_head"> 7. Use of other vehicles</h4>
              <p>
                (a) If someone is legally required to only drive vehicles with
                an ignition interlock device (because of this law or the penal
                law), this rule applies to every vehicle they drive. This
                includes vehicles they lease, rent, or are loaned, not just
                their own.
              </p>
              <p>
                (b) No one should knowingly rent, lease, or lend a vehicle to
                someone who is only allowed to drive vehicles with an ignition
                interlock device, unless that vehicle has such a device. If
                someone has this driving restriction, they must tell anyone who
                rents, leases, or lends them a vehicle about this restriction.
              </p>
              <p>
                (c) Breaking either of these rules (in paragraph a or b) is
                considered a misdemeanor, which is a type of criminal offense.
              </p>

              <h4 className="risk_head">8. Employer vehicle</h4>
              <p>
                Despite other rules in this section, if someone needs to drive a
                vehicle owned by their employer for work, they can do so without
                an ignition interlock device, but only for work purposes. This
                is allowed if:
              </p>
              <p className="ml-4">
                1. The employer knows that the employee's driving privilege is
                restricted under these laws.
              </p>
              <p className="ml-4">
                2. The employee has given the court and probation department
                written proof that the employer knows about the restriction and
                allows the employee to drive the business vehicle without the
                device for work reasons.
              </p>
              <p>
                The employee must inform the court and the probation department
                before they start driving the employer's vehicle.
              </p>
              <p>
                However, this exception doesn't apply if the business vehicle is
                owned by a business that the employee partly or fully owns or
                controls. This rule is only for driving the employer's vehicle
                as part of the job.
              </p>

              <h4 className="risk_head_4">
                9. Circumvention of interlock device
              </h4>
              <p>
                (a) If someone is only allowed to drive with an ignition
                interlock device because of legal restrictions, they can't ask
                or allow anyone else to blow into the device or start the car
                for them, so they can drive.
              </p>
              <p>
                (b) Nobody should blow into an ignition interlock device or
                start a car with such a device for someone who is only allowed
                to drive cars with the device.
              </p>
              <p>
                (c) It's not allowed for anyone to tamper with or bypass a
                working ignition interlock device.
              </p>
              <p>
                (d) If a court has ordered someone to use an ignition interlock
                device, they must not drive a car that doesn't have one.
              </p>
              <p>
                (e) Breaking any of these rules (in paragraphs a, b, c, or d) is
                a Class A misdemeanor, which is a type of criminal offense.
              </p>

              <h4 className="risk_head">10. Warning label</h4>
              <p>
                The department of health shall design a warning label which the
                manufacturer shall affix to each ignition interlock device upon
                installation in the state. The label shall contain a warning
                that any person tampering, circumventing, or otherwise misusing
                the device is guilty of a misdemeanor and may be subject to
                civil liability.
              </p>
            </Container>
            <div className="drug_class mt-5 mb-5">
              <h4 className="mb-3">Sanctions cited are as follows:</h4>
              <table className="table">
                <thead>
                  <tr>
                    <th className="p-3">
                      DWAI (Driving While Ability Impaired) 1192(1):{" "}
                      <span className="detail">
                        License suspended for 90 days. If under 21, license
                        revoked for one year. For a second offense within 5
                        years, license revoked for 6 months; if under 21,
                        revoked for one year or until age 21, whichever is
                        longer.
                      </span>
                    </th>
                  </tr>
                  <tr>
                    <th className="p-3">
                      DWI; per se (Driving While Intoxicated) 1192(2):{" "}
                      <span className="detail">
                        License revoked for 6 months. If under 21, revoked for
                        one year. For a second offense of 1192(2), (3), (4), or
                        (4-a) within 10 years, license revoked for 1 year; if
                        under 21, revoked for one year or until age 21,
                        whichever is longer.
                      </span>
                    </th>
                  </tr>
                  <tr>
                    <th className="p-3">
                      Aggravated DWI; per se and with a child 1192(2-a):{" "}
                      <span className="detail">
                        License revoked for 1 year for all ages. For a second
                        offense of 1192(2-a), with a prior conviction of
                        1192(2), (2-a), (3), (4), or (4-a) within 10 years,
                        license revoked for 18 months; if under 21, revoked for
                        18 months or until age 21, whichever is longer. For a
                        second offense of 1192(2), (3), (4), or (4-a) with a
                        prior conviction for 1192(2-a) within 10 years, license
                        revoked for 18 months.
                      </span>
                    </th>
                  </tr>
                  <tr>
                    <th className="p-3">
                      DWI (Common Law DWI) 1192(3):{" "}
                      <span className="detail">
                        License revoked for 6 months; if under 21, revoked for
                        one year or until age 21, whichever is longer. For a
                        second offense of 1192(2), (3), (4), or (4-a) within 10
                        years, license revoked for 1 year; if under 21, revoked
                        for one year or until age 21, whichever is longer. For a
                        second offense of 1192(3) with a prior conviction for
                        1192(2-a) within 10 years, license revoked for 18
                        months.
                      </span>
                    </th>
                  </tr>
                  <tr>
                    <th className="p-3">
                      DWAI - Drugs 1192(4):{" "}
                      <span className="detail">
                        License revoked for 6 months; if under 21, revoked for
                        one year or until age 21, whichever is longer. For a
                        second offense of 1192(2), (3), (4), or (4-a) within 10
                        years, license revoked for 1 year; if under 21, revoked
                        for one year or until age 21, whichever is longer. For a
                        second offense of 1192(4) with a prior conviction for
                        1192(2-a) within 10 years, license revoked for 18
                        months.
                      </span>
                    </th>
                  </tr>
                  <tr>
                    <th className="p-3">
                      DWAI - Combined Drugs or of Drugs and Alcohol 1192(4-a):{" "}
                      <span className="detail">
                        License revoked for 6 months; if under 21, revoked for
                        one year or until age 21, whichever is longer. For a
                        second offense of 1192(2), (3), (4), or (4-a) within 10
                        years, license revoked for 1 year; if under 21, revoked
                        for one year or until age 21, whichever is longer. For a
                        second offense of 1192(4-a), with a prior conviction for
                        1192(2-a) within 10 years, license revoked for 18
                        months.
                      </span>
                    </th>
                  </tr>
                  <tr>
                    <th className="p-3">
                      Under 21:{" "}
                      <span className="detail">
                        For any first offense of 1192, if the person is under 21
                        or adjudicated as a youthful offender, the court must
                        issue at least a one-year revocation. If under 21 and
                        the prior conviction is for 1192(2-a), aggravated DWI,
                        revocation is for 18 months or until age 21, whichever
                        is longer.
                      </span>
                    </th>
                  </tr>
                </thead>
              </table>

              <h4 className="mb-3">
                Why are penalties for drinking and driving so strict?
              </h4>
              <p>
                Drinking and driving is a hazardous combination. Consider these
                facts:
              </p>
              <ul className="p-0">
                <li>
                  One third of the fatalities in NYS involve impaired or
                  intoxicated drivers and pedestrians
                </li>
                <li>
                  With increased Blood Alcohol Content (BAC), crash risk
                  increases sharply. A driver with a BAC of 0.08 is four times
                  as likely to cause a crash as a driver who has not been
                  drinking, while a driver with a BAC of 0.16 is 25 times as
                  likely to do so.
                </li>
                <li>
                  Young drinking drivers are at the highest risk of all. Drivers
                  20 years old or younger are almost three times as likely to be
                  involved in alcohol related fatal crashes than other drivers.
                </li>
              </ul>

              <h4 className="mb-3">
                How much can I drink before it affects my driving ability?
              </h4>
              <p>
                Any amount of drinking will begin to affect your judgment and
                coordination, and reduce your ability to judge distances,
                speeds, and angles. The degree of impairment depends on four
                basic factors:
              </p>
              <ul className="p-0">
                <li>The amount you drink.</li>
                <li>
                  Whether you've eaten before or while drinking (food slows
                  absorption).
                </li>
                <li>Your body weight. </li>
                <li>The length of time spent drinking. </li>
              </ul>

              <h4 className="mb-3">What is the quickest way to sober up?</h4>
              <p>
                The only way to reduce your BAC after drinking is to wait for
                your body to metabolize (eliminate) the alcohol – and that takes
                several hours. Your body metabolizes about one drink each hour.
                Coffee will not sober you up. Neither will a walk or a cold
                shower. They may make you feel more awake, but you will be just
                as impaired and it will be just as dangerous for you to drive.
              </p>

              <h4 className="mb-3">
                Are license penalties the same for all age groups?
              </h4>
              <p>
                No. If you are under 21 years old your driver license will be
                revoked for one year if you are convicted of any alcohol-related
                offense that occurred in New York State, or in any other state
                or a province of Canada. If you commit a second such offense
                while you are under 21, your license will be revoked for at
                least one year or until you are 21, whichever is longer.
              </p>
              <p>
                If you enroll in the Drinking Driver Program (DDP) and receive a
                conditional license, your license will remain in conditional
                status for the original full period of revocation.
              </p>
              <p>
                Your driver license or privilege of applying for a license will
                also be suspended if you are found guilty of using a New York
                State Driver License or Non-Driver Identification Card as proof
                of age to illegally purchase alcoholic beverages.
              </p>

              <h4 className="mb-3">
                What will happen to me if I am stopped by the police?
              </h4>
              <p>
                If you are stopped by a police officer who believes you are
                under the influence of alcohol or drugs, you may be required to
                take a "field sobriety test" which may include a
                breath-screening test for the presence of alcohol. If you are
                arrested, you will be asked to take a "chemical test" for BAC.
                You may also be fingerprinted.
              </p>
              <p>
                Driving While Intoxicated (DWI) is a crime. If you are
                convicted, you will face a substantial fine, a mandatory
                surcharge, license revocation, higher insurance premiums, and a
                possible jail sentence.
              </p>

              <h4 className="mb-3">
                What will happen if I refuse to take the chemical test?
              </h4>
              <p>
                If you refuse a chemical test for BAC (Blood Alcohol Content),
                your license will be suspended at arraignment in court, and
                revoked for at least one year (18 months for a commercial
                driver) at a Department of Motor Vehicles hearing. If you are
                age 21 or over, and you have a second refusal within five years
                of a previous refusal or alcohol conviction, your license is
                revoked for 18 months (permanent for a commercial driver).
              </p>
              <p>
                If you are under age 21, and you have a second refusal within
                five years of a previous refusal or alcohol conviction, your
                license is revoked for one year or until age 21, whichever is
                longer
              </p>
              <p>
                You will also be subject to a civil penalty of $500 (second
                offense, $750). A driver under 21 years old who refuses to take
                a chemical test under the Zero Tolerance Law is subject to a
                1-year license revocation and a $125 civil penalty. The
                penalties and fines for refusing to submit to a chemical test
                are separate from, and in addition to, the penalties and fines
                for alcohol or drug related convictions.
              </p>
            </div>
          </div>
        </div>

      </Container>
    </div>
  );
};
