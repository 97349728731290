import { useState, useEffect } from "react";

const defaultIdleTimeout = 30; // timeout for 30 minutes

function useLogoutOnIdle({ logoutCallback, idleTimeOut = defaultIdleTimeout }) {
  const [isIdle, setIsIdle] = useState(false);

  let logoutTimer;

  const resetTimeout = () => {
    if (logoutTimer) {
      clearTimeout(logoutTimer);
    }
    logoutTimer = setTimeout(logoutUser, Number(idleTimeOut) * 60 * 1000); // Set ideal timeout
  };

  const logoutUser = () => {
    setIsIdle(true);
    logoutCallback(); // Perform logout action
  };

  const handleUserActivity = () => {
    setIsIdle(false);
    resetTimeout();
  };

  useEffect(() => {
    // Initial setup and event listeners
    window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keydown", handleUserActivity);
    resetTimeout();

    // Cleanup
    return () => {
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keydown", handleUserActivity);
      clearTimeout(logoutTimer);
    };
  }, []); // Run this effect only once on component mount

  return {
    isIdle,
  };
}

export default useLogoutOnIdle;
