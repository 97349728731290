import React from 'react';
import { Button, Form } from 'react-bootstrap';

export const OtpModule = (props) => {
        // variables
        const {table_dot, mod, showEditModule, index, showEditIndexModule, search, com_time,   } = props
        // icons
        const {edit,deleteIcon, file, play_icon } = props
        // functions
        const { updateCourseTitle, editModule, set_completion_time_modules, updateModulesection, 
            handleUpdateModuleCancel, handleSaveModules, handleCancelModule, editSection, deleteModule, 
            handleAudioShow, handleDocUploadShow, getQuizQuestions} = props;

    return (
        <tr key={index + 1}>
            <td>
            <img
                src={table_dot}
                className="table_dot_icon"
                alt="Image"
            />
            {mod.sequence_number}
            </td>

            <td > {showEditModule && index == showEditIndexModule ?
                <div className="form-group input_box_css">
                    <Form.Control
                    type="text"
                    placeholder="Enter module name ..."
                    name='add_module_name'
                    value={mod.name}
                    className="col-sm"
                    onChange={e => updateCourseTitle(e.target.value, index)}
                    />
                </div> : <>{search ?<><div  className="width_data">{mod.name}</div></> :<a  onClick={(e) => { editModule(mod.id, mod.module_type, index) }} className="width_data">{mod.name}</a>}</>}
            </td>
            <td>
                {

                <div className="form-group table_input_drop_down" >

                    {/* {mod.module_type === 'typing_dna' ? "" : showEditModule && index == showEditIndexModule || mod.sub_module_id == '' ? 
                        <>
                            {
                            mod.module_type === 'security_questions'? 
                                <div className="input_dropdown"><a >{com_time? com_time: 0}</a></div>
                                :
                                <div className="input_dropdown inputnewwidth">
                                <input type="number" className="font_size"   min="1"  max="9999" name={`${index}`} onChange={e =>set_completion_time_modules(e, index) } /> <p>(Minute)</p></div>
                            }
                        </> 
                        :
                        <><div className="input_dropdown"><a >{com_time? com_time:0}</a></div></>
                    } */}

                    {/* {mod.sub_module_id == '' ?<><div className="input_dropdown"><input type="number" min="1" name={`${index}`} value={mod.completion_time} onChange={e => set_completion_time_modules(e, index)} pattern='^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$' /> </div></>: <></>} */}
                
                </div>

                }
            </td>

            
            {search ? "" : 
            <>
            <td>
                {showEditModule && index == showEditIndexModule ?   
                <> 
                    <Button className="save_btn btnSame save_button mr-2" variant="primary" onClick={() => updateModulesection(index, mod.id, mod.sequence_number)}>Update</Button> <Button variant="danger" className="save_btn btnSame save_button" onClick={() =>handleUpdateModuleCancel(index)}>Cancel</Button></> : <>
                    {mod.sub_module_id == '' ?
                        <> 
                            <Button className="save_btn save_btn_margin btnSame save_button" variant="primary" onClick={() => handleSaveModules(index)}>Save</Button>
                            <Button className="save_btn btnSame save_button" variant="danger" onClick={() => handleCancelModule(index)}>Cancel</Button>
                        </> : <></>
                    }

                    {/* {mod.module_type === 'typing_dna' ? "" : mod.sub_module_id != '' ?
                        <>
                            <div className="tableediticon mr-2">
                                <a onClick={() => editSection(index)}>
                                    <img src={edit} className="table_icon" alt="Image" />
                                </a>
                            </div>
                            <div className="tabledeleteicon">
                                <a className="tabledeleteicon" onClick={() => { deleteModule(mod.id) }}>
                                    <img src={deleteIcon} className="delete_icon" alt="Image" />
                                </a>                   
                            </div>
                        </> : <></>

                    } */}
                    {/* {mod.sub_module_id != '' && mod.module_type == 'grapejs' ?
                        <a onClick={(e) => handleAudioShow(mod.id, mod.grapej.audio_description_file_path)} className="icon_position">
                            <img src={play_icon} className="play_icon" alt="Image" />
                            {mod?.grapej?.audio_description_file_path ? <span className="table_count_icon1">1</span>: <span className="table_count_icon1">0</span>}
                        </a> : <></>
                    } */}

                    {/* {mod.sub_module_id != '' && mod.module_type == 'grapejs' ?
                    <a onClick={(e) => handleDocUploadShow(mod.id, mod.grapej.audio_description_file_path)}>
                        <img src={play_icon} className="play_icon" alt="Image" />
                    </a> : <></>}   */}

                
                    {/* {mod.sub_module_id != '' && mod.module_type == 'grapejs' ?
                        <div className="tableediticon mr-2">
                            <a onClick={()=>handleDocUploadShow(mod.id, mod.course_module_documents)} className="icon_position file_icon">
                            <img src={file} className="table_icon" alt="Image" />
                            <span className="table_count_icon">{mod?.course_module_documents.length}</span>
                            </a>
                        </div>
                        : <></>
                    } */}

                    {mod.module_type === 'otp' ? 
                    
                        mod.sub_module_id != '' ?
                        <>
                            <div className="typingDnaclass"></div>
                            <div className="typingDnaclass">
                                <a onClick={() => { deleteModule(mod.id) }}>
                                <img src={deleteIcon} style={{"margin-left": "42px"}} className="delete_icon" alt="Image" />
                                </a>
                            </div>
                        </> 
                            : <></> 
                            : ''
                    }
                </>
                }
            </td>
            {/* <td>
                { mod.sub_module_id != '' && mod.module_type == 'grapejs' ?
                    <div className="add_buttons">
                        <button onClick={e => getQuizQuestions(mod)}>+ Add Quiz</button>
                    </div> : <></>
                }
            </td> */}
            </>}
      </tr>
    )
}
