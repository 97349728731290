import React from 'react'
import { Button, Form } from 'react-bootstrap';

export const AddModule = (props) => {
    // variables
    const {src, moduleList, moduleName, completionTime  } = props;
    // functions
    const {set_add_module_name, set_completion_time,  handleSaveInputModule, handleCancelInputModule } = props
    return (
        <tr>
        <td>
          <img
            src={src}
            className="table_dot_icon"
            alt="Image"
          />
          {moduleList.length + 1}
        </td>
        <td>
          <div className="form-group input_box_css">
            <Form.Control
              type="text"
              placeholder="Enter module name ..."
              name='add_module_name'
              value={moduleName}
              onChange={(e) => { set_add_module_name(e.target.value) }}
            />
          </div>
        </td>
        <td>
          <div className="form-group table_input_drop_down" >
            <div className="input_dropdown table_input_drop_down1111111 inputnewwidth">
              <input type="number" min="1" value={completionTime} className="font_size" onChange={e => set_completion_time(e.target.value)} pattern='^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$' /><p>(minute) </p>
            </div><div className="select_input">
            </div>
          </div>
        </td>
        <td>
          {/* <button className="save_btn" onClick={handleSaveModule}>Save</button> */}
          <Button className="btnSame save_button btn btn-primary mr-2" variant="primary" onClick={()=>handleSaveInputModule()}>Save</Button>
          <Button className="save_btn" variant="danger" className="btnSame save_button" onClick={() => handleCancelInputModule(moduleList.length)}>Cancel</Button>
        </td>
      </tr>
    )
}
