import React from 'react'

export const MultipleChoice = (props) => {
    // variables
    const {index, quiz, _is_disabled } = props
    // functions
    const {handleUserInput} = props

    return (
        <div key={`multiple_choice_question9-${index}`} className="question" >
            <h5>Q.{index + 1}. {quiz.question ? quiz.question : 'Quiz Question ?'}</h5>
            <div className="custom-radio-wrap">
            <form>
                {quiz.options ? quiz.options.map((opt, i) => {

                return (<>
                    <div className="form-group" key={`multiple_choice_question9_answer-${i}`}>
                    <input id={`${opt.id}_${i}`} type="checkbox" name="custom-radio-btn" disabled={_is_disabled} onChange={(e) => handleUserInput(quiz.id, opt.id, e, quiz.quiz_type)} />
                    <label className="custom-radio" htmlFor={`${opt.id}_${i}`}></label>
                    <span className="label-text">{opt.option ? opt.option : `option ${i + 1}`}</span>
                    </div>
                </>)
                }) : null
                }
            </form>
            </div>
        </div>
    )
}
