import React, { Component } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import WorkAreaSign_area from "../../../../Images/module5/TrafficSigns/areas/WorkAreaSign.jpg";
import RailRoadCrossing_area from "../../../../Images/module5/TrafficSigns/areas/RailRoadCrossing.jpg";
import StopSign_area from "../../../../Images/module5/TrafficSigns/areas/StopSign.jpg";
import WarningSign_area from "../../../../Images/module5/TrafficSigns/areas/WarningSign.jpg";
import RegulationSign_area from "../../../../Images/module5/TrafficSigns/areas/RegulationSign.jpg";
import YieldSign_area from "../../../../Images/module5/TrafficSigns/areas/YieldSign.jpg";
import WorkAreaSign_element from "../../../../Images/module5/TrafficSigns/elements/WorkAreaSign.jpg";
import RailRoadCrossing_element from "../../../../Images/module5/TrafficSigns/elements/RailRoadCrossing.jpg";
import StopSign_element from "../../../../Images/module5/TrafficSigns/elements/StopSign.jpg";
import WarningSign_element from "../../../../Images/module5/TrafficSigns/elements/WarningSign.jpg";
import RegulationSign_element from "../../../../Images/module5/TrafficSigns/elements/RegulationSign.jpg";
import YieldSign_element from "../../../../Images/module5/TrafficSigns/elements/YieldSign.jpg";
class TaskList extends Component {

  static correctPairs = [
    { name: "Work Area Sign", assignedArea: 0 },
    { name: "Rail Road Crossing", assignedArea: 1 },
    { name: "Stop Sign", assignedArea: 2 },
    { name: "Warning Sign", assignedArea: 3 },
    { name: "Regulation Sign", assignedArea: 4 },
    { name: "Yield Sign", assignedArea: 5 },
  ];

  constructor(props) {
    super(props);
    this.state = {
      droppedItems: Array(6).fill(null),
      draggableItems: [
        { name: "Work Area Sign", assignedArea: 0 },
        { name: "Rail Road Crossing", assignedArea: 1 },
        { name: "Stop Sign", assignedArea: 2 },
        { name: "Warning Sign", assignedArea: 3 },
        { name: "Regulation Sign", assignedArea: 4 },
        { name: "Yield Sign", assignedArea: 5 },
      ],
      correctList: [],
      incorrectList: [],
      buttonEnabled: false,
      submissionCompleted: false,
      occupiedAreas: Array(6).fill(false),
    };
  }
  getImageUrlForArea(index) {
    const imageUrls = [
      WorkAreaSign_area,
      RailRoadCrossing_area,
      StopSign_area,
      WarningSign_area,
      RegulationSign_area,
      YieldSign_area,
    ];
    return imageUrls[index];
  }
  getImageUrlForElement(name) {
    switch (name) {
      case "Work Area Sign":
        return WorkAreaSign_element;
      case "Rail Road Crossing":
        return RailRoadCrossing_element;
      case "Stop Sign":
        return StopSign_element;
      case "Warning Sign":
        return WarningSign_element;
      case "Regulation Sign":
        return RegulationSign_element;
      case "Yield Sign":
        return YieldSign_element;
      default:
        return null;
    }
  }

  shuffleArray = (array) => {
    const newArray = [...array];
    for (let i = newArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }
    return newArray;
  };

  componentDidMount() {
    const shuffledItems = this.shuffleArray(this.state.draggableItems);
    this.setState({ draggableItems: shuffledItems });
    sessionStorage.setItem("HAS_H5P_ACTIVITY", true);
    sessionStorage.setItem("HAS_H5P_ACTIVITY_SUBMIT", false);
  }

  onDragEnd = (result) => {
    const { destination } = result;
    if (!destination) {
      return;
    }
    const { draggableItems, droppedItems, occupiedAreas } = this.state;
    const item = draggableItems.find(item => item.name === result.draggableId);
    const destinationIndex = destination.droppableId.substring(10);
    if (droppedItems[destinationIndex]) {
      return;
    }
    const newDroppedItems = [...droppedItems];
    newDroppedItems[destinationIndex] = { ...item, assignedArea: parseInt(destinationIndex) };
    const newOccupiedAreas = [...occupiedAreas];
    newOccupiedAreas[destinationIndex] = true;
    const newDraggableItems = draggableItems.map((draggableItem) => {
      if (draggableItem.name === item.name) {
        if (destination.droppableId === 'draggable-list') {
          return { ...draggableItem, disabled: false };
        } else {
          return { ...draggableItem, disabled: true };
        }
      }
      return draggableItem;
    });
    this.setState({
      droppedItems: newDroppedItems,
      occupiedAreas: newOccupiedAreas,
      draggableItems: newDraggableItems,
      buttonEnabled: newDroppedItems.filter(item => item !== null).length === 6,
    });
  };

  removeFromDroppable = (index) => {
    if (!this.state.submissionCompleted) {
      const { droppedItems, draggableItems } = this.state;
      const newDroppedItems = [...droppedItems];
      newDroppedItems[index] = null;

      const droppedItem = droppedItems[index];
      if (droppedItem) {
        const itemName = droppedItem.name;
        const newDraggableItems = draggableItems.map((draggableItem) => {
          if (draggableItem.name === itemName) {
            return { ...draggableItem, disabled: false };
          }
          return draggableItem;
        });

        this.setState({
          droppedItems: newDroppedItems,
          draggableItems: newDraggableItems,
        });
      }
    }
  };

  updateLists = (item, index) => {
    const correctItem = this.state.draggableItems.find(
      (pair) => pair.assignedArea === index && pair.name === item.name
    );
    const { correctList, incorrectList } = this.state;
    if (correctItem) {
      this.setState({
        correctList: [...correctList, correctItem],
      });
    } else {
      const incorrectItem = this.state.draggableItems.find(
        (pair) => pair.assignedArea === index
      );
      this.setState({
        incorrectList: [...incorrectList, incorrectItem],
      });
    }
  }

  checkDroppedItems = () => {
    const { droppedItems } = this.state;

    for (let i = 0; i < droppedItems.length; i++) {
      const droppableElement = document.querySelector(`.drop-area-${i}`);
      if (droppableElement) {
        droppableElement.classList.remove("correct-area", "incorrect-area");
      }
    }

    droppedItems.forEach((item, index) => {
      if (!item) return;
      const droppableElement = document.querySelector(`.drop-area-${index}`);
      if (!droppableElement) return;
      const correctPair = TaskList.correctPairs.find(pair => pair.name === item.name);
      if (!correctPair) return;
      if (correctPair.assignedArea === index) {
        droppableElement.classList.add("correct-area");
      } else {
        droppableElement.classList.add("incorrect-area");
      }
    });

    sessionStorage.setItem("HAS_H5P_ACTIVITY_SUBMIT", true);
    this.setState({ submissionCompleted: true }); // Set submissionCompleted to true
  };


  render() {
    const { droppedItems, draggableItems, buttonEnabled, submissionCompleted } = this.state;
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <div className="droppable-section">
            {droppedItems.map((item, index) => (
              <div key={index}>
                {!item ? (
                  <Droppable droppableId={`droppable-${index}`}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        className={`flex-container drop-area drop-area-${index}`}
                        style={{ background: snapshot.isDraggingOver ? 'lightblue' : 'transparent' }}
                        onClick={() => !submissionCompleted && this.removeFromDroppable(index)}
                      >
                        <ul>
                          <li
                            className="drop-area-image"
                            style={{
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                            }}
                          >
                            <img src={this.getImageUrlForArea(index)} alt={`Area ${index}`} style={{ maxWidth: "100px", maxHeight: "100px" }} />
                          </li>
                          <li
                            className={`draggable-image DropArea drop-area-image-${index}`}
                            style={{ cursor: "pointer" }}
                          >
                            {item && (
                              <img src={this.getImageUrlForElement(item.name)} alt={item.name} style={{ maxWidth: "100px", maxHeight: "100px" }} />
                            )}
                          </li>
                        </ul>
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                ) : (
                  <div
                    key={index}
                    className={`flex-container drop-area drop-area-${index}`}
                    style={{ background: 'transparent' }}
                    onClick={() => !submissionCompleted && this.removeFromDroppable(index)}
                  >
                    <ul>
                      <li
                        className="drop-area-image"
                        style={{
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                        }}
                      >
                        <img src={this.getImageUrlForArea(index)} alt={`Area ${index}`} style={{ maxWidth: "100px", maxHeight: "100px" }} />
                      </li>
                      <li
                        className={`draggable-image DropArea drop-area-image-${index}`}
                        style={{ cursor: "pointer" }}
                      >
                        {item && (
                          <img src={this.getImageUrlForElement(item.name)} alt={item.name} style={{ maxWidth: "100px", maxHeight: "100px" }} />
                        )}
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            ))}
          </div>

          <div className="draggable-section">
            <Droppable droppableId="draggable-list" direction="horizontal">
              {(provided) => (
                <ul className="signal-ul" style={{ listStyle: "none", padding: 0 }} ref={provided.innerRef} {...provided.droppableProps}>
                  {draggableItems.map((item, index) => (
                    <Draggable
                      key={item.name}
                      draggableId={item.name}
                      index={index}
                      isDragDisabled={submissionCompleted || item.disabled}
                    >
                      {(provided) => (
                        <li
                          className={`draggable-image ${item.disabled ? 'disabled-icon' : ''}`}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <img src={this.getImageUrlForElement(item.name)} alt={item.name} style={{ maxWidth: "100px", maxHeight: "100px" }} />
                        </li>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
          </div>
          <button onClick={this.checkDroppedItems} disabled={!buttonEnabled || submissionCompleted}>
            Check Answer
          </button>
        </div>
      </DragDropContext>
    );
  }
}

export default TaskList;