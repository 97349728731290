import { Button, Container, Row, Col } from 'react-bootstrap';
import { Link, useHistory, useParams } from "react-router-dom";


const Congratulations = () => {
    const history = useHistory();
    let params = useParams();
  return (
    <Container className="d-flex justify-content-center align-items-center vh-100">
      <Row className="text-center">
        <Col xs={12} md={8} lg={6} className="mx-auto">
          <div className="p-4 shadow-sm rounded bg-white">
            <h1 className="text-success mb-3">Congratulations!</h1>
            <p className="lead">
              You have successfully completed the NY 5-Hour Pre-Licensing Course!
            </p>
            <p className="text-muted">
              A confirmation email has been sent to your registered email for your records.
            </p>
            <Button
              variant="success"
              size="lg"
              onClick={() => history.push(`/user/dashboard/${params.course_id}`)}
            >
              Back to Dashboard
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Congratulations;




// import './Congratulations.css';

// const CongratulationsPage = () => {
//   return (
//     <div className="congrats-page">
//       <div className="congrats-container">
//         <h1 className="congrats-title">Congratulations!</h1>
//         <p className="congrats-message">
//           You have successfully completed the NY 5-Hour Pre-Licensing Course!
//         </p>
//         <p className="congrats-submessage">
//           A confirmation email has been sent to your registered email for your records.
//         </p>
//         <button
//           className="back-to-dashboard-btn"
//           onClick={() => window.location.href = '/dashboard'} // Example: Redirect to dashboard
//         >
//           Back to Dashboard
//         </button>
//       </div>
//     </div>
//   );
// };

// export default CongratulationsPage;
