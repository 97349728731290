import { userOtpService } from "../../../Services/otpService";
import { loadingAction } from "../LoadingAction";
import { OTP_SEND, OTP_VERIFY } from "../ActionTypes/UserOtpTypes";

export const sendOtpAction = (detail) => {
    return (dispatch) => {
      dispatch(loadingAction(true));
      return userOtpService.sendOtp(detail)
        .then(response => {
      
          dispatch(loadingAction(false));
          return dispatch({ type: OTP_SEND, payload: response });
        })
        .catch(err => {
          dispatch(loadingAction(false));
          throw err;
        });
    };
  };


  export const verifyOtpAction = (detail) => {
    return (dispatch) => {
      dispatch(loadingAction(true));
      return userOtpService.verifyOtp(detail)
        .then(response => {
      
          dispatch(loadingAction(false));
          return dispatch({ type: OTP_VERIFY, payload: response });
        })
        .catch(err => {
          dispatch(loadingAction(false));
          throw err;
        });
    };
  };  