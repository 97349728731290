import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import "./UserQuiz.css";
import {
  getUserQuizByIdAction,
  quizQuestionAnswerVerifyAction,
  securityQuestionAnswerVerifyAction,
  tableofContentDetailAction,
  tableofContentQuizDetailAction,
  tableofContentSecurityDetailAction,
  userForgotPasswordAction,
  getUserQuizModuleByIdAction,
  socketStartModuleAction,
  socketEmitStopModuleAction,
  socketEmitStartModuleAction,
  getUserDetailByTokenAction,
  checkUserSecurityQuestionDoneOrNotAction,
  checkUserAction,
  logoutUserAction,
  socketDisconnectAction,
  userPaymentStatusCheckAction,
  socketMarkModuleComplete,
  typing_dna_session_verified_userAction,
  quizAttemptsAction,
  socketResetCourse,
  socketLockCourse,
} from "../../../Redux/Actions";
import swal from "sweetalert";
import time_clock from "../../../Images/time_clock.svg";
import {
  getModuleIdOfQuizSequence,
  secondToMinuteOrHourConvert,
  setItemInSessionStorage,
} from "../../../Utils/Util";
import { isBrowser, isMobile } from "react-device-detect";
import { QuizQuestion, SingleChoice } from "./QuizComponents/SingleChoice";
import { MultipleChoice } from "./QuizComponents/MultipleChoice";

const UserQuiz = () => {
  useEffect(() => {
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function () {
      window.history.go(1);
    };
    window.document.onkeydown = function (event) {
      if (event.keyCode == 116) {
        event.preventDefault();
      }
      if (event.metaKey && event.keyCode == 82) {
        event.preventDefault();
      }
      if (event.metaKey && event.keyCode == 87) {
        event.preventDefault();
      }
    };
  }, []);

  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();

  const beforeUnload = (e) => {
    dispatch(logoutUserAction());
    history.push(`/user/signin/${course_id}`);
    var confirmationMessage = "You are not allowed to refresh";
    (e || window.event).returnValue = confirmationMessage; //Gecko + IE
    return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc
  };

  const socket = useSelector((state) => {
    return state.userSocketioReducer?.socketConnectSuccess?.socketInstance;
  });
  const module_time_obj = useSelector((state) => {
    return state.userSocketioReducer?.socketTimerSuccessResponse;
  });
  const timeUpObj = useSelector((state) => {
    return state.userSocketioReducer?.socketTimerUpResponse;
  });
  const quiz_time = useSelector((state) => {
    return state.userSocketioReducer?.socketTimerSuccessResponse?.time;
  });
  const quiz_timer_id = useSelector((state) => {
    return state.userSocketioReducer?.socketTimerSuccessResponse?.module_id;
  });
  const quiz_id = useSelector((state) => {
    return state.tableofContentReducer?.tableofContentDetailQuizId?.data?.id;
  });
  const module_list = useSelector((state) => {
    return state.tableofContentReducer?.tableofContentDetailList?.data;
  });
  const course_data = useSelector((state) => {
    return state.userCourseReducer?.courseList?.data?.data?.rows[0];
  });
  const quiz_attempts = useSelector((state) => {
    return state.userQuizQuestionRecucer?.quizAttempts;
  });

  const module_id = params?.module_id;
  const course_id = params?.course_id;

  const [last_module_check, set_last_module_check] = useState(false);
  const [current_module_index, set_current_module_index] = useState("");
  const [content_id, set_content_id] = useState("");
  const [quizChanged, setQuizChanged] = useState(false);
  const [parent_module_id, set_parent_module_id] = useState("");

  //varibale declare
  const [quizQuestionList, setQuizQuestionList] = useState([]);
  //  User Response Data
  const [userResponse, setUserResponse] = useState([]);
  const [verificationResponse, setVerificationResponse] = useState({});
  const [userResult, setUserResult] = useState("fail");
  const [send_ans_once, set_send_ans_once] = useState(false);
  const [sequenceNumber, setSequenceNumber] = useState();
  const [resultShow, setResultShow] = useState(false);
  const handleResultShow = () => setResultShow(true);
  const handleResulthide = () => setResultShow(false);
  const [total_answered, set_total_answered] = useState(0);
  const [total_correct_ans, set_total_correct_ans] = useState(0);
  const [answer_percentage, set_answer_percentage] = useState("");
  const [attempts_remaining, set_attempts_remaining] = useState(0);
  const [completion_time, set_completion_time] = useState();

  // from the question list
  // const [attempts_and_re_attempts, set_attempts_and_re_attempts] = useState('');
  const [after_completion_of_attempts, set_after_completion_of_attempts] =
    useState("");
  const [grade_to_pass, set_grade_to_pass] = useState("");
  const [title, set_title] = useState("");
  const [module_name, set_module_name] = useState("");
  const [module_status, set_module_status] = useState("");
  const [timer_started, set_timer_started] = useState(false);

  // useEffect(() => {
  //   getCheckDetails()
  //   paymentStatusCheck()
  // }, [])

  // const paymentStatusCheck = async () => {
  //   var paymentResponse = await dispatch(userPaymentStatusCheckAction(params?.course_id))
  //   paymentResponse = paymentResponse?.payload;
  //   if (paymentResponse?.data?.data?.is_paid) {
  //   } else {
  //     swal({
  //       title: "Error!",
  //       text: 'Your payment is due.',
  //       icon: "error",
  //       timer: 5000
  //     });
  //     dispatch(logoutUserAction())
  //     history.push(`/user/signin/${params.course_id}`)
  //   }
  // }

  // const getCheckDetails = async () => {
  //   try {
  //     // check other detail pending or not
  //     let userDetailResponse = await dispatch(getUserDetailByTokenAction())
  //     userDetailResponse = userDetailResponse?.payload;
  //     userDetailResponse = userDetailResponse?.data?.data
  //     if (userDetailResponse.is_active) {
  //       if (!userDetailResponse.address_line_1 || !userDetailResponse.first_name || !userDetailResponse.last_name || !userDetailResponse.user_name || !userDetailResponse.email ||
  //         !userDetailResponse.date_of_birth || !userDetailResponse.country || !userDetailResponse.mobile_number || !userDetailResponse.state ||
  //         !userDetailResponse.zipcode || !userDetailResponse.permit_first_name || !userDetailResponse.permit_last_name || !userDetailResponse.permit_suffix || !userDetailResponse.class_of_permit || !userDetailResponse.permit_expiration_date || !userDetailResponse.dmv_id || !userDetailResponse.document_no) {
  //         history.push(`/user/profile/${params?.course_id}`);
  //       } else {
  //         var securityQuestionResponse = await dispatch(checkUserSecurityQuestionDoneOrNotAction())
  //         securityQuestionResponse = securityQuestionResponse?.payload;
  //         if (!securityQuestionResponse?.data?.data?.isDone) {
  //           history.push(`/user/setupsecurityquestion/${params?.course_id}`)
  //           return false
  //         } else {
  //           var typingDnaResponse = await dispatch(checkUserAction())
  //           typingDnaResponse = typingDnaResponse?.payload;
  //           if(isBrowser){
  //             if (typingDnaResponse?.data?.count < 3) {
  //               history.push(`/user/typingdna/${params?.course_id}`)
  //               return false
  //             }else {
  //               let typingDnaAuthUserResponse = await dispatch(typing_dna_session_verified_userAction())
  //               typingDnaAuthUserResponse = typingDnaAuthUserResponse?.payload;
  //               if (!typingDnaAuthUserResponse?.data?.is_typingDNA_verified) {
  //                 history.push(`/user/verify/${params?.course_id}`)
  //                 return false
  //               }
  //             }
  //           } else if(isMobile){
  //              if (typingDnaResponse?.data?.mobilecount < 3){
  //               history.push(`/user/typingdna/${params?.course_id}`)
  //               return false
  //             } else {
  //               let typingDnaAuthUserResponse = await dispatch(typing_dna_session_verified_userAction())
  //               typingDnaAuthUserResponse = typingDnaAuthUserResponse?.payload;
  //               if (!typingDnaAuthUserResponse?.data?.is_typingDNA_verified) {
  //                 history.push(`/user/verify/${params?.course_id}`)
  //                 return false
  //               }
  //             }
  //           }
  //         }
  //       }
  //     } else {
  //       swal({
  //         title: "Error!",
  //         text: 'Please verify your account',
  //         icon: "error",
  //         timer: 5000
  //       });
  //       dispatch(socketDisconnectAction(socket))
  //       dispatch(logoutUserAction())
  //       history.push(`/user/signin/${course_id}`)
  //     }
  //   }
  //   catch (err) {
  //     swal({
  //       title: "Error!",
  //       text: err.response.data.err,
  //       icon: "error",
  //       timer: 5000
  //     });
  //     history.push(`/user/signin/${course_id}`)
  //     dispatch(socketDisconnectAction(socket))
  //     dispatch(logoutUserAction())
  //   }
  // }

  // ===============================================
  useEffect(() => {
    if (sessionStorage.getItem("userAccessToken")) {
      console.log(course_data);
      if (params.module_id) {
        setQuizQuestionList("");
        set_grade_to_pass("");
        set_title("");
        setUserResponse([]);
        getQuizModuleDetail(params.module_id);
        getUserQuizQuestion(params.quiz_id);
      }
      return () => {
        dispatch(socketEmitStopModuleAction(socket, params.module_id));
      };
    }
  }, [params, socket, quizChanged]);

  useEffect(() => {
    if (timer_started) {
      if (!send_ans_once) {
        if (
          module_time_obj.module_id == module_id &&
          module_time_obj.time <= 0
        ) {
          testsample();
        } else if (
          timeUpObj &&
          timeUpObj.module_id === module_id &&
          timeUpObj.timeSpent >= completion_time
        ) {
          testsample();
        }
      }
    }
  }, [module_time_obj, timeUpObj, timer_started]);

  const testsample = () => {
    // when attempts are over
    set_send_ans_once(true);
    swal({
      title: "Fail!",
      text: "You ran out of time to answer the question(s), You need to restart the course from the beginning",
      icon: "error",
      timer: 10000,
    }).then(() => {
      if (timer_started) {
        dispatch(socketEmitStopModuleAction(socket, module_id));
        dispatch(socketLockCourse(socket, course_id));
        // also send emit to reset module/course based on after_completion_of_attempts
      }
      handleModuleNavigation("fail", after_completion_of_attempts);
    });
    // let newArr = [];
    // if (userResponse !== []) {
    //   newArr = [...userResponse];
    //   quizQuestionList.forEach((q, i) => {
    //     let responseObj = { question_id: q.id, answer_ids: [] };
    //     if (
    //       newArr.some((qu) => {
    //         return qu.question_id == q.id;
    //       })
    //     ) {
    //       return false;
    //     } else {
    //       newArr.push(responseObj);
    //     }
    //   });
    // } else {
    //   newArr = [];
    //   quizQuestionList.forEach((quest, i) => {
    //     let responseObj = { question_id: quest.id, answer_ids: [] };
    //     newArr.push(responseObj);
    //   });
    // }
    // setUserResponse(newArr);
    // handleResponseSubmitApi(newArr);
  };

  const getUserQuizQuestion = async (id) => {
    try {
      const response = await dispatch(getUserQuizByIdAction(id));
      const quizDetail = response?.payload;
      if (quizDetail) {
        if (quizDetail.data) {
          if (quizDetail.data.code == 200) {
            // from gobal state : userReducer
            // attempts and action after attempt is coming from course level
            // set_attempts_remaining(course_data?.attempts_and_re_attempts);
            // set_attempts_and_re_attempts(course_data?.attempts_and_re_attempts);

            setQuizQuestionList(quizDetail?.data?.data?.questions);
            set_grade_to_pass(quizDetail?.data?.data?.grade_to_pass);
            set_title(quizDetail?.data?.data?.title);
            if (quizDetail?.data?.data?.questions) {
              let quizLIst = quizDetail.data.data.questions;
              let newArr = [];
              quizLIst.forEach((quest, i) => {
                let responseObj = { question_id: quest.id, answer_ids: [] };
                newArr.push(responseObj);
              });
              setUserResponse(newArr);
            }
            set_after_completion_of_attempts(
              course_data?.after_completion_of_quiz_attempts
            );

            handleResulthide();
            set_total_answered(0);
            set_total_correct_ans(0);
            set_answer_percentage("");
          } else {
            swal({
              title: "Error!",
              text: quizDetail.data.message,
              icon: "error",
              timer: 6000,
            });
          }
        }
      }
    } catch (err) {
      if (err?.response?.data?.code === 401) {
        swal({
          title: "Error!",
          text: err?.response?.data?.err,
          icon: "error",
          timer: 5000,
        }).then(() => {
          history.push(`/user/signin/${course_id}`);
          dispatch(socketDisconnectAction(socket));
          dispatch(logoutUserAction());
        });
      }
    }
  };

  useEffect(() => {
    getModuleQuizAttempts();
  }, [parent_module_id]);

  const getModuleQuizAttempts = () => {
    const moduleObj = module_list.find((mod) => mod.id === module_id);
    let parentModule = module_list.filter(
      (module) =>
        Math.trunc(module?.sequence_number) ==
        Math.trunc(
          moduleObj?.sequence_number && module.module_type === "grapejs"
        )
    );
    let QuizList = module_list.filter(
      (module) =>
        Math.trunc(module?.sequence_number) ==
          Math.trunc(moduleObj?.sequence_number) &&
        module.module_type === "quiz"
    );
    set_attempts_remaining(QuizList.length);
    // this ensures that attmept number is called only once for a parent module
    if (parentModule && parentModule.length > 0) {
      set_parent_module_id(parentModule[0].id);
    }
  };

  const getQuizModuleDetail = async (id) => {
    try {
      const response = await dispatch(getUserQuizModuleByIdAction(id));
      const quizModuleDetail = response?.payload;

      if (quizModuleDetail) {
        if (quizModuleDetail.data) {
          if (quizModuleDetail.data.code == 200) {
            const {
              completion_time,
              module_type,
              name,
              users,
              course_id,
              sequence_number,
            } = quizModuleDetail.data.data;
            set_module_name(name);
            set_completion_time(completion_time);
            setSequenceNumber(sequence_number);
            if (users[0]?.user_course_module?.status) {
              let status = users[0]?.user_course_module?.status;
              set_module_status(status);
              if (status == "IN_PROGRESS") {
                dispatch(socketEmitStartModuleAction(socket, id));
                set_timer_started(true);
              }
            } else {
              dispatch(socketEmitStartModuleAction(socket, id));
              set_timer_started(true);
            }
          } else {
            swal({
              title: "Error!",
              text: quizModuleDetail.data.message,
              icon: "error",
              timer: 6000,
            });
          }
        }
      }
    } catch (err) {
      if (err?.response?.data?.code === 401) {
        swal({
          title: "Error!",
          text: err?.response?.data?.err,
          icon: "error",
          timer: 5000,
        }).then(() => {
          history.push(`/user/signin/${course_id}`);
          dispatch(socketDisconnectAction(socket));
          dispatch(logoutUserAction());
        });
      }
    }
  };

  // save input
  const handleUserInput = (question_id, option_id, e, type) => {
    if (type == "multiple_choice_question") {
      let newResponse = [...userResponse];
      let quiz_index = newResponse.findIndex(
        (id) => id.question_id == question_id
      );
      let responseObj = { question_id: question_id, answer_ids: [option_id] };

      if (e.target.checked) {
        if (userResponse.some((id) => id.question_id == question_id)) {
          let new_ans_ids = [...newResponse[quiz_index]?.answer_ids];
          newResponse[quiz_index] = {
            ...newResponse[quiz_index],
            answer_ids: [...new_ans_ids, option_id],
          };
          setUserResponse(newResponse);
        } else {
          userResponse.push(responseObj);
        }
      } else {
        let new_ans_ids = [...newResponse[quiz_index]?.answer_ids];
        if (new_ans_ids) {
          var index_ = new_ans_ids.findIndex(function (o) {
            return o === option_id;
          });

          if (index_ !== -1) {
            new_ans_ids.splice(index_, 1);
          }
          newResponse[quiz_index] = {
            ...newResponse[quiz_index],
            answer_ids: [...new_ans_ids],
          };
          setUserResponse(newResponse);
        }
      }
    }
    if (type == "single_choice_question") {
      let responseObj = { question_id: question_id, answer_ids: [option_id] };
      if (userResponse.some((id) => id.question_id == question_id)) {
        let newResponse = [...userResponse];
        let quiz_index = newResponse.findIndex(
          (id) => id.question_id == question_id
        );
        newResponse[quiz_index] = {
          ...newResponse[quiz_index],
          answer_ids: [option_id],
        };
        setUserResponse(newResponse);
      } else {
        userResponse.push(responseObj);
      }
    }
  };

  //  check all questions are answered
  const handleResponseSubmit = () => {
    if (attempts_remaining > 0) {
      let errorMsg = "";
      if (errorMsg == "") {
        swal("Are you sure to submit your response?", {
          buttons: {
            cancel: "Cancel",
            button: {
              text: "Submit",
              value: true,
            },
          },
          timer: 5000,
          icon: "info",
        }).then((value) => {
          if (value) {
            handleResponseSubmitApi(userResponse);
          }
        });
      }
    }
  };

  const handleResponseSubmitApi = async (userresponse) => {
    try {
      let user_response = {
        module_id: module_id,
        answers: [...userresponse],
      };

      const response = await dispatch(
        quizQuestionAnswerVerifyAction(user_response)
      );
      const resultResponse = response?.payload;
      if (resultResponse) {
        if (resultResponse.data) {
          if (resultResponse.data.code == 200) {
            const { pass, percentage } = resultResponse.data.data;
            setVerificationResponse(resultResponse.data.data);
            if (pass) {
              handleResultShow();
              set_answer_percentage(percentage.toString());
              setUserResult("pass");
              swal({
                title: "Passed",
                text: resultResponse.data.message,
                icon: "success",
                timer: 4000,
              }).then(() => {
                if (timer_started) {
                  const quizRelatedModule = getModuleIdOfQuizSequence(
                    sequenceNumber,
                    module_list
                  );
                  dispatch(
                    socketMarkModuleComplete(socket, quizRelatedModule.id)
                  );
                  dispatch(socketMarkModuleComplete(socket, module_id));
                  dispatch(socketEmitStopModuleAction(socket, module_id));
                }
                handleModuleNavigation("next", "");
              });
            }
            //  data received ??
          } else if (resultResponse.data.code == 400) {
            const { pass, percentage } = resultResponse.data.data;
            handleResultShow();
            setUserResult("fail");
            let failedList = getFailedQuizList() || [];
            let attemptsRemaining = attempts_remaining - failedList.length;
            console.log(failedList, attemptsRemaining);
            // dispatch(quizAttemptsAction(attemptsRemaining))
            // set_attempts_remaining(attemptsRemaining);

            if (quiz_timer_id == module_id && quiz_time <= 0) {
              // tested:working - when time is over
              if (timer_started) {
                dispatch(socketEmitStopModuleAction(socket, module_id));
                dispatch(socketLockCourse(socket, course_id));
              }

              swal({
                title: "Fail!",
                text: `Time up. You have scored ${percentage}%`,
                icon: "error",
                timer: 6000,
              }).then(() => {
                handleModuleNavigation("fail", after_completion_of_attempts);
              });
            } else if (attemptsRemaining === 0 || attemptsRemaining === 1) {
              // when attempts are over
              if (timer_started) {
                dispatch(socketEmitStopModuleAction(socket, module_id));
                dispatch(socketLockCourse(socket, course_id));
                // also send emit to reset module/course based on after_completion_of_attempts
              }
              swal({
                title: "Fail!",
                text: "Sorry, you are prohibited from completing the course due to consecutive failed attempts. You need to restart the course from the beginning",
                icon: "error",
                timer: 360000,
              }).then(() => {
                handleModuleNavigation("fail", after_completion_of_attempts);
              });
            } else if (attemptsRemaining > 1) {
              // when time is remaining + attempt is remaining
              // this is called if attempts are still remaining: this gets another quiz
              swal({
                title: "Fail!",
                text: `You have scored ${percentage}%`,
                icon: "error",
                timer: 6000,
              }).then(() => {
                setFailedQuizId(module_id);
                handleModuleNavigation("random_quiz", "");
              });
            } else {
              swal({
                title: "Error!",
                text: resultResponse.data.message,
                icon: "error",
                timer: 6000,
              });
            }
          }
        }
      }
    } catch (err) {
      if (timer_started) {
        dispatch(socketEmitStopModuleAction(socket, module_id));
      }
      if (err?.response?.data?.code === 401) {
        swal({
          title: "Error!",
          text: err?.response?.data?.err,
          icon: "error",
          timer: 5000,
        }).then(() => {
          history.push(`/user/signin/${course_id}`);
          dispatch(socketDisconnectAction(socket));
          dispatch(logoutUserAction());
        });
      }
    }
  };

  const handleModuleNavigation = (navigation, after_completion) => {
    if (module_list) {
      var list = [...module_list];
      if (navigation === "fail" || navigation === "next") {
        setItemInSessionStorage("failedQuizList", JSON.stringify([]));
      }
      // find index of module
      var index = list.findIndex((obj) => {
        if (obj.id == module_id) {
          return obj;
        }
      });
      set_current_module_index(index);

      // check if this module is the last module in the list
      var islastModule = list.length - index;
      if (islastModule == 1) {
        set_last_module_check(true);
      } else {
        set_last_module_check(false);
      }
      var newIndex = index;

      // if index is true i.e. if the module exists in the array
      if (index > -1) {
        // check if the navigation is for next
        if (navigation) {
          if (navigation === "next") {
            newIndex = index + 1;
          }
          // else if (navigation === 'back') {
          //   var newIndex = index - 1;
          // }
          else if (navigation === "random_quiz") {
            const failedQuizIds = getFailedQuizList();
            let QuizList = list.filter(
              (module) =>
                Math.trunc(module?.sequence_number) ==
                  Math.trunc(list[index].sequence_number) &&
                module.module_type === "quiz" &&
                module.id !== list[index].id &&
                failedQuizIds.indexOf(module.id) < 0
            );

            let randomIndex;
            if (QuizList.length > 1) {
              let min = Math.ceil(0);
              let max = Math.floor(QuizList.length - 1);
              randomIndex = Math.floor(Math.random() * (max - min) + min);
            } else {
              randomIndex = 0;
            }
            console.log(randomIndex);
            newIndex = randomIndex;
            list = QuizList;
          }
        }

        //  function is only defined here and called in the bottom
        const newModuleNav = () => {
          const getNewDetail = (newModuleList, newIndex) => {
            // getting information from this new module
            var new_module_id = newModuleList[newIndex]?.id;
            var module_type = newModuleList[newIndex]?.module_type;
            var sub_module_id = newModuleList[newIndex]?.sub_module_id;
            var iscomplete =
              newModuleList[newIndex]?.users[0]?.user_course_module?.status;
            // return new_module_id, module_type, sub_module_id, iscomplete
            if (
              new_module_id &&
              (navigation === "fail" || navigation === "next")
            ) {
              if (
                module_type === "security_questions" ||
                module_type === "typing_dna"
              ) {
                if (iscomplete === "COMPLETED") {
                  getNewDetail(list, newIndex + 1);
                } else {
                  set_content_id("");
                  dispatch(tableofContentDetailAction(new_module_id));
                  handleNavigation(sub_module_id, module_type, new_module_id);
                }
              } else if (module_type === "quiz") {
                getNewDetail(list, newIndex + 1);
              } else {
                // for grapesjs module
                set_content_id("");
                dispatch(tableofContentDetailAction(new_module_id));
                handleNavigation(sub_module_id, module_type, new_module_id);
              }
            } else if (new_module_id && navigation === "random_quiz") {
              if (module_type === "quiz") {
                if (iscomplete === "COMPLETED") {
                  getNewDetail(newModuleList, newIndex + 1);
                } else {
                  set_content_id("");
                  dispatch(tableofContentDetailAction(new_module_id));
                  handleNavigation(sub_module_id, module_type, new_module_id);
                }
              }
            } else {
              //history.push('/user/tableofcontent')
              history.push(`/user/CourseComplete/${course_id}`);
            }
          };

          console.log(list, newIndex);
          getNewDetail(list, newIndex);
        };

        // checking what action to take if quiz attempts are over.
        if (after_completion) {
          //  this is called if the user failed all attempts
          if (after_completion === "restart_course") {
            history.push(`/user/dashboard/${course_id}`);
          } else if (after_completion === "restart_module") {
            history.push(`/user/tableofcontent/${course_id}`);
          } else if (after_completion === "continue_to_next_module") {
            newModuleNav();
          } else {
            window.location.reload();
          }
        } else {
          // this is called when attempts are NOT OVER
          newModuleNav();
        }
      }
    }
  };

  const handleNavigation = (sub_id, module_type, new_module_id) => {
    dispatch(tableofContentQuizDetailAction(sub_id));
    dispatch(tableofContentSecurityDetailAction(sub_id));

    if (module_type == "quiz") {
      history.push(`/user/userquiz/${course_id}/${new_module_id}/${sub_id}`);
      setQuizChanged(true);
      // window.location.reload()
    } else if (module_type == "grapejs") {
      history.push(`/user/module/${course_id}/${new_module_id}`);
    } else if (module_type == "security_questions") {
      history.push(`/user/usersecurityquestion/${course_id}/${new_module_id}`);
    } else if (module_type == "typing_dna") {
      // history.push(
      //   `/user/typingDnaAuthenticUserCheck/${course_id}/${new_module_id}`
      // );
      history.push(
        `/user/otpVerificationUserCheck/${course_id}/${new_module_id}`
      );
    } else if (module_type == "otp") {
      history.push(
        `/user/otpVerificationUserCheck/${course_id}/${new_module_id}`
      );
    }
  };

  const setFailedQuizId = (id) => {
    let failedList = getFailedQuizList();
    failedList.push(id);
    setItemInSessionStorage("failedQuizList", JSON.stringify(failedList));
  };

  const getFailedQuizList = () => {
    let list = sessionStorage.getItem("failedQuizList");
    if (
      !list ||
      list === "" ||
      JSON.parse(list) === undefined ||
      !Array.isArray(JSON.parse(list))
    ) {
      list = [];
    } else {
      list = JSON.parse(list);
    }
    return list;
  };

  return (
    <div>
      <div className="noHeaderTop welcomeScreen">
        <Container
          fluid
          className="pl-md-5 pr-md-5 pt-3 pb-3 quiz_questioncontainer w-100"
        >
          <Row className="justify-content-md-center d-row-flex">
            <Col xl={9} lg={9} md={10} sm={12} xs={12}>
              <Row className="d-row-flex">
                <Col xl={9} lg={8} md={7} sm={12} xs={7}>
                  <h1 className="module_heading">
                    {" "}
                    {module_name.charAt(0).toUpperCase() +
                      module_name.slice(1)}{" "}
                  </h1>{" "}
                </Col>

                <Col
                  xl={3}
                  lg={4}
                  md={5}
                  sm={12}
                  xs={5}
                  className="md-right time"
                >
                  <div className="timeDiv">
                    <span> Time Remaining </span>
                    <p>
                      <img src={time_clock} className="pr-2" />
                      {module_time_obj.module_id == module_id
                        ? secondToMinuteOrHourConvert(quiz_time)
                        : `0 hr 0 min 0 sec `}
                    </p>
                  </div>
                </Col>
              </Row>
              {resultShow && userResult == "pass" ? (
                <div className="Correct_answerscontainer">
                  <p>
                    <span>{`${answer_percentage}%`} </span>Correct Answers
                  </p>
                  <p>
                    Congratulations, You have successfully Passed this Quiz.
                  </p>
                </div>
              ) : null}

              {resultShow && userResult == "fail" ? (
                <div className="failed_answerscontainer">
                  <h5>Failed! Try Again!</h5>
                  <p>
                    You have not achieved minimum grade for passing this Quiz
                  </p>
                </div>
              ) : null}

              <Col xl={12} lg={12} md={12} sm={12}>
                <div className="UserSecurityQuestion_container">
                  {quizQuestionList
                    ? quizQuestionList.map((quiz, index) => {
                        let _is_disabled = false;
                        if (
                          (userResult == "fail" && attempts_remaining == 0) ||
                          userResult == "pass"
                        ) {
                          _is_disabled = true;
                        }
                        if (quiz.quiz_type == "single_choice_question") {
                          return (
                            <SingleChoice
                              key={quiz.id}
                              index={index}
                              quiz={quiz}
                              _is_disabled={_is_disabled}
                              handleUserInput={handleUserInput}
                            />
                          );
                        }
                        if (quiz.quiz_type == "multiple_choice_question") {
                          return (
                            <MultipleChoice
                              key={quiz.id}
                              index={index}
                              quiz={quiz}
                              _is_disabled={_is_disabled}
                              handleUserInput={handleUserInput}
                            />
                          );
                        }
                      })
                    : null}

                  {userResult == "pass" ? (
                    <Button
                      variant="primary"
                      className="btnSign mt-4 questionsubmitbtn"
                      name="next"
                      onClick={(e) => handleModuleNavigation("next", "")}
                    >
                      Next
                    </Button>
                  ) : null}
                  {userResult == "fail" && attempts_remaining !== 0 ? (
                    <Button
                      variant="primary"
                      className="btnSign mt-4 questionsubmitbtn"
                      name="submit"
                      onClick={(e) => handleResponseSubmit()}
                    >
                      Submit
                    </Button>
                  ) : null}
                  {userResult == "fail" && attempts_remaining == 0 ? (
                    <Button
                      variant="primary"
                      className="btnSign mt-4 questionsubmitbtn"
                      name="back"
                      onClick={(e) =>
                        handleModuleNavigation("", after_completion_of_attempts)
                      }
                    >
                      {/* {button_name} */}
                      {(() => {
                        switch (after_completion_of_attempts) {
                          case "restart_course":
                            return "Back";
                          case "restart_module":
                            return "Restart";
                          case "continue_to_next_module":
                            return "Continue";
                          default:
                            return "Restart";
                        }
                      })()}
                    </Button>
                  ) : null}
                </div>
              </Col>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default UserQuiz;
